<script setup>
import SigningPage from "@/components/SigningPage.vue";
import { useMeta } from "vue-meta";

useMeta({
  title: "Login",
  link: [{ rel: "canonical", href: "https://wonderai.app/signup" }],
});
</script>

<template>
  <SigningPage type="signin"></SigningPage>
</template>
