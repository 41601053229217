<script setup>
import { getCerebroId } from "@/helpers/cerebro";
import { useUserStore } from "@/store/user";
import { computed } from "vue";
import LinkTag from "./LinkTag.vue";

const userStore = useUserStore();
const userId = computed(() => userStore.userData.userId);
</script>
<template>
  <div class="sup-container gap-20">
    <h2>Support</h2>
    <span class="sup-info">
      Do you have a problem? Our dedicated support team is ready to assist.
    </span>
    <LinkTag
      :href="`mailto:wonderweb@codeway.co?subject=Support - Wonder Web - ${userId}/${getCerebroId()}`"
      target="_blank"
      eventName="click_email_support"
      class="sup-email-btn"
    >
      <img src="@/assets/icons/mail_icon.svg" alt="Contact support" />
      <span class="sup-email-btn-label">Email Support</span>
    </LinkTag>
  </div>
</template>
<style scoped>
.sup-container {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
}

.sup-container .sup-info {
  color: #111a187d;
  font-size: 16px;
  font-weight: 400;
}

.sup-container .sup-email-btn {
  display: flex;
  height: 50px;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  background: #f5f5f5;
}

.sup-container .sup-email-btn img {
  width: 20px;
  height: 20px;
}

.sup-container .sup-email-btn-label {
  color: #111a18;
  font-size: 16px;
  font-weight: 600;
}
</style>
