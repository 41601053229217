<script setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import BlackButton from "./BlackButton.vue";

const props = defineProps(["page", "next", "skip", "isMobile"]);

const title = computed(() => {
  const regex = /\((.*?)\)/;
  const match = props.page.title.match(regex);
  if (match) {
    return {
      normal: props.page.title.replace(regex, "").trim(),
      bold: match[1].trim(),
    };
  } else {
    return {
      normal: props.page.title.trim(),
      bold: "",
    };
  }
});

const translateY = ref(0);
const animationSpeed = 0.5; // Speed of animation. Adjust as necessary.
let animationDirection = 1; // 1 for down, -1 for up
let animationFrameId = null;

const imageElement = ref(null);

// Calculate the max translation needed to ensure the bottom of the image
// aligns with the bottom of the container when moving up
let maxTranslateY = 0;

const updateAnimation = () => {
  const containerHeight = document.documentElement.clientHeight; // Or your specific container's height
  const imageHeight = imageElement.value.offsetHeight;

  // Calculate the maximum translateY value
  maxTranslateY = imageHeight - containerHeight;
  // Update the position of the image
  translateY.value += animationSpeed * animationDirection;

  // Check for reversal conditions
  if (translateY.value <= -maxTranslateY || translateY.value > 0) {
    animationDirection *= -1; // Reverse direction
  }

  imageElement.value.style.transform = `translateY(${translateY.value}px)`;

  // Continue the animation
  animationFrameId = requestAnimationFrame(updateAnimation);
};

onMounted(() => {
  imageElement.value = document.querySelector("#scrolling-img");

  if (props.page.name === "welcome") {
    animationFrameId = requestAnimationFrame(updateAnimation);
  }
});

onUnmounted(() => {
  if (animationFrameId) {
    cancelAnimationFrame(animationFrameId);
  }
});
</script>
<template>
  <div class="upper-bg edge-bg"></div>
  <div class="container">
    <span v-if="page.skip" class="skip pointer" @click="props.skip">Skip</span>
    <div class="img-container">
      <div v-if="page.name === 'welcome'" class="welcome-container">
        <div class="logo">
          <img src="@/assets/images/wonder_logo.png" alt="Wonder logo" />
        </div>
        <div class="stars">
          <img src="@/assets/icons/star.svg" alt="" />
          <img src="@/assets/icons/star.svg" alt="" />
          <img src="@/assets/icons/star.svg" alt="" />
          <img src="@/assets/icons/star.svg" alt="" />
          <img src="@/assets/icons/star.svg" alt="" />
        </div>
        <h1>Discover Wonderland</h1>
        <span>Expand your visual capacity</span>
      </div>
      <img
        :src="isMobile ? page.mobile_img_url : page.desktop_img_url"
        :alt="page.name"
        :id="page.name === 'welcome' ? 'scrolling-img' : ''"
      />
    </div>
    <div class="content-container">
      <div class="text-container">
        <span class="title">
          {{ title.normal }}
          <span class="bold">{{ title.bold }}</span>
        </span>
        <span class="subtitle">
          {{ page.subtitle }}
        </span>
      </div>
      <div class="action-container">
        <BlackButton :rightIcon="page.arrow" :clickHandler="props.next">{{
          page.button_text
        }}</BlackButton>
        <span class="faded-text" v-if="page.legal">
          By continuing, you agree to our
          <a
            href="https://static.wonderai.app/privacy"
            target="_blank"
            class="legal-link"
            >Privacy Policy</a
          >
          and
          <a
            href="https://static.wonderai.app/terms-conditions"
            target="_blank"
            class="legal-link"
            >Terms of Use</a
          >.
        </span>
      </div>
    </div>
  </div>
  <div class="lower-bg edge-bg"></div>
</template>
<style scoped>
.container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
}

.skip {
  position: absolute;
  top: 40px;
  right: 40px;
  font-size: 18px;
  font-weight: 700;
  text-decoration-line: underline;
}

.welcome-container {
  z-index: 1;
  position: absolute;
  top: 0;
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10%;
  background: linear-gradient(
    180deg,
    #fff 0%,
    #f5f5f5 50%,
    rgba(245, 245, 245, 0.9) 68%,
    rgba(245, 245, 245, 0.75) 78%,
    rgba(245, 245, 245, 0.6) 88%,
    rgba(245, 245, 245, 0) 100%
  );
  gap: 12px;
}

.welcome-container .logo {
  display: flex;
  padding: 12px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid rgba(17, 26, 24, 0.1);
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(25px);
}

.welcome-container .logo img {
  width: 150px;
}

.welcome-container .stars {
  display: flex;
  align-items: center;
  gap: 10px;
}

.welcome-container h1 {
  color: #111a18;
  font-size: 40px;
  font-weight: 700;
  margin: 0;
}

.welcome-container span {
  color: rgba(17, 26, 24, 0.7);
  font-size: 20px;
  font-weight: 400;
}

.img-container {
  width: 60%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f5f5f5;
}

.img-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

#scrolling-img {
  height: auto;
  object-fit: cover;
}

.content-container {
  padding: 8%;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.title {
  font-size: 40px;
  font-weight: 275;
  line-height: 48px; /* 120% */
}

.subtitle {
  font-size: 16px;
  font-weight: 400;
  color: #111a1880;
  width: 90%;
}

.action-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.skip-text {
  text-align: right !important;
  cursor: pointer;
}

.faded-text {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #111a1880;
}

.legal-link {
  font-weight: 700;
}

.edge-bg {
  display: none;
}

@keyframes slide {
  0% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(-35%);
  }
}

@media screen and (max-width: 1024px) {
  .skip {
    top: 15px;
    right: 15px;
    font-size: 14px;
  }

  .welcome-container {
    display: none;
  }

  .title {
    font-size: 34px;
    text-align: left;
    padding-right: 60px;
  }

  .subtitle {
    display: none;
  }

  .faded-text {
    font-size: 10px;
  }

  .skip-text {
    font-size: 12px;
  }

  .img-container {
    position: absolute;
    z-index: -2;
    width: 100%;
    justify-content: center;
  }

  .img-container > img {
    object-fit: contain;
    height: auto;
  }

  #scrolling-img {
    width: 130%;
  }

  .content-container {
    width: 100%;
    height: 100%;
    padding: 25px 25px 15px 25px;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  @keyframes slide {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(-15%);
    }
  }

  .edge-bg {
    display: block;
    position: absolute;
    width: 100%;
    height: 30%;
    z-index: -1;
  }

  .upper-bg {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 3.24%,
      #fff 32.86%
    );
    top: 0;
  }

  .lower-bg {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.35) 17.89%,
      rgba(255, 255, 255, 0.55) 25.98%,
      rgba(255, 255, 255, 0.75) 34.53%,
      rgba(255, 255, 255, 0.86) 39.62%,
      rgba(255, 255, 255, 0.915266) 43.04%,
      rgba(255, 255, 255, 0.94) 46.06%
    );
    bottom: 0;
  }
}
</style>
