<script setup>
import { sendEvent } from "@/helpers/cerebro";
import { wordCount } from "@/helpers/common";
import LinkTag from "./LinkTag.vue";

const props = defineProps([
  "imgLink",
  "prompt",
  "clickHandler",
  "style",
  "downloadBtnEnabled",
  "imgLoad",
]);

const getEventProperties = () => ({
  prompt: props.prompt,
  style: props.style,
  artURL: props.imgLink,
  wordCount: wordCount(props.prompt),
  promptLength: props.prompt?.length || 0,
});
</script>
<template>
  <div class="featured-img-box">
    <img :src="imgLink" :alt="prompt" @load="props.imgLoad" />
    <div class="featured-overlay">
      <div class="download-btn" v-if="downloadBtnEnabled">
        <LinkTag
          :href="imgLink"
          target="_blank"
          eventName="click_download"
          :eventProperties="getEventProperties()"
        >
          <img
            src="@/assets/icons/download_icon.png"
            alt="Download"
            id="download-icon"
          />
        </LinkTag>
      </div>
      <p class="featured-prompt">{{ prompt }}</p>
      <button
        class="btn"
        @click="
          () => {
            sendEvent({
              eventName: 'use_this_prompt',
              eventGroup: 'creation',
              properties: getEventProperties(),
            });
            clickHandler(prompt, style);
          }
        "
      >
        Use This Prompt
      </button>
    </div>
  </div>
</template>

<style scoped>
img {
  width: 100%;
  margin: auto;
  border-radius: 16px;
}

.featured-img-box {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.featured-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(17, 26, 24, 0.5);
  border-radius: 16px;
  color: #fff;
  font-weight: 400;
  font-size: 12.5982px;
  line-height: 19px;
  justify-content: flex-end;
  flex-direction: column;
  padding: 16px;
  display: none;
}

.featured-img-box:hover > .featured-overlay {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  word-break: break-word;
}

.featured-overlay button {
  height: 45px;
  padding: 0;
  margin-top: 15px;
  background: #f5f5f5;
  border-radius: 16px;
  color: #111a18;
  width: 100%;
  font-weight: 700;
  flex-shrink: 0;
  font-size: 12px;
  line-height: 18px;
}

.download-btn {
  position: absolute;
  top: 25px;
  right: 25px;
}

.featured-prompt {
  overflow: hidden;
  overscroll-behavior: contain;
  position: relative;
}

#download-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  img {
    border-radius: 10px;
  }
  .featured-overlay .btn {
    font-size: 10px;
    line-height: 15px;
    height: auto;
    padding: 9px;
    border-radius: 10px;
  }

  .featured-prompt {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
</style>
