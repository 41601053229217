import { sendEvent } from "@/helpers/cerebro";
import { isVerificationRequired } from "@/helpers/verificationEmail";
import { useUserStore } from "@/store/user";
import { createRouter, createWebHistory } from "vue-router";
import Checkout from "../pages/Checkout";
import CouponManager from "../pages/CouponManager";
import Credits from "../pages/Credits";
import Feed from "../pages/Feed";
import Gallery from "../pages/Gallery";
import Home from "../pages/Home";
import Landing from "../pages/Landing";
import MyCollection from "../pages/MyCollection";
import OnboardingManager from "../pages/OnboardingManager";
import Payment from "../pages/Payment";
import PaymentResult from "../pages/PaymentResult";
import Settings from "../pages/Settings";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import Text2Vid from "../pages/Text2Vid";
import VerifyEmail from "../pages/VerifyEmail";

const routes = [
  { path: "/", component: Landing, name: "Landing" },
  {
    path: "/home",
    component: Home,
    name: "Home",
    meta: { requiresAuth: true },
  },
  {
    path: "/feed",
    component: Feed,
    name: "Feed",
    meta: { requiresAuth: true },
  },
  {
    path: "/collection",
    component: MyCollection,
    name: "MyCollection",
    meta: { requiresAuth: true },
  },
  {
    path: "/signin",
    component: SignIn,
    name: "Signin",
    meta: { onlyUnauth: true },
  },
  {
    path: "/signup",
    component: SignUp,
    name: "Signup",
    meta: { onlyUnauth: true },
  },
  {
    path: "/verify",
    component: VerifyEmail,
    name: "VerifyEmail",
    meta: { requiresAuth: true },
  },
  {
    path: "/coupon-manager",
    component: CouponManager,
    name: "CouponManager",
    meta: { onlyManagers: true, requiresAuth: true },
  },
  {
    path: "/payment",
    component: Payment,
    name: "Payment",
    meta: { requiresAuth: true },
  },
  {
    path: "/checkout/:subscription/:clientSecret",
    component: Checkout,
    name: "Checkout",
    meta: { requiresAuth: true },
  },
  {
    path: "/payment-result",
    component: PaymentResult,
    name: "PaymentResult",
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    component: Settings,
    name: "Settings",
    meta: { requiresAuth: true },
  },
  {
    path: "/onboarding",
    component: OnboardingManager,
    name: "Onboarding",
    meta: { onlyUnauth: true },
  },
  {
    path: "/credits",
    component: Credits,
    name: "Credits",
    meta: { requiresAuth: true },
  },
  {
    path: "/text2vid",
    component: Text2Vid,
    name: "Text2Vid",
    meta: { requiresAuth: true },
  },
  {
    path: "/gallery",
    component: Gallery,
    name: "Gallery",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  const currentUser = await userStore.getCurrentUser();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (currentUser) {
      if (to.matched.some((record) => record.meta.onlyManagers)) {
        if (userStore.userData && userStore.userData.isManager == true) {
          next();
        } else {
          next("/home");
        }
      } else {
        if (process.env.VUE_APP_ENV == "local") {
          next();
        } else {
          if (isVerificationRequired(currentUser) && to.name != "VerifyEmail") {
            next({
              name: "VerifyEmail",
              state: {
                from:
                  from.name === "WhatsappSignup"
                    ? "whatsapp"
                    : from.query.onboarding
                    ? "onboarding"
                    : from.query.onboardingInf
                    ? "onboardingInf"
                    : from.query.onboardingChat
                    ? "onboardingChatMobile"
                    : from.name === "ChatOnboarding"
                    ? "onboardingChatDesktop"
                    : from.name === "Signup"
                    ? "Home"
                    : from.name === "Home" && from.query.position
                    ? `Home_${from.query.position}`
                    : "",
              },
            });
          } else if (
            !isVerificationRequired(currentUser) &&
            to.name == "VerifyEmail"
          ) {
            next({ name: "Home" });
          } else {
            next();
          }
        }
      }
    } else {
      next("/signin");
    }
  } else if (to.matched.some((record) => record.meta.onlyUnauth)) {
    if (currentUser) {
      if (!userStore.isUserPro()) {
        const onboardingId = localStorage.getItem("ocid");
        const cfpSource = localStorage.getItem("cfpSource");
        if (onboardingId || cfpSource == "onboarding") {
          userStore.setCheckForPaywall({ val: true, source: "onboarding" });
        } else if (to.name == "Signup") {
          userStore.setCheckForPaywall({ val: true, source: "landing" });
        } else if (to.name == "Signin") {
          userStore.setCheckForPaywall({ val: true, source: "signin" });
        }
      }

      next({ name: "Home" });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to, from, failure) => {
  if (!failure) {
    if (to.name == "Checkout") {
      let properties = {
        productIdentifier: to.query.pid,
      };
      if (to.params.subscription !== to.query.productIdentifier) {
        properties.subscriptionIdentifier = to.params.subscription;
      }
      sendEvent({
        eventName: "web_page_open",
        page: "Checkout",
        properties,
      });
    } else if (to.name == "PaymentResult") {
      sendEvent({
        eventName: "web_page_open",
        page: "PaymentResult",
        result: to.query.success == "true" ? "success" : "fail",
        properties: { productIdentifier: to.query.s },
      });
    } else {
      sendEvent({
        eventName: "web_page_open",
        page: to.name,
        properties: { source: from.name || "", fullPath: to.fullPath },
      });
    }
  }
});

export default router;
