<script setup>
import { sendEvent } from "@/helpers/cerebro";
import { cancelSubscription } from "@/helpers/paddle";
import { cancelStripeSubscription } from "@/helpers/stripe";
import uploadToBucket from "@/helpers/uploadToBucket";
import { handleSignout, setUserData } from "@/helpers/userManager";
import router from "@/router";
import { useUserStore } from "@/store/user";
import imageCompression from "browser-image-compression";
import {
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  deleteUser,
  getAuth,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
} from "firebase/auth";
import { computed, onMounted, ref } from "vue";
import CustomModal from "./CustomModal.vue";
import SettingsAccountDetailsBox from "./SettingsAccountDetailsBox.vue";

const providerIdToAccountType = {
  password: "Mail Account",
  "google.com": "Google Account",
  "apple.com": "Apple Account",
  "facebook.com": "Facebook Account",
};

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select_account" });
const facebookProvider = new FacebookAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

const providerDetails = {
  "google.com": {
    provider: googleProvider,
  },
  "facebook.com": {
    provider: facebookProvider,
  },
  "apple.com": {
    provider: appleProvider,
  },
};

const userStore = useUserStore();

const editingUsername = ref(false);
const editedUsername = ref();

const showDeleteUser = ref(false);
const existingPasswordForDelete = ref("");
const errMsgCurrentPassword = ref("");

onMounted(() => {
  editedUsername.value = userStore.userData.username;
});

const isDeleteButtonDisabled = computed(
  () =>
    !existingPasswordForDelete.value &&
    accountDetailsData.value?.accountType === "password"
);

const accountDetailsData = computed(() => {
  const user = userStore.user;
  const providerData = user?.providerData[0];
  return {
    providerId: providerData.providerId,
    accountType: providerIdToAccountType[providerData.providerId],
    email: providerData.email,
  };
});

const username = computed(() => {
  const userData = userStore.userData;
  return userData.username;
});

const profilePhoto = computed(() => {
  const userData = userStore.userData;
  return (
    userData.profilePhoto ||
    "https://storage.googleapis.com/wonder-eed94.appspot.com/website_images/default_profile_photo.png"
  );
});

const editUsername = () => {
  sendEvent({ eventName: "click_profile_name" });
  editingUsername.value = true;
};

const updateUsername = async () => {
  sendEvent({ eventName: "click_save", eventGroup: "settings" });
  const user = userStore.user;
  try {
    await setUserData({
      userId: user.uid,
      username: editedUsername.value,
    });
    userStore.editUserData({ username: editedUsername.value });
    editingUsername.value = false;
    sendEvent({
      eventName: "profile_name_updated",
      eventGroup: "settings",
      result: "success",
    });
  } catch (error) {
    sendEvent({
      eventName: "profile_name_updated",
      eventGroup: "settings",
      result: "fail",
    });
    console.log(error);
    // TODO: SHOW ERROR
  }
};

const uploadProfilePic = () => {
  sendEvent({ eventName: "click_photo" });
  document.getElementById("imgUploadInput").click();
};

const handleImageUpload = async (event) => {
  const imageFile = event.target.files[0];

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    const user = userStore.user;
    if (user && user.uid) {
      try {
        const url = await uploadToBucket(
          compressedFile,
          `profile-photos/${user.uid}/profile_photo.jpg`
        );
        await setUserData({
          userId: user.uid,
          profilePhoto: url,
        });
        userStore.editUserData({ profilePhoto: url });
      } catch (error) {
        // TODO: SHOW ERROR ALERT
        console.log(error);
      }
    } else {
      console.log("User is not logged in");
    }
  } catch (error) {
    console.log(error);
  }
};

const updateShowDeleteUser = (rhs) => {
  showDeleteUser.value = rhs;

  if (!rhs) {
    errMsgCurrentPassword.value = "";
    existingPasswordForDelete.value = "";
  }

  if (rhs)
    sendEvent({
      eventName: "delete_account_tapped",
      properties: {
        source: "Settings",
        signupMethod: accountDetailsData.value.providerId,
      },
    });
};

const deleteAccount = () => {
  const subscriptions = userStore.userData.subscriptions;

  if (subscriptions && Object.keys(subscriptions).length > 0) {
    Object.keys(subscriptions).forEach(async (subscriptionId) => {
      if (
        !subscriptionId.startsWith("pi") &&
        subscriptions[subscriptionId].isAutoRenewOn
      ) {
        if (subscriptions[subscriptionId].paymentProvider) {
          try {
            await cancelSubscription(subscriptionId);
          } catch (error) {
            console.log(error);
            return alert(
              "Error occured while cancelling subscription. If the problem persists, please contact support at wonderweb@codeway.co"
            );
          }
        } else {
          try {
            await cancelStripeSubscription(subscriptionId);
          } catch (error) {
            console.log(error);
            return alert(
              "Error occured while cancelling subscription. If the problem persists, please contact support at wonderweb@codeway.co"
            );
          }
        }
      }
    });
  }

  deleteUser(getAuth().currentUser)
    .then(() => {
      // TODO: SHOW SUCCESS MESSAGE IN TOAST
      updateShowDeleteUser(false);
      errMsgCurrentPassword.value = "";
      localStorage.clear();
      sendEvent({
        eventName: "account_deleted",
        properties: {
          source: "Settings",
          signupMethod: accountDetailsData.value.providerId,
        },
      });
      router.push({ name: "Home" });
    })
    .catch((error) => {
      errMsgCurrentPassword.value = error.message || "Please try again.";
      setTimeout(() => {
        errMsgCurrentPassword.value = "";
      }, 5000);
    });
};

const handleDeleteUser = async () => {
  if (
    !existingPasswordForDelete.value.trim() &&
    accountDetailsData.value.providerId === "password"
  ) {
    errMsgCurrentPassword.value = "Password cannot be empty.";
    setTimeout(() => {
      errMsgCurrentPassword.value = "";
    }, 5000);
    return;
  }

  const auth = getAuth();
  const user = auth.currentUser;

  if (accountDetailsData.value.providerId === "password") {
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      existingPasswordForDelete.value
    );

    reauthenticateWithCredential(user, credential)
      .then(() => {
        deleteAccount();
      })
      .catch((error) => {
        errMsgCurrentPassword.value = "Wrong Password";
        setTimeout(() => {
          errMsgCurrentPassword.value = "";
        }, 5000);
        return;
      });
  } else {
    reauthenticateWithPopup(
      user,
      providerDetails[accountDetailsData.value.providerId].provider
    )
      .then(() => {
        deleteAccount();
      })
      .catch((error) => {
        errMsgCurrentPassword.value = error.message || "Please try again.";
        setTimeout(() => {
          errMsgCurrentPassword.value = "";
        }, 5000);
      });
  }
};
</script>
<template>
  <div class="profile-container gap-20">
    <h2>Profile</h2>
    <div class="pp-and-username">
      <div class="profile-pic-container">
        <img :src="profilePhoto" alt="profile" class="profile-pic" />
        <div class="edit-photo-icon">
          <img
            src="@/assets/icons/photo_icon.svg"
            alt="Upload photo"
            @click="uploadProfilePic"
          />
        </div>
        <input
          type="file"
          accept="image/*"
          @change="handleImageUpload"
          style="display: none"
          id="imgUploadInput"
        />
      </div>
      <div class="username-and-edit-container">
        <div class="username-container">
          <span class="label">USERNAME</span>
          <span class="username" v-if="!editingUsername">
            {{ username }}
          </span>
          <span class="username" v-else>
            <input type="text" class="form-control" v-model="editedUsername" />
            <button @click="updateUsername" class="btn">Save</button>
          </span>
        </div>
        <img
          v-if="!editingUsername"
          src="@/assets/icons/edit_icon.svg"
          alt="Edit username"
          class="edit-icon"
          @click="editUsername"
        />
      </div>
    </div>
  </div>
  <div class="account-container gap-20">
    <h2>Account</h2>
    <SettingsAccountDetailsBox :accountDetailsData="accountDetailsData" />
    <div class="logout-btn" @click="handleSignout">
      <img src="@/assets/icons/logout_icon_light.svg" alt="logout" />
      <span class="label">Logout</span>
    </div>
    <div class="delete-container">
      <div class="delete-btn" @click="() => updateShowDeleteUser(true)">
        <img src="@/assets/icons/delete_icon_red.svg" alt="Delete account" />
        <span class="label">Delete account</span>
      </div>
      <span class="info"
        >This action will permanently delete your account and data.</span
      >
    </div>
  </div>
  <CustomModal v-model="showDeleteUser">
    <div class="delete-modal">
      <h1>Are you sure you want to delete your account?</h1>
      <p>
        If you continue, this will delete your data. Deleting your account will
        permanently remove all of your data and cannot be undone.
      </p>
      <input
        v-if="accountDetailsData.providerId === 'password'"
        class="form-control"
        type="password"
        placeholder="Enter your password."
        v-model="existingPasswordForDelete"
      />
      <p v-if="errMsgCurrentPassword" class="error-message">
        {{ errMsgCurrentPassword }}
      </p>
      <div class="buttons-container">
        <button
          class="btn btn-modal-delete"
          @click="handleDeleteUser"
          :disabled="isDeleteButtonDisabled"
        >
          Delete Account
        </button>
        <button
          class="btn btn-modal-cancel"
          @click="
            () => {
              sendEvent({
                eventName: 'delete_account_canceled',
                properties: {
                  source: 'Settings',
                  signupMethod: accountDetailsData.providerId,
                },
              });
              updateShowDeleteUser(false);
            }
          "
        >
          Cancel
        </button>
      </div>
    </div>
  </CustomModal>
</template>
<style scoped>
.profile-container {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.pp-and-username {
  display: flex;
  width: 100%;
}

.profile-pic-container {
  display: flex;
  align-items: center;
  width: 100px;
  height: 100px;
}

.profile-pic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.edit-photo-icon {
  position: absolute;
  width: 100px;
  height: 100px;
  opacity: 0;
  background-color: #111a184d;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-photo-icon:hover {
  opacity: 1;
  cursor: pointer;
}

#imgUploadInput {
  display: none;
}

.username-and-edit-container {
  display: flex;
  width: calc(100% - 120px);
  margin-left: 20px;
  justify-content: space-between;
  align-items: center;
}

.username-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  width: calc(100% - 20px);
}

.username-container .label {
  color: rgba(17, 26, 24, 0.6);
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.username {
  font-size: 18px;
  font-weight: 700;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit-icon {
  cursor: pointer;
}

.account-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.account-container .logout-btn {
  display: none;
}

.account-container .delete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.account-container .delete-container .delete-btn {
  display: flex;
  height: 50px;
  padding: 15px 30px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  border-radius: 12px;
  background: #f5f5f5;
  color: #ea4335;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.account-container .delete-container .info {
  color: #111a1866;
  font-size: 16px;
  font-weight: 400;
  align-self: self-start;
}

.delete-modal {
  max-width: 480px;
  padding: 48px;
}

.delete-modal h1 {
  font-size: 38px;
  font-weight: 700;
  margin: 0;
}

.delete-modal p {
  font-size: 16px;
  font-weight: 400;
  opacity: 0.5;
  margin-top: 16px;
}

.delete-modal input {
  margin-top: 20px;
}

.delete-modal .error-message {
  color: #ea4335;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}

.delete-modal .buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
}

.delete-modal .buttons-container .btn-cancel {
  background: #111a18;
  font-size: 16px;
  font-weight: 700;
}

.delete-modal .buttons-container .btn-modal-delete {
  background: #ea4335;
  font-size: 16px;
  font-weight: 700;
}

@media screen and (max-width: 1024px) {
  .profile-pic-container {
    width: 80px;
    height: 80px;
  }

  .profile-pic {
    width: 80px;
    height: 80px;
  }

  .username-and-edit-container {
    width: calc(100% - 100px);
  }

  .edit-photo-icon {
    width: 80px;
    height: 80px;
  }

  .account-container .logout-btn {
    display: flex;
    align-self: stretch;
    height: 50px;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 12px;
    background: #000;
    color: #fff;
    text-align: right;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }

  .delete-modal {
    max-width: 300px;
    padding: 36px;
  }

  .delete-modal h1 {
    font-size: 24px;
  }

  .delete-modal p {
    font-size: 10px;
  }

  .delete-modal .buttons-container .btn {
    font-size: 10px;
    padding: 10px;
    border-radius: 12px;
  }
}
</style>
