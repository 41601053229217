<script setup>
import MailIcon from "@/assets/icons/mail_icon.svg";
import AppleIcon from "@/assets/images/apple_icon.png";
import FacebookIcon from "@/assets/images/facebook_icon.png";
import GoogleIcon from "@/assets/images/google_icon.png";
import { sendEvent } from "@/helpers/cerebro";
import { handleSignout } from "@/helpers/userManager";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { computed, ref } from "vue";
import CustomModal from "./CustomModal.vue";

const props = defineProps(["accountDetailsData"]);

const themeBackgroundColors = {
  light: "#F5F5F5",
  dark: "#000",
  blue: "#4267B2",
};

const icons = {
  "Google Account": GoogleIcon,
  "Apple Account": AppleIcon,
  "Mail Account": MailIcon,
  "Facebook Account": FacebookIcon,
};

const showChangePassword = ref(false);

const existingPasswordForChange = ref("");
const newPassword = ref("");
const newPasswordConfirm = ref("");

const errMsgCurrentPassword = ref("");
const errMsgNewPassword = ref("");
const succesfullMsg = ref("");

const isChangePasswordButtonDisabled = computed(
  () =>
    !existingPasswordForChange.value ||
    !newPassword.value ||
    !newPasswordConfirm.value
);

const theme = computed(() => {
  if (
    props.accountDetailsData.accountType == "Mail Account" ||
    props.accountDetailsData.accountType == "Google Account"
  ) {
    return "light";
  } else if (props.accountDetailsData.accountType == "Apple Account") {
    return "dark";
  } else if (props.accountDetailsData.accountType == "Facebook Account") {
    return "blue";
  } else {
    return "light";
  }
});

const isMailAccount = computed(() => {
  return props.accountDetailsData.accountType == "Mail Account";
});
const backgroundColor = computed(() => {
  return themeBackgroundColors[theme.value];
});
const icon = computed(() => {
  return icons[props.accountDetailsData.accountType];
});
const labelColor = computed(() => {
  return theme.value == "light" ? "#111A18" : "#FAFAFA";
});
const emailColor = computed(() => {
  return theme.value == "light" ? "#111A1899" : "#FFFFFF99";
});
const logoutBtnBgColor = computed(() => {
  return theme.value == "light" ? "#111A18" : "#FFFFFF";
});
const logoutTextColor = computed(() => {
  if (theme.value == "light") {
    return "#FFFFFF";
  } else if (theme.value == "blue") {
    return "#538AEF";
  } else if (theme.value == "dark") {
    return "#111A18";
  } else {
    return "#111A18";
  }
});
const logoutIconSrc = computed(() => {
  const themeSuffix = theme.value === "dark" ? "" : `_${theme.value}`;
  return require(`@/assets/icons/logout_icon${themeSuffix}.svg`);
});

const updateShowChangePassword = (rhs) => {
  showChangePassword.value = rhs;

  if (!rhs) {
    errMsgCurrentPassword.value = "";
    errMsgNewPassword.value = "";

    existingPasswordForChange.value = "";
    newPassword.value = "";
    newPasswordConfirm.value = "";
  }

  if (rhs)
    sendEvent({
      eventName: "change_password_tapped",
      properties: { source: "Settings" },
    });
};

const handleChangePassword = () => {
  if (!existingPasswordForChange.value.trim()) {
    errMsgCurrentPassword.value = "Password cannot be empty.";
    setTimeout(() => {
      errMsgCurrentPassword.value = "";
    }, 5000);
    return;
  }

  const trimmedPassword = newPassword.value.trim();
  if (!trimmedPassword) {
    errMsgNewPassword.value = "Password cannot be empty.";
    setTimeout(() => {
      errMsgNewPassword.value = "";
    }, 5000);
    return;
  }

  if (newPassword.value != newPasswordConfirm.value) {
    errMsgNewPassword.value = "Password must be same.";
    setTimeout(() => {
      errMsgNewPassword.value = "";
    }, 5000);
    return;
  }

  if (trimmedPassword == existingPasswordForChange.value.trim()) {
    errMsgNewPassword.value = "New password can not be same.";
    setTimeout(() => {
      errMsgNewPassword.value = "";
    }, 5000);
    return;
  }

  const auth = getAuth();
  const user = auth.currentUser;
  const credential = EmailAuthProvider.credential(
    auth.currentUser.email,
    existingPasswordForChange.value
  );

  reauthenticateWithCredential(user, credential)
    .then(() => {
      updatePassword(getAuth().currentUser, trimmedPassword)
        .then(() => {
          succesfullMsg.value = "Password changed successfully.";
          setTimeout(() => {
            succesfullMsg.value = "";
          }, 5000);
          updateShowChangePassword(false);
          sendEvent({
            eventName: "password_changed",
            properties: { source: "Settings" },
          });
        })
        .catch((error) => {
          if (error.code === "auth/weak-password") {
            errMsgNewPassword.value = "Weak password.";
          } else {
            errMsgNewPassword.value = error.message || "Please try again.";
          }
          setTimeout(() => {
            errMsgNewPassword.value = "";
          }, 5000);
        });
    })
    .catch((error) => {
      console.log(error);
      errMsgCurrentPassword.value = "Wrong Password.";
      setTimeout(() => {
        errMsgCurrentPassword.value = "";
      }, 5000);
      return;
    });
};
</script>

<template>
  <div
    class="details-and-password-container"
    :style="`background-color: ${backgroundColor}`"
  >
    <div class="details-container">
      <div class="icon-and-mail-container">
        <img :src="icon" alt="mail" />
        <div class="mail-container">
          <span class="label" :style="`color: ${labelColor}`">{{
            accountDetailsData.accountType
          }}</span>
          <span class="email" :style="`color: ${emailColor}`">{{
            accountDetailsData.email
          }}</span>
        </div>
      </div>
      <div
        class="logout-btn"
        :style="`background-color: ${logoutBtnBgColor}`"
        @click="handleSignout"
      >
        <span class="label" :style="`color: ${logoutTextColor}`">Logout</span>
        <img :src="logoutIconSrc" alt="logout" />
      </div>
    </div>
    <div v-if="isMailAccount" class="password-and-change-container">
      <div class="password-container">
        <span class="label">Password</span>
        <span class="password">****************</span>
      </div>
      <div
        class="change-password-btn"
        @click="() => updateShowChangePassword(true)"
      >
        <span class="label">Change</span>
      </div>
    </div>
  </div>
  <CustomModal v-model="showChangePassword">
    <div class="change-pwd-modal">
      <h1>Change your password</h1>
      <div class="input-container">
        <h2>Current Password</h2>
        <p v-if="errMsgCurrentPassword" class="error-message">
          {{ errMsgCurrentPassword }}
        </p>
        <input
          class="form-control"
          type="password"
          placeholder="Enter your current password"
          name="passwordExistingChange"
          v-model="existingPasswordForChange"
        />
      </div>
      <div class="input-container">
        <h2>New Password</h2>
        <p v-if="errMsgNewPassword" class="error-message">
          {{ errMsgNewPassword }}
        </p>
        <input
          class="form-control"
          type="password"
          placeholder="Enter your new password"
          name="passwordNew"
          v-model="newPassword"
        />
      </div>
      <div class="input-container">
        <h2>New Password (Confirm)</h2>
        <input
          class="form-control"
          type="password"
          placeholder="Enter your new password"
          name="passwordNewConfirm"
          v-model="newPasswordConfirm"
        />
      </div>
      <div class="buttons-container">
        <button
          class="btn btn-cancel"
          @click="
            () => {
              sendEvent({
                eventName: 'change_password_canceled',
                properties: { source: 'Settings' },
              });
              updateShowChangePassword(false);
            }
          "
        >
          Cancel
        </button>
        <button
          class="btn btn-save"
          @click="handleChangePassword"
          :disabled="isChangePasswordButtonDisabled"
        >
          Save
        </button>
      </div>
    </div>
  </CustomModal>
</template>

<style scoped>
.details-and-password-container {
  display: flex;
  padding: 15px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 12px;
}

.details-and-password-container .details-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.details-and-password-container .details-container .icon-and-mail-container {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.icon-and-mail-container > img {
  width: 40px;
  height: 40px;
}

.details-and-password-container
  .details-container
  .icon-and-mail-container
  .mail-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  overflow: hidden;
}

.details-and-password-container
  .details-container
  .icon-and-mail-container
  .mail-container
  .label {
  color: #111a18;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.details-and-password-container
  .details-container
  .icon-and-mail-container
  .mail-container
  .email {
  color: #111a1899;
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.details-and-password-container .details-container .logout-btn {
  display: flex;
  height: 40px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 12px;
  background: #000;
  color: #fff;
  text-align: right;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.details-and-password-container .password-and-change-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.details-and-password-container
  .password-and-change-container
  .password-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.details-and-password-container
  .password-and-change-container
  .password-container
  .label {
  color: #111a18;
  font-size: 20px;
  font-weight: 600;
}

.details-and-password-container
  .password-and-change-container
  .password-container
  .password {
  color: #111a1899;
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.details-and-password-container
  .password-and-change-container
  .change-password-btn {
  display: flex;
  height: 40px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 12px;
  border: 1.5px solid #000;
  background: rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.change-pwd-modal {
  max-width: 480px;
  padding: 48px;
}

.change-pwd-modal h1 {
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  margin: 0;
}

.change-pwd-modal h2 {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

.change-pwd-modal .input-container {
  margin-top: 20px;
}

.change-pwd-modal .error-message {
  color: #ea4335;
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
}

.change-pwd-modal input {
  margin-top: 10px;
}

.change-pwd-modal .buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
}

.change-pwd-modal .buttons-container .btn-cancel {
  background: #111a18;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

.change-pwd-modal .buttons-container .btn-save {
  background: #0ea982;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

@media screen and (max-width: 1024px) {
  .details-and-password-container .details-container .logout-btn {
    display: none;
  }

  .icon-and-mail-container > img {
    display: none;
  }

  .details-and-password-container
    .details-container
    .icon-and-mail-container
    .mail-container
    .email {
    font-size: 16px;
  }

  .details-and-password-container
    .password-and-change-container
    .password-container
    .password {
    font-size: 16px;
  }

  .change-pwd-modal {
    max-width: 300px;
    padding: 36px;
  }

  .change-pwd-modal h1 {
    font-size: 24px;
  }

  .change-pwd-modal p {
    font-size: 10px;
  }

  .change-pwd-modal .buttons-container .btn {
    font-size: 10px;
    padding: 10px;
    border-radius: 12px;
  }
}

@media screen and (max-width: 380px) {
  .details-and-password-container .password-and-change-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}
</style>
