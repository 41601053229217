<script setup>
import { sendEvent } from "@/helpers/cerebro";
import { fetchAllProducts } from "@/helpers/stripe";
import { onMounted, ref, watch } from "vue";
import ListPaywallProductButton from "./ListPaywallProductButton.vue";

const props = defineProps(["paywallSource", "checkoutFunc", "closeFunc"]);

const { products } = fetchAllProducts();

const selectedProduct = ref();
const benefits = [
  "50 Credits/day",
  "50% off all Credit Packs & AI Avatar",
  "Fast processing",
];

onMounted(() => {
  if (products && products[1] && products[1].id) {
    selectedProduct.value = products[1].id;
  } else {
    const unwatch = watch(
      () => products,
      (newValue, _) => {
        if (newValue && newValue[1]) {
          selectedProduct.value = newValue[1].id;
          unwatch();
        }
      }
    );
  }
});

const continueCheckout = () => {
  sendEvent({
    eventName: "paywall",
    properties: {
      source: props.paywallSource,
      paywallType: "list",
      productIdentifier: selectedProduct.value.toString(),
    },
    eventGroup: "subscription",
  });
  if (!selectedProduct.value) return;
  const product = products.value.find(
    (item) => item.id == selectedProduct.value
  );

  props.checkoutFunc(
    product,
    benefits.map((item) => {
      return {
        icon: "https://storage.googleapis.com/wonder-eed94.appspot.com/website_images/tick_in_circle.png",
        title: item,
      };
    })
  );
};
</script>

<template>
  <div class="container">
    <div class="close-btn" @click="closeFunc">
      <img src="@/assets/icons/close_icon.png" class="close-icon" alt="Close" />
    </div>
    <div class="img-container">
      <img
        src="https://storage.googleapis.com/wonder-eed94.appspot.com/website_images/onb_pyw_1.png"
        alt="Example of Wonder Artworks"
      />
    </div>
    <div class="products-container">
      <p class="title"><span class="light-text">Wonder</span> <b>Pro</b></p>
      <p class="faded-text sub-title">Create your own art without limits</p>
      <div class="benefits">
        <div
          v-for="(benefit, index) in benefits"
          :key="`benefit-${index}`"
          class="benefit-box"
        >
          <img
            src="@/assets/icons/tick_in_circle.png"
            class="benefit-icon"
            alt="Benefit icon"
          />&nbsp;&nbsp;
          <span>{{ benefit }}</span>
        </div>
      </div>
      <div class="products">
        <div v-if="products && products.length > 0">
          <ListPaywallProductButton
            :product="products[0]"
            :clickHandler="
              () => {
                selectedProduct = products[0].id;
              }
            "
            :isSelected="selectedProduct == products[0].id"
          ></ListPaywallProductButton>
          <ListPaywallProductButton
            :product="products[1]"
            :clickHandler="
              () => {
                selectedProduct = products[1].id;
              }
            "
            :isSelected="selectedProduct == products[1].id"
            badgeText="Save 70%"
          ></ListPaywallProductButton>
        </div>
        <button
          id="submit"
          class="btn dark-btn submit-btn"
          @click="continueCheckout"
        >
          Continue
        </button>
        <div class="sub-text-box">
          <span class="faded-text">
            <a
              href="https://static.wonderai.app/privacy"
              target="_blank"
              class="legal-link"
              >Privacy
            </a>
            and
            <a
              href="https://static.wonderai.app/terms-conditions"
              target="_blank"
              class="legal-link"
              >Terms</a
            >
          </span>
          <span class="bold">Cancel anytime</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  width: 100%;
  height: 100%;
  width: 100vw;
  height: 100vh;
}

.close-btn {
  position: absolute;
  left: 30px;
  top: 30px;
}

.close-icon {
  width: 50px;
  height: 50px;
}

.img-container {
  flex: 1;
  padding: 80px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.img-container img {
  max-width: 100%;
  max-height: 100%;
}

.products-container {
  width: 325px;
  margin: 32px;
  margin-right: 6%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 48px;
  line-height: 72px;
  margin: 8px 0px;
}

.sub-title {
  font-size: 14px;
  margin: 0;
}

.benefits {
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.benefit-box {
  display: flex;
  align-items: center;
  margin: 16px 0px;
}

.benefit-icon {
  width: 32px;
  height: 32px;
}

.submit-btn {
  width: 100%;
}

.sub-text-box {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 14px;
  line-height: 21px;
}

@media screen and (max-width: 1024px) {
  .title {
    line-height: 60px;
    margin: 8px 0px 0px 0px;
  }

  .benefits {
    font-size: 14px;
    line-height: 18px;
  }

  .benefit-icon {
    width: 20px;
    height: 20px;
  }
  .products-container {
    width: 100%;
    margin: 0;
    padding: 0px 25px;
  }
  .container {
    display: flex;
    flex-direction: column;
  }

  .img-container {
    flex: inherit;
    padding: 0;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .img-container img {
    width: 100%;
    height: auto;
    max-height: none;
  }
}
</style>
