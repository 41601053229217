import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// const authDomain = isStaging
//   ? import.meta.env.MODE === "development"
//     ? "chatapp-ffb0c.firebaseapp.com"
//     : "website-staging.askaichat.app"
//   : isPreProd
//     ? "website-preprod.askaichat.app"
//     : "askaichat.app";

const firebaseConfig = {
  apiKey: "AIzaSyCUrZ1Z_I74TrAAyECyGA5ieQk7UWxSZak",
  authDomain: "wonder-eed94.firebaseapp.com",
  projectId: "wonder-eed94",
  storageBucket: "wonder-eed94.appspot.com",
  messagingSenderId: "877003017560",
  appId: "1:877003017560:web:799d4acc6c95513135292d",
  measurementId: "G-BEZRHVDLYE",
};

const firebaseApp = initializeApp(firebaseConfig);
getAnalytics(firebaseApp);

export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp, "gs://wonder-users");
