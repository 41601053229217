import { useUserStore } from "@/store/user";
import { ref, watchEffect } from "vue";

export default function useConfigFetchStatus() {
  const userStore = useUserStore();
  const configFetchStatus = ref(CONFIG_FETCH_VALUES.FETCHING);

  watchEffect(() => {
    if (userStore.getParameter("configFailed"))
      configFetchStatus.value = CONFIG_FETCH_VALUES.FAILED;
    else if (userStore.getParameter("isl_keys"))
      configFetchStatus.value = CONFIG_FETCH_VALUES.FETCHED;
    else configFetchStatus.value = CONFIG_FETCH_VALUES.FETCHING;
  });
  return { configFetchStatus };
}

export const CONFIG_FETCH_VALUES = {
  FETCHING: "fetching",
  FETCHED: "fetched",
  FAILED: "failed",
};
