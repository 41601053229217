export default [
  "Cat wearing a gold chain and holding a gun,realistic portrait, symmetrical, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic lighting, art by artgerm and greg rutkowski and alphonse mucha",
  "Cyberpunk Tokyo, street view, in Digital Art style",
  "Old Jeddah city alley, night, roshan, old shops, horse, a man wearing a white robe standing watching over, dramatic lighting sci fi, by Caspar David Friedrich by Beeple and James Gilleard and Justin Gerard, centered, artstation, smooth, sharp focus, photoreal octane render, 3d, by Jean Baptiste Monge",
  "Living room with blue inflatable elements, 3D",
  "Realistic detailed image of an cosmonaut alone in the desert in the style of Francis Bacon, Surreal, Norman Rockwell and James Jean, Greg Hildebrandt, and Mark Brooks, triadic color scheme, by Greg Rutkowski, in the style of Francis Bacon and Syd Mead and Edward Hopper and Norman Rockwell and Beksinski, open ceiling, highly detailed, painted by Francis Bacon, painted by James Gilleard, surrealism, airbrush, Ilya Kuvshinov, wlop, stanley artgerm",
  "Teddy bears swimming at the Olympics 400m butterfly event",
  "A camel in a purple galaxy, realistic photography",
  "Downtown Mexico, string lights, colorful lighting, night, by Tooth Wu, by Lienzo Óleo Paisaje, by Greg Rutkowski",
  "Movie poster depicting krubi the hungarian popstar with lake balaton in the background, award winning art, cinematic light, dynamic composition, highly detailed, dramatic lighting, digital painting, concept art, masterpiece, by Leonardo da Vinci, raphael, artgerm, Greg Rutkowski, vibrant colors",
  "2d illustration of a castle in the sky, concept art by Hayao Miyazaki, featured on pixiv, fantasy art, concept art, official art, high detailed",
  "A portrait of a cowboy in 2070, intricate, highly detailed, digital painting, artstation, concept art, smooth, sharp focus, illustration, cinematic, vfx",
  "A hyper-detailed 3d render like a oil painting of the ocean’s dream of the upward, lifelike, photorealistic, digital painting, aesthetic, smooth, sharp focus, artstation HD, by Greg Rutkowski, Chris Tulloch McCabe, Valentina Remenar and Asher Duran",
  "A tau spacemarine in the style of Jean Giraud / Ralph Mcquarrie / Syd Mead / John Berkey trending on artstation deviantart pinterest detailed realistic high resolution hd 8k character portraits concept art",
  "Deer with glowing antlers, moonlight, art by artgerm and Greg Rutkowski, cinematic shot, intricate, photorealistic, artstation, realistic, 100 mm, photography, octane, high definition, depth of field, bokeh, 8k",
  "Angel in the world",
  "Assassin",
  "A cute house with balloons",
  "A photo of a cute white fur monster standing in a green room",
  "Woman with her dog watching sea on the shore",
  "A photo of Michelangelo’s sculpture of David wearing headphones DJing",
];
