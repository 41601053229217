<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue";

const isDroppedDown = ref(false);
const toggleDropdown = () => (isDroppedDown.value = !isDroppedDown.value);
const handleClickOutside = () => {
  isDroppedDown.value = false;
};
onMounted(() => {
  document.addEventListener("mouseup", handleClickOutside);
});
onBeforeUnmount(() => {
  document.removeEventListener("mouseup", handleClickOutside);
});
</script>

<template>
  <div class="dropdown">
    <span @click="toggleDropdown">
      <slot name="mainItem"></slot>
    </span>
    <div :class="`dropdown-content ${isDroppedDown ? 'active' : ''}`">
      <slot name="contents"></slot>
    </div>
  </div>
</template>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background: #f5f5f5;
  border-radius: 8px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  text-align: left;
  right: 0;
}

.dropdown-content.active {
  display: block;
}
</style>
