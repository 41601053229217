<script setup>
import SettingsFAQCard from "@/components/SettingsFAQCard.vue";
import SettingsLinksCard from "@/components/SettingsLinksCard.vue";
import SettingProfileAccountCard from "@/components/SettingsProfileAccountCard.vue";
import SettingsSubscriptionCard from "@/components/SettingsSubscriptionCard.vue";
import SettingsSupportCard from "@/components/SettingsSupportCard.vue";
import useDeviceType from "@/helpers/composables/useDeviceType";
import { useUserStore } from "@/store/user";
import { computed, ref } from "vue";

const { deviceType } = useDeviceType();
const userStore = useUserStore();

const isMobile = computed(() => deviceType.value === "mobile");
const isUserPro = computed(() => {
  return userStore.isUserPro();
});

const selectedTab = ref("general");
</script>
<template>
  <div class="container gap-20">
    <h1>Settings</h1>
    <div v-if="isMobile" class="tabs-container">
      <span
        :class="`tab ${selectedTab == 'general' ? 'selected' : ''}`"
        @click="() => (selectedTab = 'general')"
      >
        General
      </span>
      <span
        :class="`tab ${selectedTab == 'subscription' ? 'selected' : ''}`"
        @click="() => (selectedTab = 'subscription')"
      >
        Subscription
      </span>
    </div>
    <Transition name="fade" mode="out-in">
      <div
        class="cards-container gap-20"
        v-if="!isMobile || (isMobile && selectedTab == 'general')"
      >
        <div class="row gap-20">
          <div class="col gap-20">
            <div class="card grow gap-40"><SettingProfileAccountCard /></div>
            <div class="card" v-if="!isUserPro"><SettingsSupportCard /></div>
          </div>
          <div class="col gap-20">
            <div class="card" v-if="!isMobile">
              <SettingsSubscriptionCard />
            </div>
            <div class="card" v-if="isUserPro"><SettingsSupportCard /></div>
          </div>
        </div>
        <div class="card"><SettingsFAQCard /></div>
        <div class="card"><SettingsLinksCard /></div>
      </div>
      <div class="card" v-else-if="isMobile && selectedTab == 'subscription'">
        <SettingsSubscriptionCard />
      </div>
    </Transition>
  </div>
</template>
<style scoped>
.gap-20,
:deep(.gap-20) {
  gap: 20px;
}

.gap-40 {
  gap: 40px;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 40px 148px;
  text-align: left;
}

h1 {
  font-size: 48px;
  font-weight: 600;
  letter-spacing: -0.96px;
  text-align: left;
  align-self: stretch;
  margin: 0;
}

.tabs-container {
  display: flex;
  gap: 20px;
}

.tab {
  color: #000;
  opacity: 0.5;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.tab.selected {
  background: linear-gradient(139deg, #bd00ff 0%, #4f95ff 96.68%);
  opacity: 1;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cards-container {
  display: flex;
  flex-direction: column;
}

.card :deep(h2) {
  font-size: 28px;
  font-weight: 600;
  text-align: left;
  align-self: stretch;
  margin: 0;
}

.grow {
  flex-grow: 1;
}

.row {
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  width: 50%;
}

.card {
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  background: #fff;
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 8px 16px;
  }

  h1 {
    font-size: 24px;
    letter-spacing: -0.48px;
  }

  .row {
    flex-direction: column;
  }

  .col {
    width: 100%;
  }

  .card :deep(h2) {
    font-size: 24px;
  }

  .card {
    padding: 30px;
  }
}
</style>
