<script setup>
import { FAQs } from "@/constants/settings.js";
import { sendEvent } from "@/helpers/cerebro";
import { ref } from "vue";

const showFAQAnswer = ref(null);

const showAnswer = (index) => {
  if (showFAQAnswer.value === index) showFAQAnswer.value = null;
  else {
    showFAQAnswer.value = index;
    sendEvent({
      eventName: "faq_question_open",
      properties: { source: "settings", question: FAQs[index].question },
    });
  }
};
</script>

<template>
  <div class="faq-section-container gap-20">
    <h2>FAQ</h2>
    <div class="faq-list-container">
      <template v-for="(qa, index) in FAQs" :key="index">
        <div class="qa-container gap-20" @click="showAnswer(index)">
          <div class="qa-header">
            <span class="question">{{ qa.question }}</span>
            <img
              v-if="showFAQAnswer === index"
              src="@/assets/icons/arrow_up_icon.svg"
              class="arrow"
              alt="Close answer"
            />
            <img
              v-else
              src="@/assets/icons/arrow_down_icon.svg"
              class="arrow"
              alt="Open answer"
            />
          </div>
          <transition name="slide">
            <div
              v-if="showFAQAnswer === index"
              class="answer"
              v-html="qa.answer"
            ></div>
          </transition>
        </div>
        <div v-if="index != FAQs.length - 1" class="divider"></div>
      </template>
    </div>
  </div>
</template>

<style scoped>
.faq-section-container {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.faq-list-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.qa-container {
  display: flex;
  flex-direction: column;
}

.qa-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.question {
  color: #111a18;
  font-size: 18px;
  font-weight: 600;
}

.arrow {
  width: 24px;
  height: 24px;
}

.answer {
  color: #111a1899;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

.divider {
  height: 1px;
  background-color: #d9d9d9;
  width: 100%;
}

.slide-enter-active {
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave-from {
  max-height: 800px;
  overflow: hidden;
}

.slide-enter-from,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
