import { getAuth } from "firebase/auth";

import axios from "axios";

export const getIdToken = async () => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user !== null) {
    return user.getIdToken();
  } else {
    return undefined;
  }
};

const requestWithIdToken = async (params) => {
  const axiosConfig = {
    ...params,
  };
  const idToken = await getIdToken();
  if (idToken) {
    if (axiosConfig.headers) {
      axiosConfig.headers.authorization = idToken;
    } else {
      axiosConfig.headers = { authorization: idToken };
    }
    return await axios(axiosConfig);
  } else {
    return new Promise((_, reject) => reject(401));
  }
};

export default requestWithIdToken;
