<script setup>
import { onMounted, onUnmounted, ref } from "vue";
import CustomSpinner from "./CustomSpinner.vue";

const isFixed = ref(false);

defineProps(["promptHistory", "isActive"]);

const handleSideBarPositioning = (e) => {
  const lastKnownScrollPosition = window.scrollY;
  if (lastKnownScrollPosition >= 75) {
    isFixed.value = true;
  } else {
    isFixed.value = false;
  }
};
onMounted(() => {
  document.addEventListener("scroll", handleSideBarPositioning);
});

onUnmounted(() => {
  document.removeEventListener("scroll", handleSideBarPositioning);
});
</script>

<template>
  <div
    id="sidebar"
    :class="`${isActive ? 'active' : ''} ${isFixed ? 'fixed' : ''}`"
  >
    <p id="sidebar-title">
      <span
        ><img
          @click="$emit('toggleSideBar')"
          src="@/assets/icons/vertical_left_icon.png"
          alt="history"
          class="history-toggle"
        /> </span
      >Prompt History
    </p>
    <div v-if="promptHistory == undefined" class="loading-box">
      <CustomSpinner></CustomSpinner>
    </div>
    <div v-else-if="promptHistory.length == 0">
      <p>
        You do not have any prompts. Start by generating an image from your
        prompt.
      </p>
    </div>
    <div
      v-else
      class="sidebar-item"
      v-for="(prompt, index) in promptHistory"
      :key="`prompt-history-item-${index}`"
    >
      {{ prompt }}
    </div>
  </div>
</template>

<style scoped>
#sidebar {
  right: -35%;
  width: 0%;
  display: inline-block;
  background: #f2f2f2;
  border-radius: 12px;
  vertical-align: top;
  height: 100%;
  height: 100vh;
  overflow: auto;
  text-align: left;
  padding: 0;
  transition: right 0.33s;
  position: absolute;
}

#sidebar.active {
  padding: 16px;
  width: 18%;
  right: 0;
  top: 75px;
  height: 100%;
  height: 100vh;
  transition: right 0.33s;
}

#sidebar.active.fixed {
  position: fixed;
  top: 0;
  height: 100vh;
}

.sidebar-item {
  background-color: #fff;
  color: #000;
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

#sidebar-title {
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: #111a18;
  margin-bottom: 32px;
}

.loading-box {
  display: flex;
  height: 100vh;
  height: calc(100vh - 180px);
  align-items: center;
  justify-content: center;
}

.history-toggle {
  display: none;
}

@media screen and (max-width: 1024px) {
  .history-toggle {
    display: inline-block;
    width: 28px;
    height: 28px;
  }
  #sidebar-title {
    display: flex;
    justify-content: space-between;
  }

  #sidebar.active {
    padding: 16px;
    width: 50%;
    right: 0;
    transition: right 0.33s;
  }
}
</style>
