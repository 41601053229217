<script setup>
import { sendEvent } from "@/helpers/cerebro";
import { ref } from "vue";

const props = defineProps([
  "class",
  "id",
  "href",
  "target",
  "eventName",
  "eventProperties",
]);
const status = ref("idle");
const clickHandler = async () => {
  if (status.value == "idle") {
    status.value = "redirecting";

    let eventJson = {
      eventName: props.eventName,
      eventGroup: "forward",
    };
    if (props.eventProperties) eventJson.properties = props.eventProperties;

    await sendEvent(eventJson);
    status.value = "idle";
  }
};
</script>
<template>
  <a
    :id="id"
    :class="props.class"
    :href="props.href"
    :target="props.target"
    @click="clickHandler"
  >
    <slot></slot>
  </a>
</template>

<style scoped>
a {
  cursor: pointer;
}
</style>
