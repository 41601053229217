import { getAuth, sendEmailVerification } from "firebase/auth";

export const sendVerificationEmail = async (source) => {
  const auth = getAuth();
  let path = "/signin";
  if (source === "onboarding") {
    path += "?onboarding=true";
  } else if (source && source.startsWith("Home_") && source !== "Home_Middle") {
    path += `?home=true&position=${source.split("_")[1]}`;
  } else if (source === "Home_Middle") {
    path += "?free=true";
  }
  return await sendEmailVerification(auth.currentUser, {
    url: window.location.origin + path,
  });
};

export const isVerificationRequired = (authUser) => {
  return (
    authUser.providerData[0].providerId === "password" &&
    !authUser.emailVerified
  );
};
