import { ConstUserLimits } from "@/constants/userLimits";
import { sendUserEvent } from "@/helpers/cerebro";
import { dateObjToString } from "@/helpers/dateFunctions";
import { isStaging } from "@/helpers/environment";
import { loginActions } from "@/helpers/userManager";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

export const useUserStore = defineStore("user", () => {
  const user = ref();
  const userData = ref({});
  const userConfig = ref({});
  const checkForPaywall = ref(!!localStorage.getItem("cfp"));
  const checkForPaywallSource = ref(localStorage.getItem("cfpSource"));
  const cerebroId = ref();
  const userLimits = computed(() => {
    if (userConfig.value && userConfig.value["credit_configuration"]) {
      try {
        return JSON.parse(userConfig.value["credit_configuration"]);
      } catch (error) {
        if (isStaging()) console.error("Error parsing user limits", error);
        return ConstUserLimits;
      }
    }

    if (isStaging()) console.warn("Using hard-coded limits");
    return ConstUserLimits;
  });

  const setUserData = (data) => {
    userData.value = data;
  };

  const setUser = async (authUser, isJustRefetch, source) => {
    if (authUser) {
      const dataToSet = {
        userId: authUser.uid,
        email: authUser.email,
        createdAt: authUser.metadata.createdAt,
        providerData: authUser.providerData,
      };

      const userDataPayload = await loginActions(
        authUser,
        dataToSet,
        isJustRefetch,
        source
      );
      setUserData({ ...userDataPayload, ...dataToSet });
      user.value = authUser;
    } else {
      setUserData({});
      sendUserEvent();
      user.value = null;
    }
  };

  const editUserData = (newUserData) => {
    for (let key in newUserData) {
      userData.value[key] = newUserData[key];
    }
  };

  const getCurrentUser = () => {
    return new Promise((resolve, _) => {
      if (user.value === undefined) {
        const removeListener = watch(user, (value) => {
          removeListener();
          resolve(value);
        });
      } else {
        resolve(user.value);
      }
    });
  };

  const setCheckForPaywall = ({ val, source }) => {
    if (val) {
      localStorage.setItem("cfp", "true");
      localStorage.setItem("cfpSource", source);
    } else {
      localStorage.removeItem("cfp");
      localStorage.removeItem("cfpSource");
    }
    checkForPaywall.value = val;
    checkForPaywallSource.value = source;
  };

  const getCheckForPaywallStatus = () => {
    return checkForPaywall.value;
  };

  const getCheckForPaywallSource = () => {
    return checkForPaywallSource.value;
  };

  const isUserPro = () => {
    let isPro = false;
    if (userConfig.value && userConfig.value["premium"] === "true") {
      isPro = true;
    } else if (userData.value) {
      isPro =
        userData.value.subscriptions &&
        Object.keys(userData.value.subscriptions).length > 0;
    }
    return isPro;
  };

  const dailyLimitUsage = () => {
    if (userData.value && userData.value.dailyProcessCounts) {
      const today = dateObjToString(new Date());
      return userData.value.dailyProcessCounts[today] || 0;
    }
    return 0;
  };

  const getCredits = () => {
    const data = userData.value;
    if (!data.userId) return -1;

    let totalCredits = 0;
    if (
      data &&
      data.consumables &&
      data.consumables.credits &&
      Object.keys(data.consumables.credits).length > 0
    ) {
      const credits = data.consumables.credits;
      totalCredits = Object.values(credits).reduce((a, b) => a + b);
    }
    const totalLimit = isUserPro()
      ? userLimits.value.daily_limit_pro
      : userLimits.value.daily_limit_free;
    const remainingLimits = totalLimit - dailyLimitUsage();

    if (isStaging()) {
      console.log(
        totalCredits,
        remainingLimits,
        totalLimit,
        dailyLimitUsage(),
        isUserPro(),
        userLimits.value
      );
    }
    return totalCredits + remainingLimits;
  };

  const setCerebroId = (cid) => {
    cerebroId.value = cid;
  };

  const setConfig = (data) => {
    userConfig.value = data;
  };

  const getParameter = (key) => {
    if (userConfig.value && userConfig.value[key]) {
      try {
        return JSON.parse(userConfig.value[key]);
      } catch (error) {
        return userConfig.value[key];
      }
    }
  };

  const setParameter = (key, value) => {
    userConfig.value[key] = JSON.stringify(value);
  };

  return {
    user,
    userData,
    cerebroId,
    setUser,
    getCurrentUser,
    setCerebroId,
    editUserData,
    setCheckForPaywall,
    getCheckForPaywallStatus,
    getCheckForPaywallSource,
    isUserPro,
    getCredits,
    setConfig,
    getParameter,
    setParameter,
  };
});
