export const promptPart1Options = [
  "An astronaut",
  "A Princess",
  "A monkey",
  "A teddy bear",
];

export const promptPart2Options = [
  "Riding a horse",
  "Playing poker",
  "Drinking margarita",
  "Reading a book",
];

export const promptPart3Options = [
  "Hyper Realistic",
  "Pen&Ink",
  "Toon",
  "Epic",
];
