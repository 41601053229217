import router from "@/router";
import { useUserStore } from "@/store/user";
import { onMounted, ref } from "vue";
import { getCerebroId, sendEvent } from "./cerebro";
import { sendGTagEvent } from "./gtag";
import { getPlanNameFromProduct, getPriceTextFromProduct } from "./product";
import requestWithIdToken from "./requestWithIdToken";

const paymentHandler = process.env.VUE_APP_PAYMENT_HANDLER_BASE_URL;

export const fetchAllProducts = (isConsumable) => {
  const userStore = useUserStore();
  const products = ref([]);

  onMounted(async () => {
    try {
      await userStore.getCurrentUser();
      const productsResponse = await requestWithIdToken({
        method: "GET",
        url: `${paymentHandler}/get-products`,
        params: {
          consumable: isConsumable,
        },
      });
      products.value = productsResponse.data;
    } catch (error) {
      console.log(error);
    }
  });

  return { products };
};

export const getProductsByIds = async (ids) => {
  const productsResponse = await requestWithIdToken({
    method: "GET",
    url: `${paymentHandler}/get-selected-products`,
    params: {
      iap_ids: ids,
      platform: "web",
    },
  });
  return productsResponse.data;
};

export const initiateCheckout = async (product, advantages) => {
  const isRecurring = product.price.type == "recurring";
  const subscriptionResponse = await requestWithIdToken({
    method: "POST",
    url: `${paymentHandler}/initiate-checkout`,
    data: {
      pid: product.price.id,
      isRecurring,
      cid: getCerebroId(),
    },
  });
  if (
    subscriptionResponse &&
    subscriptionResponse.data &&
    subscriptionResponse.data.success
  ) {
    sendGTagEvent({
      event: "checkout",
      purchase_value: product.price.unit_amount / 100,
      currency_code: "USD",
      item_checkedout: product.iap_id,
      item_id_number: product.iap_id,
      category: isRecurring ? "subscription" : "oneTime",
      item_value: product.price.unit_amount / 100,
      quantity_bought: 1,
      // 'coupon': 'Wonder10',
      // 'discount_value': 2.00
    });
    router.push({
      name: "Checkout",
      params: {
        clientSecret: subscriptionResponse.data.payload.clientSecret,
        subscription:
          subscriptionResponse.data.payload.subscriptionId || product.iap_id,
      },
      state: {
        productName: getPlanNameFromProduct(product) + " Plan",
        priceText: getPriceTextFromProduct(product),
        advantages,
      },
      query: {
        pid: product.iap_id,
        stid: product.price.id,
      },
    });
  } else {
    console.log(subscriptionResponse);
    alert("An unexpected error occured.");
  }
};

export const cancelStripeSubscription = async (subscriptionId) => {
  try {
    await requestWithIdToken({
      path: "/cancel-subscription",
      method: "POST",
      data: {
        subscriptionId,
      },
    });
    const userStore = useUserStore();
    const userData = userStore.userData;
    userData.subscriptions[subscriptionId].is_auto_renew_on = false;
    userStore.editUserData(userData);
  } catch (error) {
    console.log(error);
    sendEvent({
      eventName: "cancel_subs_error",
      eventGroup: "subscription",
      properties: {},
    });
    alert(
      "Error occured while cancelling subscription. If the problem persists, please contact support at wonderweb@codeway.co"
    );
  }
};
