<script setup>
import { sendEvent } from "@/helpers/cerebro";
import { onMounted, onUnmounted, ref } from "vue";

const props = defineProps({ arrows: { default: true } });

let slider;
let isDown = false;
let startX;
let scrollLeft;

const isAtStart = ref(true);
const isAtEnd = ref(false);

const scroll = (stepLength, stepDuration, maxSteps, step = 0) => {
  if (step != maxSteps) {
    setTimeout(() => {
      const newScrollPoisiton = Math.round(slider.scrollLeft + stepLength);
      slider.scrollLeft = newScrollPoisiton;
      step++;
      if (slider.scrollLeft == newScrollPoisiton) {
        scroll(stepLength, stepDuration, maxSteps, step);
      }
    }, stepDuration);
  }
};

const scrollSlider = (isLeft) => {
  const sliderWidth = slider.clientWidth;
  let scrollLength = sliderWidth / 3;
  if (scrollLength < 300) scrollLength = 300;
  const scrollAmount = isLeft ? -1 * scrollLength : scrollLength;
  const steps = 120;
  const duration = 500;
  scroll(scrollAmount / steps, duration / steps, 60);
};

// Define event handlers
const handleMouseDown = (e) => {
  isDown = true;
  slider.classList.add("active");
  startX = e.pageX - slider.offsetLeft;
  scrollLeft = slider.scrollLeft;
};

const handleMouseLeave = () => {
  isDown = false;
  slider.classList.remove("active");
};

const handleMouseUp = () => {
  isDown = false;
  slider.classList.remove("active");
};

const handleMouseMove = (e) => {
  if (!isDown) return;
  e.preventDefault();
  const x = e.pageX - slider.offsetLeft;
  const walk = x - startX; //scroll-fast
  slider.scrollLeft = scrollLeft - walk;
};

const handleScroll = (e) => {
  // Disables the arrows at the left and right most positions
  const scrollLeft = slider.scrollLeft;
  const maxScrollLeft = slider.scrollWidth - slider.clientWidth;

  isAtStart.value = scrollLeft === 0;
  isAtEnd.value = scrollLeft >= maxScrollLeft;
};

const handleLeftArrowClick = () => {
  sendEvent({ eventName: "click_left_button" });
  scrollSlider(true);
};

const handleRightArrowClick = () => {
  sendEvent({ eventName: "click_right_button" });
  scrollSlider(false);
};

onMounted(() => {
  slider = document.querySelector(".items");

  slider.addEventListener("mousedown", handleMouseDown);
  slider.addEventListener("mouseleave", handleMouseLeave);
  slider.addEventListener("mouseup", handleMouseUp);
  slider.addEventListener("mousemove", handleMouseMove);
  slider.addEventListener("scroll", handleScroll);

  if (props.arrows) {
    const leftArrow = document.querySelector("#left-arrow");
    const rightArrow = document.querySelector("#right-arrow");

    leftArrow.addEventListener("click", handleLeftArrowClick);
    rightArrow.addEventListener("click", handleRightArrowClick);
  }
});

onUnmounted(() => {
  slider.removeEventListener("mousedown", handleMouseDown);
  slider.removeEventListener("mouseleave", handleMouseLeave);
  slider.removeEventListener("mouseup", handleMouseUp);
  slider.removeEventListener("mousemove", handleMouseMove);
  slider.removeEventListener("scroll", handleScroll);

  if (props.arrows) {
    const leftArrow = document.querySelector("#left-arrow");
    const rightArrow = document.querySelector("#right-arrow");

    if (leftArrow && rightArrow) {
      leftArrow.removeEventListener("click", handleLeftArrowClick);
      rightArrow.removeEventListener("click", handleRightArrowClick);
    }
  }
});

const scrollToPosition = (targetPosition, duration = 500) => {
  const start = slider.scrollLeft;
  const change = targetPosition - start;
  const startTime = performance.now();

  function animateScroll(timestamp) {
    var timeElapsed = timestamp - startTime;
    var progress = Math.min(timeElapsed / duration, 1);

    slider.scrollLeft = start + change * progress;

    if (timeElapsed < duration) {
      requestAnimationFrame(animateScroll);
    }
  }

  requestAnimationFrame(animateScroll);
};
defineExpose({ scrollToPosition });
</script>

<template>
  <div class="slider-wrap">
    <img
      v-if="arrows"
      src="@/assets/icons/right_icon.svg"
      alt="Left Arrow"
      :class="`arrows ${isAtStart ? 'disabled' : ''}`"
      id="left-arrow"
    />
    <div class="items">
      <slot></slot>
    </div>
    <img
      v-if="arrows"
      src="@/assets/icons/right_icon.svg"
      alt="Right Arrow"
      :class="`arrows ${isAtEnd ? 'disabled' : ''}`"
      id="right-arrow"
    />
  </div>
</template>

<style scoped>
.slider-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.slider-wrap .items::-webkit-scrollbar {
  display: none;
}

.items {
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  will-change: transform;
  user-select: none;
  cursor: pointer;
}

.items.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

.arrows {
  width: 30px;
  margin: 0px 30px;
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

#left-arrow {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

@media screen and (max-width: 1024px) {
  .arrows {
    width: 20px;
    margin: 0px;
  }
  .items {
    margin: 0px 15px;
  }
}
</style>
