import { isStaging } from "../helpers/environment";

if (isStaging()) console.log("staging mode");

const STAGING_COLLECTION_NAMES = Object.freeze({
  USERS: "staging-users-v2",
});

const PRODUCTION_COLLECTION_NAMES = Object.freeze({
  USERS: "users-v2",
});

export const COLLECTION_NAMES = isStaging()
  ? STAGING_COLLECTION_NAMES
  : PRODUCTION_COLLECTION_NAMES;
