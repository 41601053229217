<script setup>
import CustomSpinner from "@/components/CustomSpinner.vue";
import { getFileNameFromUrl } from "@/helpers/common";
import { isStaging } from "@/helpers/environment";

const props = defineProps([
  "type",
  "packNumber",
  "imgList",
  "setLoadingModal",
  "setLoadingPercent",
  "sendGalleryEvent",
]);

const downloadAll = async () => {
  props.setLoadingModal(true);
  props.setLoadingPercent(0);
  let imagesProcessed = 0;
  const totalImages = props.imgList.length;
  try {
    const zip = new window.JSZip(); // JSZip is available globally via the CDN
    const imageFolder = zip.folder(`${props.type}s`); // Create a folder in the ZIP

    const fetchImagePromises = props.imgList.map(async (url, index) => {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName =
        getFileNameFromUrl(url) || `${props.type}_${index + 1}.jpg`;
      imageFolder.file(fileName, blob);

      // Update the loading progress
      imagesProcessed++;
      props.setLoadingPercent(
        Math.round((imagesProcessed / totalImages) * 100)
      );
    });

    // Wait for all images to be fetched and added to the ZIP
    await Promise.all(fetchImagePromises);

    // Generate the ZIP file
    const zipBlob = await zip.generateAsync({ type: "blob" });

    // Create a link and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(zipBlob);
    link.download = `${props.type}s${
      props.packNumber ? "_pack" + props.packNumber : ""
    }.zip`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Send the gallery event
    props.sendGalleryEvent({ eventName: "click_download_all" });

    setTimeout(() => {
      props.setLoadingModal(false);
      props.setLoadingPercent(0);
    }, 1000);
  } catch (error) {
    if (isStaging()) console.error(error);
    props.setLoadingModal(false);
    props.setLoadingPercent(0);
  }
};

const download = async (imageSrc) => {
  try {
    const image = await fetch(imageSrc);
    const imageBlob = await image.blob();
    const imageURL = URL.createObjectURL(imageBlob);

    const link = document.createElement("a");
    link.href = imageURL;
    link.download = getFileNameFromUrl(imageSrc);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    props.sendGalleryEvent({ eventName: "click_download", artURL: imageSrc });
  } catch (error) {
    if (isStaging()) console.error(error);
  }
};
</script>
<template>
  <div class="container">
    <div v-if="imgList == undefined">
      <CustomSpinner />
    </div>
    <div v-else-if="imgList != undefined && !imgList.length" class="empty">
      <img src="@/assets/icons/empty_icon.svg" />
      There are no {{ type }}s available.
    </div>
    <div v-else class="container-header">
      <span v-if="props.packNumber" class="pack-name">
        Pack #{{ props.packNumber }}
      </span>
      <span class="download-all-btn" @click="() => downloadAll('artwork')">
        Download All
      </span>
    </div>
    <div class="img-list">
      <div
        v-for="(imgURL, index) in props.imgList"
        :key="`gallery-list-${index}`"
        class="img-wrapper"
      >
        <img
          :src="imgURL"
          loading="lazy"
          class="img"
          height="800"
          width="800"
          :alt="`Artwork ${index + 1}`"
        />
        <img
          src="@/assets/icons/download_icon.svg"
          loading="lazy"
          class="download-img"
          alt="Download"
          @click="() => download(imgURL)"
        />
      </div>
    </div>
  </div>
</template>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 12px;
}

.container .container-header {
  display: flex;
  align-items: center;
}

.container .container-header .pack-name {
  color: #1c1d21;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
}

.container .container-header .download-all-btn {
  color: #6941c6;
  font-size: 14px;
  font-weight: 600;
  line-height: 142.857%;
  cursor: pointer;
  margin-left: auto;
}

.container .img-list {
  width: 100%;
  column-count: 2;
  column-gap: 10px;
}

.container .img-list .img-wrapper {
  display: flex;
  width: 100%;
  padding-bottom: 10px;
  position: relative;
}

.container .img-list .img-wrapper .img {
  width: 100%;
  height: auto;
  border-radius: 12px;
  background-color: #f2f4f7;
}

.container .img-list .img-wrapper .download-img {
  position: absolute;
  right: 10px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 50%;
  background-color: #1c1d2180;
  cursor: pointer;
}
</style>
