import axios from "axios";

const currencyManagerBaseUrl = "https://currency-manager.cdwapi.com";
const currencyManagerApiKey = "Su4ycZ7tY6GFmFu1XuxW";

export const determineCurrencySymbol = (currency) => {
  switch (currency) {
    case "USD":
      return "$";
    case "MXN":
      return "M$";
    case "BRL":
      return "R$";
    case "AUD":
      return "A$";
    case "CAD":
      return "C$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    case "HKD":
      return "HK$";
    case "THB":
      return "฿";
    case "CNY":
      return "CN¥";
    case "KRW":
      return "₩";
    case "TWD":
      return "NT$";
    case "SGD":
      return "S$";
    case "INR":
      return "₹";
    case "CHF":
      return "CHF";
    case "NOK":
      return "kr";
    case "NZD":
      return "NZ$";
    case "JPY":
      return "¥";
    default:
      return "$";
  }
};

export const convertToUsd = async (price, currency) => {
  const url = `${currencyManagerBaseUrl}/convert-to-usd?currency=${currency}&amount=${price}`;

  const response = await axios.get(url, {
    headers: {
      Authorization: `${currencyManagerApiKey}`,
    },
  });
  return response.data.data.usd_amount;
};
