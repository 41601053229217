export const GeneralConstants = Object.freeze({
  stagingCollectionName: "staging-images",
  collectionName: "images",
  stagingVideoCollectionName: "staging-videos",
  videoCollectionName: "videos",
});

export const APP_VERSION = "1.4.0";

export const APP_ID = "com.codeway.wonderweb";

export const COUPON_MANAGER_PRODUCTS = [
  "com.wonder.consumable.5variation.10styles.t3",
  "com.wonder.consumable.10variation.10styles.t3",
  "com.wonder.consumable.20variation.10styles.t3",
];
