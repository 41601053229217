<script setup>
import { sendEvent } from "@/helpers/cerebro";
import { handleSignout } from "@/helpers/userManager";
import router from "@/router";
import { useUserStore } from "@/store/user";
import { computed } from "vue";
import { useRoute } from "vue-router";
import socialLinks from "../constants/socialLinks";
import DropdownMenu from "./DropdownMenu.vue";
import HamburgerSlideMenu from "./HamburgerSlideMenu.vue";
import LinkTag from "./LinkTag.vue";
import NavBarPaywallBox from "./NavBarPaywallBox.vue";

const route = useRoute();

const userStore = useUserStore();

const isLanding = computed(() => route.name == "Landing");

const isLoggedIn = computed(() => !!userStore.user);

const isText2vidEnabled = computed(() => {
  return userStore.getParameter("text2vid_configuration")?.enabled ?? false;
});

const profilePhoto = computed(() => {
  const userData = userStore.userData;
  return (
    userData.profilePhoto ||
    "https://storage.googleapis.com/wonder-eed94.appspot.com/website_images/default_profile_photo.png"
  );
});
</script>

<template>
  <div>
    <div class="navbar desktop-navbar">
      <div v-if="isLoggedIn">
        <div class="nav-left">
          <router-link
            :to="{ name: 'Home' }"
            class="home-link"
            @click="
              () => {
                if (route.name === 'Home') router.go(0);
                sendEvent({
                  eventName: 'click_home',
                  properties: { source: 'Navbar' },
                });
              }
            "
          >
            <img
              src="@/assets/images/wonder_logo.png"
              alt="Wonder logo"
              style="width: 123px"
            />
          </router-link>
          <router-link
            :to="{ name: 'Text2Vid' }"
            @click="
              () => {
                sendEvent({ eventName: 'click_text2vid' });
              }
            "
            v-if="isText2vidEnabled"
          >
            <div :class="route.name === 'Text2Vid' ? 'active' : ''">
              <img
                src="@/assets/images/text2vid_logo.svg"
                style="width: 20px; height: 20px"
                alt="Text2Vid"
              />
              Text2Vid
            </div>
          </router-link>
          <router-link
            :to="{ name: 'MyCollection' }"
            @click="
              () => {
                sendEvent({ eventName: 'click_my_collection' });
              }
            "
          >
            <div :class="route.name === 'MyCollection' ? 'active' : ''">
              My Collection
            </div>
          </router-link>
          <router-link
            :to="{ name: 'Feed' }"
            @click="
              () => {
                sendEvent({ eventName: 'click_my_feed' });
              }
            "
          >
            <div :class="route.name === 'Feed' ? 'active' : ''">My Feed</div>
          </router-link>
        </div>
        <div class="nav-right">
          <NavBarPaywallBox />
          <dropdown-menu>
            <template v-slot:mainItem>
              <div
                id="dropdown-menu-btn"
                @click="
                  () => {
                    sendEvent({ eventName: 'click_profile' });
                  }
                "
              >
                <img :src="profilePhoto" alt="Profile Photo" />
                <img
                  src="@/assets/icons/down_arrow.svg"
                  class="down-icon"
                  alt=""
                />
              </div>
            </template>
            <template v-slot:contents>
              <router-link
                :to="{ name: 'Settings' }"
                class="dropdown-item"
                @click="
                  () => {
                    sendEvent({ eventName: 'click_settings' });
                  }
                "
              >
                Settings
              </router-link>
              <div @click="handleSignout" class="pointer dropdown-item">
                <span>Signout</span>
                <img
                  src="@/assets/icons/logout_icon.png"
                  style="width: 20px; height: 20px"
                  alt="Signout"
                />
              </div>
            </template>
          </dropdown-menu>
        </div>
      </div>
      <div v-else>
        <div
          class="nav-left"
          :style="isLanding ? '' : 'justify-content: center;'"
        >
          <router-link :to="{ name: 'Landing' }" class="home-link">
            <img
              src="@/assets/images/wonder_logo.png"
              alt="Wonder logo"
              style="width: 123px"
            />
          </router-link>
        </div>
        <div class="nav-right" v-if="isLanding">
          <LinkTag
            v-for="(item, index) in socialLinks"
            :href="item.link"
            :key="`social-link-${index}`"
            target="_blank"
            :eventName="item.eventName"
          >
            <div class="social-item">
              <img :src="item.image" :alt="item.name" />
              {{ item.name }}
            </div>
          </LinkTag>
          <router-link
            :to="{ name: 'Signup' }"
            @click="
              () =>
                sendEvent({
                  eventName: 'click_sign_up',
                  eventGroup: 'authentication',
                  properties: { source: 'Navbar' },
                })
            "
          >
            <button class="btn" id="login-btn">Sign up</button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="navbar mobile-navbar">
      <div v-if="isLoggedIn">
        <div class="nav-left">
          <router-link
            :to="{ name: 'Home' }"
            class="home-link"
            @click="
              () => {
                if (route.name === 'Home') router.go(0);
                sendEvent({
                  eventName: 'click_home',
                  properties: { source: 'Navbar' },
                });
              }
            "
          >
            <img
              src="@/assets/images/wonder_logo.png"
              alt="Wonder logo"
              style="width: 123px"
            />
          </router-link>
        </div>
        <div class="nav-right">
          <HamburgerSlideMenu>
            <p class="dropdown-title mb-16">Your pages</p>
            <router-link
              :to="{ name: 'Text2Vid' }"
              class="dropdown-item mb-8"
              @click="
                () => {
                  sendEvent({ eventName: 'click_text2vid' });
                }
              "
              v-if="isText2vidEnabled"
            >
              <img
                src="@/assets/images/text2vid_logo.svg"
                style="width: 20px; height: 20px"
                alt="Text2Vid"
              />
              Text2Vid
            </router-link>
            <router-link
              :to="{ name: 'MyCollection' }"
              class="dropdown-item mb-8"
              @click="
                () => {
                  sendEvent({
                    eventName: 'click_my_collecion',
                    properties: { source: 'Navbar' },
                  });
                }
              "
            >
              My Collection
            </router-link>
            <router-link
              :to="{ name: 'Feed' }"
              class="dropdown-item mb-16"
              @click="
                () => {
                  sendEvent({
                    eventName: 'click_my_feed',
                    properties: { source: 'Navbar' },
                  });
                }
              "
            >
              My Feed
            </router-link>
            <p class="dropdown-title mb-16"></p>
            <router-link
              :to="{ name: 'Settings' }"
              class="dropdown-item mb-8"
              @click="
                () => {
                  sendEvent({ eventName: 'click_settings' });
                }
              "
            >
              Settings
            </router-link>
            <div @click="handleSignout" class="pointer dropdown-item">
              <span>Signout</span>
              <img
                src="@/assets/icons/logout_icon.png"
                style="width: 20px; height: 20px"
                alt="Signout"
              />
            </div>
          </HamburgerSlideMenu>
        </div>
      </div>
      <div v-else>
        <div
          class="nav-left"
          :style="isLanding ? '' : 'justify-content: center;'"
        >
          <router-link :to="{ name: 'Landing' }" class="home-link">
            <img
              src="@/assets/images/wonder_logo.png"
              alt="Wonder logo"
              style="width: 123px"
            />
          </router-link>
        </div>
        <div class="nav-right" v-if="isLanding">
          <HamburgerSlideMenu>
            <LinkTag
              v-for="(item, index) in socialLinks"
              :href="item.link"
              :key="`social-link-${index}`"
              target="_blank"
              :eventName="item.eventName"
            >
              <div class="social-item">
                <img :src="item.image" :alt="item.name" />
                {{ item.name }}
              </div>
            </LinkTag>
            <router-link :to="{ name: 'Signup' }">
              <button
                class="btn"
                id="login-btn"
                @click="
                  () =>
                    sendEvent({
                      eventName: 'click_sign_up',
                      eventGroup: 'authentication',
                      properties: { source: 'Navbar' },
                    })
                "
              >
                Sign up
              </button>
            </router-link>
          </HamburgerSlideMenu>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.navbar.mobile-navbar {
  display: none;
}

.navbar.mobile-navbar .social-item {
  color: #000;
}

.home-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar {
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  width: 100%;
  padding: 0 1%;
  height: 75px;
  font-size: 17px;
  font-weight: 600;
}

.nav-right {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
}

.nav-right a {
  display: flex;
  align-items: center;
}

.nav-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.nav-left > a {
  display: flex;
}

.nav-left > a > div {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #111a18;
  opacity: 0.5;
}

.nav-left > a > div.active {
  opacity: 1;
}

.navbar > div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.social-item {
  display: inline-flex;
  align-items: center;
  margin-right: 24px;
}

.social-item > img {
  margin-right: 8px;
}

#login-btn {
  height: 42px;
  padding: 8px 16px;
  background-color: #000;
  color: #fff;
  white-space: nowrap;
}

.nav-left > * {
  margin-left: 20px;
}

#dropdown-menu-btn {
  display: flex;
  cursor: pointer;
}

#dropdown-menu-btn img {
  width: 42px;
  height: 42px;
  border-radius: 100%;
}

#dropdown-menu-btn .down-icon {
  width: 20px;
  height: auto;
  margin-left: 12px;
}

.dropdown-title {
  font-weight: 600;
  font-size: 14px;
  color: #111a18;
  opacity: 0.5;
}

.dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8px;
  font-weight: 600;
  font-size: 17px;
  color: #111a18;
  opacity: 0.8;
  border-radius: 8px;
}

.dropdown-item:hover {
  background-color: #111a1810;
}

@media (max-width: 768px) {
  .navbar.mobile-navbar {
    display: flex;
    padding: 0;
  }
  .navbar.desktop-navbar {
    display: none;
  }

  .dropdown-item {
    justify-content: flex-start;
    gap: 8px;
    margin-right: 20px;
  }
}
</style>
