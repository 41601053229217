<script setup>
import { sendEvent } from "@/helpers/cerebro";
import { initiatePaddleCheckout } from "@/helpers/paddle";
import { getPage } from "@/helpers/page";
import { useUserStore } from "@/store/user";
import { computed, watch } from "vue";
import BoxPaywall from "./BoxPaywall.vue";
import ComparePaywall from "./ComparePaywall.vue";
import CustomSpinner from "./CustomSpinner.vue";
import ListPaywall from "./ListPaywall.vue";

const props = defineProps(["isPopup", "isCard"]);

const userStore = useUserStore();

const paywallSource = computed(
  () =>
    (props.isPopup
      ? userStore.getCheckForPaywallSource()
      : getPage().toLowerCase()) ?? "default"
);

const paywallStructure = computed(() => {
  return (
    userStore.getParameter("paywall_structure")?.[paywallSource.value] ??
    userStore.getParameter("paywall_structure")?.["default"]
  );
});

const toggleScroll = (isEnabled) => {
  if (isEnabled) {
    document.body.style.overflowY = "";
  } else {
    document.body.style.overflowY = "hidden";
  }
};

const checkFromOnboarding = () => {
  const onboardingId = localStorage.getItem("ocid");
  if (onboardingId) {
    localStorage.removeItem("ocid");
    sendEvent({
      eventName: "onboarding_signup_complete",
      properties: { onboardingId },
    });
  }
};

const showPaywall = computed(() => {
  const isPro = userStore.isUserPro();
  if (!props.isPopup) {
    if (isPro) {
      return false;
    }
    return true;
  }
  if (
    userStore.getCheckForPaywallStatus() &&
    userStore.user &&
    userStore.user.emailVerified &&
    userStore.getCurrentUser() != null
  ) {
    if (!isPro) {
      toggleScroll(false);
      checkFromOnboarding();
      return true;
    }
  }
  toggleScroll(true);
  return false;
});

watch(showPaywall, (newValue) => {
  if (newValue && !paywallStructure.value) {
    setTimeout(() => {
      if (!paywallStructure.value) {
        paywallStructure.value = {
          paywall_type: "list",
        };
      }
    }, 5000);
  }
});

const checkoutFunc = async (product, advantages) => {
  initiatePaddleCheckout(product, advantages, paywallSource.value);
  closeFunc();
};

const closeFunc = () => {
  userStore.setCheckForPaywall({ val: false });
};

const skipFunc = () => {
  sendEvent({
    eventName: "skip_paywall",
    properties: { source: paywallSource.value },
  });
  closeFunc();
};
</script>

<template>
  <div
    :class="`${
      props.isPopup ? 'paywall-container-popup' : 'paywall-container-component'
    }`"
    v-if="showPaywall"
  >
    <Transition name="fade" mode="out-in">
      <div v-if="!paywallStructure" class="spinner">
        <CustomSpinner />
      </div>
      <ListPaywall
        v-else-if="paywallStructure?.paywall_type === 'list'"
        :paywallSource="paywallSource"
        :checkoutFunc="checkoutFunc"
        :closeFunc="closeFunc"
      ></ListPaywall>
      <ComparePaywall
        v-else-if="paywallStructure?.paywall_type === 'compare'"
        :isPopup="props.isPopup"
        :isCard="props.isCard"
        :paywallSource="paywallSource"
        :paywallStructure="paywallStructure"
        :closeFunc="closeFunc"
        :skipFunc="skipFunc"
      ></ComparePaywall>
      <BoxPaywall
        v-else-if="paywallStructure?.paywall_type === 'box'"
        :isPopup="props.isPopup"
        :paywallSource="paywallSource"
        :paywallStructure="paywallStructure"
      ></BoxPaywall>
      <ListPaywall
        v-else
        :paywallSource="paywallSource"
        :checkoutFunc="checkoutFunc"
        :closeFunc="closeFunc"
      ></ListPaywall>
    </Transition>
  </div>
</template>

<style scoped>
.paywall-container-popup {
  position: fixed;
  top: 0;
  background: #fff;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.paywall-container-component {
  width: 100%;
  height: 100%;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
