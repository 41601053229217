<script setup>
import FeaturedImageBox from "@/components/FeaturedImageBox.vue";
import HrWithText from "@/components/HrWithText.vue";
import { sendEvent } from "@/helpers/cerebro";
import { scrollToTop } from "@/helpers/common";
import requestWithIdToken from "@/helpers/requestWithIdToken";
import router from "@/router";
import { onBeforeUnmount, onMounted, ref } from "vue";
import { Waterfall } from "vue-waterfall-plugin-next";
import "vue-waterfall-plugin-next/dist/style.css";

const images = ref([]);
const isFeedEnded = ref(false);
const latestImageId = ref();
const status = ref("idle");
const feedType = ref("top");
const waterfall = ref(null);

const scrollBottomListener = () => {
  if (
    window.innerHeight + Math.ceil(window.scrollY) >=
    document.body.offsetHeight
  ) {
    getFeedImages();
  }
  if (isFeedEnded.value) {
    window.removeEventListener("scroll", scrollBottomListener);
  }
};

const getFeedImages = async () => {
  if (status.value == "idle") {
    status.value = "fetchingImages";
    const response = await requestWithIdToken({
      method: "GET",
      url:
        process.env.VUE_APP_PUBLISHED_IMAGE_MANAGER_BASE_URL + "/feed/images",
      params: { sa: latestImageId.value, top: feedType.value == "top" },
    }).catch((err) => "Error on getting feed data.");

    if (response.data && response.data.success) {
      const fetchedImages = response.data.payload;
      if (fetchedImages.length > 0) {
        images.value.push(...fetchedImages);
        latestImageId.value = fetchedImages.getLastItem().id;
      }
    } else {
      isFeedEnded.value = true;
    }
    status.value = "idle";
  }
};

const changeFeedType = (type) => {
  if (status.value == "idle") {
    window.addEventListener("scroll", scrollBottomListener);
    if (type == "top") sendEvent({ eventName: "click_top_artworks" });
    else if (type == "recent")
      sendEvent({ eventName: "click_recent_artworks" });

    feedType.value = type;
    images.value = [];
    isFeedEnded.value = false;
    latestImageId.value = undefined;
    getFeedImages();
  }
};

const usePrompt = (prompt, style) => {
  router.push({
    name: "Home",
    state: { prompt, style, source: `${feedType.value}Artwork` },
  });
};

onMounted(() => {
  getFeedImages();
  scrollToTop();
  window.addEventListener("scroll", scrollBottomListener);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", scrollBottomListener);
});

const rerenderWaterfall = () => {
  waterfall.value.renderer();
};
</script>

<template>
  <div id="content">
    <h1>Community <span class="gradient-text">Artworks</span></h1>
    <div class="tabs">
      <button
        :class="`btn ${feedType == 'top' ? 'active' : ''}`"
        @click="changeFeedType('top')"
      >
        Top <span class="hidden-sm-down">Artworks</span>
      </button>
      <button
        :class="`btn ${feedType == 'recent' ? 'active' : ''}`"
        @click="changeFeedType('recent')"
      >
        Recent <span class="hidden-sm-down">Artworks</span>
      </button>
    </div>
    <Waterfall
      ref="waterfall"
      :list="images"
      :imgSelector="'publishedImageUrl'"
      :breakpoints="{
        3840: {
          rowPerView: 8,
        },
        2560: {
          rowPerView: 6,
        },
        1920: {
          rowPerView: 4,
        },
        1024: {
          rowPerView: 2,
        },
        300: {
          rowPerView: 1,
        },
      }"
      :gutter="0"
      :lazyload="false"
    >
      <template #item="{ item, url, index }">
        <FeaturedImageBox
          :key="'feed-img-' + index"
          :imgLink="url"
          :style="item.style || item.bkey2"
          :prompt="item.prompt || item.bkey1"
          :downloadBtnEnabled="false"
          :clickHandler="
            () => {
              usePrompt(item.prompt || item.bkey1, item.style || item.bkey2);
            }
          "
          class="card wf-element"
          :imgLoad="rerenderWaterfall"
        ></FeaturedImageBox>
      </template>
    </Waterfall>
    <HrWithText v-if="isFeedEnded" text="End of feed"></HrWithText>
  </div>
</template>

<style scoped>
#content {
  padding: 5px 24px;
  margin: auto;
  margin-top: 30px;
  color: #000;
}

#content > img {
  width: 100%;
  margin-top: 10px;
}

.wf-element {
  margin: 8px;
  border-radius: 16px;
}

.btn {
  align-items: center;
  padding: 8px 24px;
  height: 42px;
  background: #f5f5f5;
  color: #000;
  border-radius: 12px;
  margin: 24px 8px;
}

.btn.active {
  background: #111a18;
  color: #fff;
}

h1 {
  font-size: 48px;
  line-height: 72px;
  margin-left: 5px;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .tabs {
    display: flex;
    justify-content: space-between;
  }

  .wf-element {
    padding: 5px;
  }

  #content {
    padding: 0px 15px;
  }

  h1 {
    font-size: 24px;
    line-height: 36px;
  }

  .btn {
    width: 50%;
    margin: 0px 5px;
  }
}
</style>
