<script setup>
import { reactive } from "vue";

import CarouselSlider from "@/components/CarouselSlider.vue";
import LandingCard from "@/components/LandingCard.vue";
import LinkTag from "@/components/LinkTag.vue";
import example2Items from "@/constants/homeExamples2";
import {
  promptPart1Options,
  promptPart2Options,
  promptPart3Options,
} from "@/constants/homePromptCombinationExamples";
import { sendEvent } from "@/helpers/cerebro";
import { computed } from "@vue/reactivity";
import FlatButton from "../components/FlatButton.vue";

const activeOptions = reactive([0, 1, 0]);

const examplesActiveImg = computed(() => {
  return `https://storage.googleapis.com/wonder-eed94.appspot.com/website_images/landing_examples_v2/p${
    activeOptions[0] + 1
  }a${activeOptions[1] + 1}s${activeOptions[2] + 1}.jpg`;
});

const activePrompt = computed(() => {
  return `${promptPart1Options[activeOptions[0]]}, ${
    promptPart2Options[activeOptions[1]]
  }, ${promptPart3Options[activeOptions[2]]}`;
});

const optionClick = (option, index) => {
  activeOptions[option] = index;

  sendEvent({
    eventName: "click_text_description",
    properties: { prompt: activePrompt.value },
  });
};
</script>

<template>
  <div id="landing-main">
    <img
      src="@/assets/images/landing_main.png"
      alt="Wonder landing image"
      id="main-img"
    />
    <div class="mobile-img-container">
      <img
        src="https://storage.googleapis.com/wonder-eed94.appspot.com/website_images/signin_image.png"
        alt="Wonder Artworks"
        id="main-img-mobile"
      />
      <div class="hidden-md-up mobile-title">
        <img
          src="@/assets/images/Wonder.svg"
          class="wonder-img"
          alt="Wonder logo"
        />
      </div>
    </div>
    <div class="landing-main-titles">
      <div class="landing-main-text landing-main-titles-paddings">
        <h1>Welcome to Wonder.</h1>
        <p class="subText">
          Wonder is one of the most capable AI Art generators, allowing you to
          turn any image into reality with just your words.
        </p>
      </div>
      <div class="landing-main-action landing-main-titles-paddings">
        <router-link
          :to="{ name: 'Signup' }"
          @click="() => sendEvent({ eventName: 'click_try_wonder_web' })"
          class="dark-btn"
        >
          <div>
            <p class="margin-0">Try Wonder Web</p>
            <p class="margin-0 subText">Online AI Art Generator</p>
          </div>
          <img src="@/assets/icons/right_icon_white.svg" alt="Next" />
        </router-link>
        <div class="store-links">
          <link-tag
            href="https://app.adjust.com/1bhxqf9x?engagement_type=fallback_click&fallback=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fwonder-ai-art-generator%2Fid1621278575&redirect_macos=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fwonder-ai-art-generator%2Fid1621278575"
            eventName="click_app_store"
            target="_blank"
          >
            <img src="@/assets/images/app_store_btn.png" alt="Go App Store" />
          </link-tag>
          <link-tag
            href="https://app.adjust.com/1b54fbjs?engagement_type=fallback_click&fallback=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.codeway.wonder%26hl%3Den_US&redirect_macos=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.codeway.wonder%26hl%3Den_US"
            eventName="click_google_play"
            target="_blank"
          >
            <img
              src="@/assets/images/google_play_btn.png"
              alt="Go Google Play Store"
            />
          </link-tag>
        </div>
      </div>
    </div>
  </div>
  <div id="examples">
    <div class="subText">
      <span class="bold">Wonder</span> creates original images in tens of
      different art styles based on your text description. Try and see for
      yourself!
    </div>
    <div id="showcase">
      <div class="showcase-part">
        <p class="margin-0">Text Description</p>
        <div class="prompt-part">
          <flat-button
            v-for="(option, index) in promptPart1Options"
            :key="'prompt-part1-' + index"
            :text="option"
            :active="activeOptions[0] == index"
            :onClick="() => optionClick(0, index)"
          />
        </div>
        <div class="prompt-part">
          <flat-button
            v-for="(option, index) in promptPart2Options"
            :key="'prompt-part2-' + index"
            :text="option"
            :active="activeOptions[1] == index"
            :onClick="() => optionClick(1, index)"
          />
        </div>
        <div class="prompt-part">
          <flat-button
            v-for="(option, index) in promptPart3Options"
            :key="'prompt-part3-' + index"
            :text="option"
            :active="activeOptions[2] == index"
            :onClick="() => optionClick(2, index)"
          />
        </div>
      </div>
      <div class="showcase-part">
        <img :src="examplesActiveImg" :alt="activePrompt" />
      </div>
    </div>
  </div>
  <div id="examples-2">
    <carousel-slider :arrows="false">
      <landing-card
        v-for="(example, index) in example2Items"
        :key="`example-2-item-${index}`"
        :imgLink="example.imgLink"
        :title="example.title"
        :text="example.text"
        class="examples-2-card"
      ></landing-card>
    </carousel-slider>
  </div>
  <div id="blog">
    <h1>Isn’t it Wonderful?</h1>
    <div class="blog-item">
      <h2>
        Pour your creativity into words, Wonder will pour them into reality.
      </h2>
      <p class="subText">
        Wonder is not just any other AI Art Generator, it is a companion who
        helps you to explore the wonders of your imagination.
      </p>
      <router-link
        :to="{ name: 'Signup' }"
        @click="() => sendEvent({ eventName: 'click_try_now' })"
      >
        <button class="btn">Try now!</button>
      </router-link>
      <img src="@/assets/images/landing_blog_main.png" alt="" />
    </div>
  </div>
</template>

<style scoped>
.mobile-img-container {
  position: relative;
}

h1 {
  font-size: 56px;
  line-height: 84px;
  font-weight: 600;
  margin: 54px 0 24px 0;
  letter-spacing: -0.02em;
}

h2 {
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -0.02em;
}

.bold {
  color: #000;
  font-weight: 600;
}

.landing-main-titles {
  display: flex;
  justify-content: space-between;
}

.landing-main-titles-paddings {
  padding: 0 calc(20% - 220px);
}

@media (max-width: 1024px) {
  .landing-main-titles {
    flex-direction: column;
  }
  .landing-main-action {
    margin-top: 25px;
  }

  .mobile-title {
    position: absolute;
    height: 160px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 52.9%
    );
    width: 100%;
    bottom: 5px;
  }

  .mobile-title > .wonder-img {
    width: 100%;
    padding: 0 20px;
    position: absolute;
    bottom: 0;
    display: block;
  }
}

.landing-main-action {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.dark-btn {
  display: flex;
  justify-content: space-between;
  padding: 12px 36px;
  background: #000000;
  border-radius: 24px;
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  color: #ffffff;
}

.dark-btn p {
  padding: 0 14px;
}

.dark-btn .subText {
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #ffffff80;
}

.landing-main-text .subText {
  text-align: left;
  max-width: 560px;
}

#landing-main {
  margin-bottom: 80px;
}

.store-links {
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.store-links img {
  width: 180px;
}

#showcase-title {
  font-size: 32px;
  padding: 20px;
}

.subText {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.5);
}

.subText b {
  color: #000;
}

.prompt-part {
  margin-top: 40px;
  font-size: 32px;
  line-height: 48px;
}

#main-img {
  width: 100%;
}

#main-img-mobile {
  display: none;
}

#examples {
  background: #f2f2f2;
  padding: 96px 32px;
}

#examples .subText {
  max-width: 600px;
  margin: auto;
  margin-bottom: 80px;
}

#examples-2 {
  padding: 150px 24px;
  text-align: left;
}

.examples-2-card {
  width: 450px;
}

#showcase {
  display: flex;
  background: #fff;
  border-radius: 24px;
  padding: 100px 120px;
}

.showcase-part {
  width: 50%;
  flex: 1;
  padding: 30px;
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}

.showcase-part p {
  font-weight: 600;
}

.showcase-part img {
  border-radius: 24px;
  width: 100%;
  display: block;
}

#showcase-2 {
  background: #fff;
  padding: 168px 32px;
}

#blog {
  padding: 120px 150px;
  background: #f2f2f2;
}

.blog-item {
  background: #fff;
  padding: 10px 20% 0px 20%;
  border-radius: 24px;
  overflow-y: hidden;
}

#blog .btn {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  width: 106px;
  height: 42px;
  background: #000000;
  color: #fff;
  border-radius: 12px;
  margin-bottom: 48px;
}

#blog img {
  width: 120%;
  margin-left: -10%;
  margin-bottom: -10px;
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  #showcase {
    padding: 50px 60px;
  }
  .prompt-part {
    font-size: 28px;
  }
}

@media only screen and (max-width: 1024px) {
  .mobile-img-container {
    margin-top: -20px;
  }

  .store-links {
    padding: 0px 15px;
    max-width: 400px;
  }

  .store-links img {
    width: 50%;
    margin: 0;
    padding: 0px 10px;
  }

  .landing-main-text .subText {
    text-align: center;
    padding: 0 25px;
    font-size: 12px;
    line-height: 18px;
  }

  .landing-main-titles-paddings {
    padding: 0;
  }
  .dark-btn {
    padding: 12px 20px;
  }

  .dark-btn p {
    font-size: 18px;
    line-height: 30px;
  }

  .dark-btn .subText {
    font-weight: 600;
    font-size: 11px;
    line-height: 19px;
    color: #ffffff80;
  }

  .dark-btn img {
    display: none;
  }

  #showcase {
    padding: 30px;
    flex-direction: column;
  }

  .showcase-part {
    width: auto;
    padding: 0;
    font-size: 18px;
    line-height: 27px;
  }

  .showcase-part p {
    font-weight: 275;
    font-size: 16px;
    line-height: 24px;
  }

  .prompt-part {
    font-size: 18px;
    line-height: 27px;
    margin: 20px 0;
  }

  #examples-2 {
    padding: 32px 20px;
  }

  .examples-2-card {
    width: 250px;
  }

  #blog {
    padding: 30px 35px;
    background: #f2f2f2;
  }

  h1 {
    margin: 27px 0 12px 0;
  }

  h1,
  h2,
  .subText {
    font-size: 20px;
    line-height: 30px;
  }

  #main-img {
    display: none;
  }

  #main-img-mobile {
    width: 100%;
    padding: 0px 50px;
    display: block;
  }

  .subText {
    font-size: 16px;
    line-height: 24px;
  }

  .store-links {
    margin-top: 24px;
  }

  .landing-main-action {
    margin-top: 16px;
  }

  .dark-btn {
    order: 2;
  }

  .store-links {
    margin-top: 0px;
    margin-bottom: 16px;
    padding: 0 40px;
    order: 1;
    justify-content: center;
  }

  .store-links img {
    width: 100%;
    object-fit: contain;
  }
}
</style>
