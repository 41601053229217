<script setup>
import { computed } from "@vue/reactivity";

const props = defineProps([
  "creditCount",
  "promptCount",
  "priceWithoutDiscount",
  "priceObj",
  "isPromoted",
  "isSelected",
  "product",
  "onClick",
]);

const price = computed(() => {
  return props.priceObj ? (props.priceObj.unit_amount / 100).toFixed(2) : "";
});
</script>

<template>
  <div :class="`credit-box ${props.isSelected ? 'selected' : ''}`">
    <span class="badge" v-if="props.isPromoted">Most popular</span>
    <p class="title">{{ props.creditCount }} Credits</p>
    <p class="mute-text subTitle">{{ props.promptCount }} artwork results</p>
    <div>
      <span
        :class="`pricing ${props.priceWithoutDiscount ? 'line-through' : ''}`"
      >
        ${{ props.priceWithoutDiscount || price }}
      </span>
      <span class="mute-text">
        ${{ (Number(price) / props.creditCount).toFixed(2) }}/credit
      </span>
    </div>
    <button class="btn" @click="props.onClick">
      Purchase
      {{ props.priceWithoutDiscount ? `for $${price}` : "now!" }}
    </button>
  </div>
</template>

<style scoped>
p {
  margin: 0;
}
.title {
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  /* identical to box height */

  text-align: center;
}

.subTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  text-align: center;
  margin-bottom: 35px;
}
.credit-box {
  padding: 54px 40px;
  background: #ffffff;
  border: 3px solid rgba(17, 26, 24, 0.1);
  border-radius: 18px;
  display: inline-block;
  position: relative;
}

.credit-box.selected {
  border: 3px solid #000;
}

.pricing {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  /* identical to box height */

  color: #111a18;
}

.badge {
  position: absolute;
  top: -15px;
  transform: translate(-50%);
}

button {
  padding: 14px 28px;
  gap: 10px;
  color: #ffffff;

  background: #111a18;
  border-radius: 12px;
  margin-top: 22px;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .credit-box {
    padding: 20px;
  }
  .title {
    font-size: 24px;
    line-height: 36px;
  }
  .subTitle {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 5px;
  }
  .pricing {
    font-size: 24px;
    line-height: 36px;
  }
  button {
    padding: 10px 20px;
    font-size: 14px;
    line-height: 21px;
    margin-top: 5px;
  }
}
</style>
