/* eslint-disable no-undef */
import { isStaging } from "@/helpers/environment";
import { calculateRevenue, getPriceTextFromProduct } from "@/helpers/product";
import router from "../router";
import { useUserStore } from "../store/user";
import { getCerebroId, sendEvent } from "./cerebro";
import { sendGTagEvent } from "./gtag";
import { getPage } from "./page";
import requestWithIdToken from "./requestWithIdToken";

const paymentHandler = process.env.VUE_APP_PAYMENT_HANDLER_BASE_URL;

if (isStaging()) {
  Paddle.Environment.set("sandbox");
}

const initializeOptions = {
  token: process.env.VUE_APP_PADDLE_CLIENT_TOKEN,
  checkout: {
    settings: {
      displayMode: "inline",
      theme: "light",
      locale: "en",
      frameTarget: "checkout-container",
      frameInitialHeight: "450",
      frameStyle:
        "width: 100%; min-width: 120px; background-color: transparent; border: none;",
    },
  },
  eventCallback: async function (event) {
    if (isStaging()) {
      console.log(event); // useful for debugging
    }
    const userStore = useUserStore();

    if (
      event.name == "checkout.loaded" ||
      event.name == "checkout.items.updated"
    ) {
      // send purchase started event
      sendGTagEvent({
        event: "checkout",
        purchase_value: event.data.totals.total,
        currency_code: "USD",
        item_checkedout: event.data.items[0].price_name,
        item_id_number: event.data.items[0].price_id,
        category: event.data.items[0].billingCycle
          ? "Subscription"
          : "One Time",
        item_value: event.data.totals.total,
        quantity_bought: 1,
        user_id: userStore.user?.uid,
        // 'coupon': 'Wonder10',
        // 'discount_value': 2.00
      });
      sendEvent({
        eventName: "purchase_started",
        properties: { productId: event.data.items[0].product.id },
      });
    } else if (event.name == "checkout.payment.initiated") {
      userStore.editUserData({ isPaymentInitiated: true });
      sendEvent({
        eventName: "subscribe_click",
        properties: {
          productIdentifier: event.data.items[0].product.id,
          price: event.data.totals.total,
          revenue: calculateRevenue(event.data.totals.total, "paddle"),
          paymentProvider: "paddle",
        },
      });
    } else if (event.name == "checkout.payment.failed") {
      userStore.editUserData({ isPaymentInitiated: false });
      // send purchase failed event
      sendEvent({
        eventName: "payment_failed",
        properties: { productId: event.data.items[0].product.id },
      });
    } else if (event.name == "checkout.completed") {
      userStore.editUserData({ isPaymentInitiated: false });
      const isConsumable = !event.data.items[0].billing_cycle;
      // validate paddle payment
      router.replace({
        name: "PaymentResult",
        query: {
          success: true,
          cs: "paddle",
          s: event.data.transaction_id,
          c: isConsumable,
          pid: event.data.items[0].price_id,
          pv:
            (isConsumable
              ? event.data.totals.total
              : event.data.recurring_totals.total) * 100,
          cur: event.data.currency_code,
          pm: event.data.payment.method_details.type,
          src: router.options.history.state.source,
          ft: event.data.items[0].trial_period !== null,
        },
      });
      if (isConsumable) {
        // validate method will be called by the payment result page
      } else {
        setTimeout(() => {
          if (!userStore.isUserPro()) {
            sendEvent({
              eventName: "checking_transaction_manually",
              event_group: "error",
            });
            validatePaddlePurchase({ trxId: event.data.transaction_id });
          }
        }, 10000);
      }
      if (isStaging()) {
        console.log("Checkout completed", event.data);
      }
    } else if (
      event.name == "checkout.customer.created" ||
      event.name == "checkout.customer.updated"
    ) {
      const updateData = {
        customerId: event.data.customer.id,
        paddleCustomerEmail: event.data.customer.email,
        userId: userStore.user?.uid,
        cerebroId: getCerebroId(),
      };
      const response = await requestWithIdToken({
        method: "POST",
        url: `${paymentHandler}/paddle/customer-update`,
        data: updateData,
      });
      if (response.data.success != true) {
        sendEvent({
          eventName: "paddle_customer_update_failed",
          event_group: "error",
          properties: updateData,
        });
      } else {
        userStore.editUserData({
          paddleCustomerId: updateData.customerId,
          paddleCustomerEmail: updateData.paddleCustomerEmail,
        });
        initializePaddle();
      }
    }
  },
};

export const initializePaddle = (pci) => {
  const userStore = useUserStore();

  const paddleCustomerId = pci || userStore.userData?.paddleCustomerId;
  if (Paddle.Initialized) {
    if (paddleCustomerId) {
      Paddle.Update({
        pwCustomer: {
          id: paddleCustomerId,
        },
      });

      if (isStaging()) {
        console.log("updated paddle with customer id: ", paddleCustomerId);
      }
    }
  } else {
    if (paddleCustomerId) {
      Paddle.Initialize({
        ...initializeOptions,
        pwCustomer: {
          id: paddleCustomerId,
        },
      });

      if (isStaging()) {
        console.log("initialized paddle with customer id: ", paddleCustomerId);
      }
    } else {
      Paddle.Initialize(initializeOptions);

      if (isStaging()) {
        console.log("initialized paddle with default options");
      }
    }
  }
};

export const validatePaddlePurchase = async (params, count = 0) => {
  const trxId = params.trxId;

  if (isStaging()) {
    console.log("Validating paddle purchase", trxId + count);
  }

  const response = await requestWithIdToken({
    path: "/paddle/verify-purchase",
    method: "POST",
    url: `${paymentHandler}/paddle/verify-purchase`,
    data: {
      trxId,
    },
  });
  if (response.data.success == false) {
    if (response.data.error == "already-processed") {
      params.callback(true, { processed: true });
    } else if (count < 6) {
      setTimeout(() => {
        validatePaddlePurchase(params, count + 1);
      }, 5000);
    } else {
      params.callback(false, response.data.payload);
    }
  } else {
    if (response.data.payload?.action == "credit") {
      params.callback(true, response.data.payload);
    } else {
      router.replace({ name: "Home" });
    }
  }
};

export const fetchPaddleProducts = async (products) => {
  const pids = products.map((product) => product.priceId);
  const pidMap = {};
  products.forEach((product) => {
    pidMap[product.priceId] = product;
  });
  try {
    const prices = pids.map((pid) => ({
      quantity: 1,
      priceId: pid,
    }));
    const paddleProducts = await Paddle.PricePreview({
      items: prices,
    });
    return formatPricesLikeStripe(paddleProducts.data.details.lineItems).map(
      (item) => {
        return {
          ...pidMap[item.price.id],
          ...item,
        };
      }
    );
  } catch (error) {
    console.log(error);
    return [];
  }
};

const formatPricesLikeStripe = (prices) => {
  return prices.map((priceItem) => {
    const isRecurring = !!priceItem.price.billingCycle;
    return {
      price: {
        id: priceItem.price.id,
        object: "price",
        currency: priceItem.price.unitPrice.currencyCode,
        nickname: priceItem.price.name,
        product: priceItem.product.id,
        recurring: isRecurring
          ? {
              interval: priceItem.price.billingCycle.interval,
              interval_count: priceItem.price.billingCycle.frequency,
              trial_period_days: priceItem.price.trialPeriod
                ? priceItem.price.trialPeriod.frequency
                : null,
              usage_type: "licensed",
            }
          : null,
        type: isRecurring ? "recurring" : "one_time",
        unit_amount: Number(priceItem.unitTotals.total),
        unit_amount_decimal: priceItem.unitTotals.total,
        offers_free_trial: priceItem.price.trialPeriod !== null,
      },
    };
  });
};

export const initiatePaddleCheckout = (product, advantages, source) => {
  router.push({
    name: "Checkout",
    query: {
      pid: product.price.id,
    },
    params: {
      clientSecret: "paddle",
      subscription: product.price.id,
    },
    state: {
      source: source || getPage(),
      advantages,
      priceText: getPriceTextFromProduct(product),
      interval: product.price.recurring && product.price.recurring.interval,
      intervalCount:
        product.price.recurring && product.price.recurring.interval_count,
      offersFreeTrial: product.price.offers_free_trial,
      ftDays:
        product.price.recurring && product.price.recurring.trial_period_days,
    },
  });
};

export const cancelSubscription = async (subscriptionId) => {
  const userStore = useUserStore();

  try {
    await requestWithIdToken({
      url: `${paymentHandler}/paddle/cancel-subscription`,
      method: "POST",
      data: {
        subscriptionId,
      },
    });
    const userData = userStore.userData;
    userData.subscriptions[subscriptionId].isAutoRenewOn = false;
    userStore.editUserData(userData);
  } catch (error) {
    console.log(error);
    sendEvent({
      eventName: "cancel_subs_error",
      eventGroup: "subscription",
      properties: {},
    });
    alert("Error occured on cancelling subscription");
  }
};

export const stopCancellation = async (subscriptionId) => {
  const userStore = useUserStore();

  try {
    await requestWithIdToken({
      url: `${paymentHandler}/paddle/stop-cancellation`,
      method: "POST",
      data: {
        subscriptionId,
      },
    });
    const userData = userStore.userData;
    userData.subscriptions[subscriptionId].isAutoRenewOn = true;
    userStore.editUserData(userData);
  } catch (error) {
    console.log(error);
    sendEvent({
      eventName: "stop_cancellation_error",
      eventGroup: "subscription",
      properties: {},
    });
    alert("Error occured on cancelling subscription");
  }
};
