<script setup>
import CircularProgress from "@/components/CircularProgress.vue";
import GalleryImageList from "@/components/GalleryImageList.vue";
import { sendEvent } from "@/helpers/cerebro";
import router from "@/router";
import { onBeforeMount, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

const requestPathArtwork = "/api/v1/user/get-old-gallery/artwork";
const requestPathAvatar = "/api/v1/user/get-old-gallery/avatar";

const route = useRoute();

const cerebroId = ref("");

const tab = ref("artwork");
const artworks = ref();
const avatars = ref();
const selectedAvatarPack = ref(0);

const loadingModal = ref(false);
const loadingPercent = ref(0);

let tabContentDiv;

const tabClick = (tabName) => {
  tab.value = tabName;
  selectedAvatarPack.value = 0;
  if (tabContentDiv) tabContentDiv.scroll({ top: 0, behavior: "smooth" });
};

const avatarPackClick = (index) => {
  selectedAvatarPack.value = index;
  if (tabContentDiv) tabContentDiv.scroll({ top: 0, behavior: "smooth" });
};

const setLoadingModal = (show) => {
  loadingModal.value = show;
};

const setLoadingPercent = (percent) => {
  loadingPercent.value = percent;
};

const sendGalleryEvents = async ({ eventName, feature, packIndex, artURL }) => {
  const properties = { feature };
  if (packIndex) properties.packIndex = packIndex - 1;
  if (artURL) properties.artURL = artURL;
  await sendEvent({ eventName, properties, cerebroId: cerebroId.value });
};

onBeforeMount(() => {
  /* if (!route.query.rs || !route.query.ts || !route.query.uid) {
    router.replace({ name: "Landing" });
  } */
  document.querySelector("html").classList.add("gallery");
});

onMounted(async () => {
  const { rs, ts, uid } = route.query;
  cerebroId.value = uid;

  tabContentDiv = document.querySelector(".tab-content");

  await fetch(process.env.VUE_APP_NEW_BACKEND_BASE_URL + requestPathArtwork, {
    method: "POST",
    headers: { "Content-Type": "application/json", rs: rs, ts: ts },
    body: JSON.stringify({ userId: uid }),
  })
    .then((response) => {
      if (response.ok) return response.json();
      throw new Error("Error fetching data");
    })
    .then((json) => {
      artworks.value = json.gallery;
    })
    .catch((error) => {
      console.log(error);
      router.replace({ name: "Landing" });
    });

  await fetch(process.env.VUE_APP_NEW_BACKEND_BASE_URL + requestPathAvatar, {
    method: "POST",
    headers: { "Content-Type": "application/json", rs: rs, ts: ts },
    body: JSON.stringify({ userId: uid }),
  })
    .then((response) => {
      if (response.ok) return response.json();
      throw new Error("Error fetching data");
    })
    .then((json) => {
      avatars.value = json.avatars;
    })
    .catch((error) => {
      console.log(error);
      router.replace({ name: "Landing" });
    });
});
</script>
<template>
  <div id="wrapper">
    <div class="header">
      <RouterLink :to="{ name: 'Landing' }">
        <img src="@/assets/images/wonder_logo.png" alt="Wonder logo" />
      </RouterLink>
    </div>
    <div class="body">
      <div class="tabs-container">
        <div
          :class="`tab ${tab == 'artwork' ? 'selected' : ''}`"
          @click="() => tabClick('artwork')"
        >
          <span>My Artworks</span>
        </div>
        <div
          :class="`tab ${tab == 'avatar' ? 'selected' : ''}`"
          @click="() => tabClick('avatar')"
        >
          <span>My Avatars</span>
        </div>
        <div class="slider"></div>
      </div>
      <div class="tab-content">
        <div class="reminder">
          <div class="bulb">💡</div>
          <span>
            Remember to download your images within 30 days to avoid losing your
            results.
          </span>
        </div>
        <Transition
          :name="`${tab == 'artwork' ? 'slide-left' : 'slide-right'}`"
          mode="out-in"
        >
          <GalleryImageList
            v-if="tab == 'artwork'"
            type="artwork"
            :imgList="artworks"
            :setLoadingModal="setLoadingModal"
            :setLoadingPercent="setLoadingPercent"
            :sendGalleryEvent="
              ({ eventName, artURL }) =>
                sendGalleryEvents({ eventName, feature: 'text2img', artURL })
            "
          >
          </GalleryImageList>
          <div v-else class="avatars-container">
            <Transition name="fade" mode="out-in">
              <div v-if="avatars != undefined && !avatars.length" class="empty">
                <img src="@/assets/icons/empty_icon.svg" />
                There are no avatar packs available.
              </div>
              <div
                v-else-if="!selectedAvatarPack"
                class="avatar-pack-container"
              >
                <div
                  v-for="(avatarPack, index) in avatars"
                  :key="`avatar-pack-${index}`"
                  class="avatar-pack"
                  @click="() => avatarPackClick(index + 1)"
                >
                  <div class="img-outer-shadow">
                    <div class="img-middle-shadow">
                      <img :src="avatarPack[0]" alt="Avatar pack" />
                    </div>
                  </div>
                  <div class="labels">
                    <h1>Pack #{{ index + 1 }}</h1>
                    <h2>{{ avatarPack.length }} Avatars</h2>
                  </div>
                </div>
              </div>
              <GalleryImageList
                v-else
                type="avatar"
                :packNumber="selectedAvatarPack"
                :imgList="avatars[selectedAvatarPack - 1]"
                :setLoadingModal="setLoadingModal"
                :setLoadingPercent="setLoadingPercent"
                :sendGalleryEvent="
                  ({ eventName, artURL }) =>
                    sendGalleryEvents({
                      eventName,
                      feature: 'avatar',
                      packIndex: selectedAvatarPack,
                      artURL,
                    })
                "
              >
              </GalleryImageList>
            </Transition>
          </div>
        </Transition>
      </div>
    </div>
  </div>
  <Transition name="fade">
    <div v-if="loadingModal" class="loading-modal">
      <div class="loading-container">
        <CircularProgress
          :value="loadingPercent"
          width="70"
          height="70"
          strokeWidth="5"
          strokeColor="#1C1D21"
          strokeBgColor="#EAECF0"
          textSize="16"
          textWeight="700"
          textColor="#1C1D21"
        />
        <span>
          {{
            loadingPercent != 100
              ? "Downloading photos..."
              : "Downloaded successfully!"
          }}
        </span>
      </div>
    </div>
  </Transition>
</template>
<style>
html.gallery {
  background-color: #fcfcfd;
}
</style>
<style scoped>
#wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.header img {
  width: 102px;
}

.loading-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff80;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 30%;
  background-color: white;
  border-radius: 12px;
  font-size: 24px;
  font-weight: 600;
  gap: 16px;
}

.loading-container span {
  color: #1c1d21;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
}

.body {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  height: 100%;
}

.body .tabs-container {
  display: flex;
  width: 100%;
  position: relative;
}

.body .tabs-container .slider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 2px;
  background-color: #6941c6;
  transition: left 0.25s;
}

.body .tabs-container .tab {
  width: 50%;
  padding: 6px 12px;
  cursor: pointer;
  color: #98a2b3;
  border-bottom: 1px solid #eaecf0;
}

.body .tabs-container .tab.selected {
  color: #1c1d21;
  transition: color 0.25s;
}

.body .tabs-container .tab:first-child.selected ~ .slider {
  left: 0%;
}

.body .tabs-container .tab:nth-child(2).selected ~ .slider {
  left: 50%;
}

.body .tabs-container .tab span {
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  color: inherit;
}

.body .tab-content {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 16px 20px;
  gap: 12px;
  overflow-x: hidden;
  height: 100%;
}

:deep .empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  color: #1c1d21;
  color: #667085;
  font-size: 14px;
  font-weight: 400;
}

.body .tab-content .reminder {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: #f2f4f7;
  border-radius: 12px;
  gap: 12px;
  width: 100%;
}

.body .tab-content .reminder .bulb {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50%;
}

.body .tab-content .reminder span {
  color: #667085;
  font-size: 14px;
  font-weight: 400;
  line-height: 142.857%;
  text-align: left;
  width: calc(100% - 42px);
}

.body .tab-content .avatars-container,
.body .tab-content .avatar-pack-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.body .tab-content .avatar-pack-container {
  flex-direction: column;
  gap: 12px;
}

.body .tab-content .avatar-pack-container .avatar-pack {
  display: flex;
  align-items: center;
  border: 1px solid #eaecf0;
  border-radius: 16px;
  gap: 24px;
  padding: 8px 0 8px 12px;
  cursor: pointer;
}

.body .tab-content .avatar-pack-container .avatar-pack .img-outer-shadow,
.body .tab-content .avatar-pack-container .avatar-pack .img-middle-shadow {
  display: flex;
  align-items: center;
  height: 92px;
  border-radius: 16px;
  background-color: #1c1d211a;
}

.body .tab-content .avatar-pack-container .avatar-pack .img-outer-shadow {
  width: 112px;
}
.body .tab-content .avatar-pack-container .avatar-pack .img-middle-shadow {
  width: 102px;
}

.body .tab-content .avatar-pack-container .avatar-pack img {
  width: 92px;
  height: 92px;
  background-color: #f2f4f7;
  border-radius: 16px;
}

.body .tab-content .avatar-pack-container .avatar-pack .labels {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}

.body .tab-content .avatar-pack-container .avatar-pack .labels h1 {
  font-size: 18px;
  font-weight: 600;
  color: #344054;
  margin: 0;
}

.body .tab-content .avatar-pack-container .avatar-pack .labels h2 {
  font-size: 12px;
  font-weight: 400;
  color: #667085;
  margin: 0;
}

/* Transition */
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.25s ease;
}

.slide-left-enter-from,
.slide-right-leave-to {
  opacity: 0;
  transform: translateX(50%);
}

.slide-left-leave-to,
.slide-right-enter-from {
  opacity: 0;
  transform: translateX(-50%);
}

.fade-fast-enter-active,
.fade-fast-leave-active {
  transition: opacity 10ms linear;
}

.fade-fast-enter-from,
.fade-fast-leave-to {
  opacity: 0;
}
</style>
