<script setup>
import BlackButton from "@/components/BlackButton.vue";
import { sendEvent } from "@/helpers/cerebro";
import { sendGTagEvent } from "@/helpers/gtag";
import router from "@/router";
import { onBeforeUnmount, onMounted, ref } from "vue";

let scrollingImage;
let top = 0;
const currentStep = ref(0);

const endOnboarding = () => {
  router.replace({ name: "Signup" });
};

const onBoardingConfiguration = [
  {
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/website_images/Frame%20427320690%20(1).png",
    title: `Turn your words <br />
      into <b>artwork</b>`,
    btnText: "Get Started",
    btnArrow: false,
    subText: "legal",
    isScrolling: true,
  },
  {
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/website_images/Group%201939%20(1).png",
    title: `Turn your words <br />
      into <b>artwork</b>`,
    btnText: "Next",
    btnArrow: false,
    subText: "skip",
  },
  {
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/website_images/Frame%20427320878%20(1).png",
    title: `Turn yourself into<br />
      <b>Wonder</b>`,
    btnText: "Next",
    btnArrow: false,
    subText: "skip",
  },
  {
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/website_images/Frame%20427320879%20(1).png",
    title: `Turn yourself into <br />
      <b>Anime Character</b>`,
    btnText: "Next",
    btnArrow: false,
    subText: "skip",
  },
];

onMounted(() => {
  sendEvent({
    eventName: "web_page_open",
    page: "Onboarding",
    properties: { screen: "0" },
  });
  top = 0;
  hideAllContainers();
  showContainer(0);
  scrollingImage = setInterval(() => {
    const elem = document.getElementById("scrollingImg");
    const boundingClientRect = elem.getBoundingClientRect();
    if (boundingClientRect.height < window.outerHeight) {
      return;
    }
    if (
      boundingClientRect.height + boundingClientRect.y <=
      window.outerHeight
    ) {
      if (scrollingImage) {
        clearInterval(scrollingImage);
      }
    } else {
      top -= 1;
      elem.style.top = top + "px";
    }
  }, 15);
});

const hideAllContainers = () => {
  document
    .querySelectorAll(".container")
    .forEach((item) => (item.style.display = "none"));
};

const showContainer = (index) => {
  document.getElementById("container-" + index).style.display = "";
};

const nextStep = () => {
  sendEvent({
    eventName: "click_onboarding_button",
    page: "Onboarding",
    properties: {
      screen: currentStep.value.toString(),
    },
  });
  // events
  if (currentStep.value === 0) {
    sendGTagEvent({ event: "get_started" });
  } else if (currentStep.value < onBoardingConfiguration.length) {
    sendGTagEvent({
      event: "next",
      step: currentStep.value.toString(),
    });
  }
  // real stuff
  if (currentStep.value < onBoardingConfiguration.length - 1) {
    currentStep.value++;
    sendEvent({
      eventName: "web_page_open",
      page: "Onboarding",
      properties: {
        screen: currentStep.value.toString(),
      },
    });
    hideAllContainers();
    showContainer(currentStep.value);
    document.getElementById("scrollingImg").style.top = 0;
  } else {
    localStorage.setItem("ocid", "static");
    endOnboarding();
  }
  clearInterval(scrollingImage);
};

const skipDemo = () => {
  sendGTagEvent({ event: "skip_demo" });
  sendEvent({
    eventName: "skip_onboarding",
    page: "Onboarding",
    properties: { screen: currentStep.value.toString() },
  });
  endOnboarding();
};

onBeforeUnmount(() => {
  if (scrollingImage) {
    clearInterval(scrollingImage);
  }
});
</script>

<template>
  <div class="upper-bg edge-bg hidden-md-up"></div>
  <div
    class="container"
    v-for="(step, index) in onBoardingConfiguration"
    :key="`preload-${index}`"
    :id="`container-${index}`"
  >
    <div class="img-container">
      <img
        :src="step.imgLink"
        alt=""
        :id="step.isScrolling ? `scrollingImg` : ''"
      />
    </div>
    <div class="text-container">
      <p class="title" v-html="step.title"></p>
      <div class="action-container">
        <BlackButton :rightIcon="true" :clickHandler="nextStep">{{
          step.btnText
        }}</BlackButton>
        <p class="faded-text" v-if="step.subText == 'legal'">
          By continuing, you agree to our
          <a
            href="https://static.wonderai.app/privacy"
            target="_blank"
            class="legal-link"
            >Privacy Policy</a
          >
          and
          <a
            href="https://static.wonderai.app/terms-conditions"
            target="_blank"
            class="legal-link"
            >Terms of Use</a
          >.
        </p>
        <p
          class="faded-text skip-text"
          v-if="step.subText == 'skip'"
          @click="skipDemo"
        >
          Skip Demo >>
        </p>
      </div>
    </div>
  </div>
  <div class="lower-bg edge-bg hidden-md-up"></div>
</template>

<style scoped>
.container {
  width: 100%;
  display: flex;
}

.img-container {
  width: 60%;
  height: 100%;
  height: 100vh;
  overflow: hidden;
}

.img-container > img {
  width: 100%;
  top: -10%;
  position: relative;
}

#scrollingImg {
  width: 100%;
  position: relative;
  top: 0;
}

.text-container {
  padding: 5% 5% 0% 5%;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  font-weight: 300;
}

.action-container {
  width: 70%;
}

.skip-text {
  text-align: right !important;
  cursor: pointer;
}

.faded-text {
  font-size: 12px;
}

.edge-bg {
  display: none;
}

@media screen and (max-width: 1024px) {
  .title {
    font-size: 34px;
    line-height: 51px;
    text-align: left;
    width: 100%;
    margin: 20px;
    padding-left: 25px;
    font-weight: 200;
  }

  .faded-text {
    line-height: 15px;
    text-align: left;
  }

  .skip-text {
    font-size: 12px;
  }
  .img-container {
    position: absolute;
    width: 100%;
    z-index: -2;
    padding: 30% 0%;
  }

  .text-container {
    width: 100%;
    height: 100%;
    height: 100vh;
    padding: 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }

  .edge-bg {
    display: block;
    position: absolute;
    width: 100%;
    height: 150px;
    z-index: -1;
  }

  .upper-bg {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 7.79%,
      rgba(255, 255, 255, 0.4) 21.96%,
      rgba(255, 255, 255, 0.8) 40.32%,
      #ffffff 65.95%
    );
    top: 0;
  }

  .lower-bg {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.35) 17.89%,
      rgba(255, 255, 255, 0.55) 25.98%,
      rgba(255, 255, 255, 0.75) 34.53%,
      rgba(255, 255, 255, 0.86) 39.62%,
      rgba(255, 255, 255, 0.915266) 43.04%,
      rgba(255, 255, 255, 0.94) 46.06%
    );
    bottom: 0;
  }

  .action-container {
    width: 100%;
    padding: 0px 25px 65px 25px;
    height: 180px;
  }
}
</style>
