<script setup>
import {
  getPlanNameFromProduct,
  getPriceTextFromProduct,
} from "@/helpers/product";
import { computed } from "@vue/reactivity";
import CustomSpinner from "./CustomSpinner.vue";

const props = defineProps(["onclick", "product", "advantages"]);

const duration = computed(() => {
  if (props.product.price.type !== "recurring") {
    return ",lifetime";
  } else {
    return `/${props.product.price.recurring.interval}`;
  }
});
</script>

<template>
  <div class="text-center paywall-product-box">
    <CustomSpinner v-if="!product"></CustomSpinner>
    <div class="paywall-product-content" v-else>
      <div
        class="free-trial free-trial-color"
        v-if="props.product.price.offers_free_trial"
      >
        {{ props.product.price.offers_free_trial }} Days Free Trial
      </div>
      <p class="title">
        {{ getPlanNameFromProduct(props.product) }} <b>Pro</b>
      </p>
      <p id="priceText">
        {{ getPriceTextFromProduct(props.product)
        }}<span id="priceText2">{{ duration }}</span>
      </p>
      <button
        :class="`btn ${
          props.product.price.offers_free_trial ? 'free-trial-color' : ''
        }`"
        @click="props.onclick"
        id="unlock-btn"
      >
        Unlock Wonder Pro
      </button>
      <div class="advantages-container">
        <div
          class="row"
          v-for="(item, index) in advantages"
          :key="`${product.id}-bulletItem-${index}`"
        >
          <div class="bullet-icon">
            <img :src="item.icon" :alt="item.title" />
          </div>
          <div class="bullet-item">
            <p class="bullet-item-title">{{ item.title }}</p>
            <p class="bullet-item-subtitle">{{ item.subtitle }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.paywall-product-box {
  padding: 30px;
  width: 330px;
  border: 1px solid #000000;
  border-radius: 18px;
  min-height: 419px;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.paywall-product-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.free-trial {
  display: flex;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  transform: rotate(45deg);
  padding: 6px 32px;
  flex-shrink: 0;
  position: absolute;
  top: 30px;
  left: 195px;
  width: 175px;
  justify-content: center;
}

.free-trial-color {
  background-color: #823cee !important;
}

.title {
  font-size: 36px;
  font-weight: 275;
  line-height: normal;
  letter-spacing: -0.02em;
  margin: 18px 0 8px 0;
}

.advantages-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 22px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: left;
}

.bullet-icon img {
  display: flex;
  justify-content: center;
}

.bullet-item {
  margin-left: 20px;
  text-align: left;
}

.bullet-item-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
}

.bullet-item-subtitle {
  font-weight: 400;
  font-size: 13px;
  line-height: 23px;
  opacity: 0.5;
}

p {
  margin: 0;
}

#unlock-btn {
  width: 100%;
  height: 60px;
  background: #000000;
  border-radius: 17.886px;
  color: #fff;
  margin: 24px 0px;
  font-size: 16px;
  font-weight: 600;
  background-color: #ff2567;
}

#priceText {
  font-weight: 700;
  font-size: 40px;
  line-height: normal;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.02em;
}

#priceText2 {
  font-size: 16px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.02em;
  color: #00000080;
}
</style>
