export default [
  {
    text: "Glass mosaic portrait of profile of a girl with her hair fluttering in the wind, wearing a transparent dress with sleeves, her arm up parallel to the ground, she has the sun lying on her hand, in voronoi minkowski style, soviet realism style",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h1.JPG",
    title: "Mosaic Girl Portrait",
  },
  {
    text: "A duck swimming in a pond, in a forest - origami style",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l1.JPG",
    title: "Swimming Duck",
  },
  {
    text: "A cute kawaii plush toy, white bunny on a meadow, blue sky and warm light, vibrant colors, highly detailed, sharp focus, studio photo, highly detailed, dynamic shot",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h20.JPG",
    title: "The Bunny",
  },
  {
    text: "A creepy man in a house looking inside through the window - halloween style",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l11.JPG",
    title: "Creepy Man",
  },
  {
    text: "A quirky man sitting on a bench in a suburban street - toon style",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l5.JPG",
    title: "Man as a Toon Character",
  },
  {
    text: "A portrait of a woman with cracked glass face, alejandro burdisio, white and gold, paul hedley, insanely detailed, octane render, trending on artstation, perfect light, abstract vector fractal, wave function, Zentangle, 3d shading intricate details, HDR, beautifully shot, hyperrealistic, sharp focus, skin looks like old porcelain, cracks everywhere, broken skin, cracks all over the face",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h10.JPG",
    title: "Girl Made of Glass",
  },
  {
    text: "A superhero and villian fighting - comic book style",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l15.JPG",
    title: "Comic Book Hero vs Villain",
  },
  {
    text: "Classic car made of soap bubbles, action footage on a road driving fast at night",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h2.JPG",
    title: "The Car",
  },
  {
    text: "The fool, tarot card art, occult symbolism",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h17.JPG",
    title: "Tarot Card",
  },
  {
    text: "Sun setting over the sea in a peaceful beach environment - Van Gogh style",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l16.JPG",
    title: "Beautiful View",
  },
  {
    text: "A dog wearing suit and holding a pistol - vice style",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l2.JPG",
    title: "Dog in Vice Universe",
  },
  {
    text: "Highly detailed linework reminiscent of Carne Griffiths, scarecrow, crows, imbued with Wadim Kashim's bold color and texture, light and airy as Carl Larsson's compositions, dark background, dark, evil, field, fire, featuring Pascal Blanche-style hyper realistic characters, pastel, elegance, dramatic lighting, expressive camera angle, matte, concept art, corn field, style by Arthur Rackham and Brian Froud",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h5.JPG",
    title: "Field",
  },
  {
    text: "A beautiful black woman with short hair wearing an orange blouse - studio lighting style",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l19.JPG",
    title: "Beautiful Black Woman",
  },
  {
    text: "Close-up macro photo of a bright green eye - hyperrealistic style",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l9.JPG",
    title: "Hyperrealistic Eye",
  },
  {
    text: "Masterpiece dapper portrait of tintin by studio harcourt, by Arlington Nelson Lindenmuth, monochromatic palette, dark palette, highly professional, dramatic lighting, extremely beautiful, intricate details, masterpiece, best quality, close up portrait, Googie Art Style, dynamic, dramatic, 1950's looking at viewer, dynamic pose, silky clothing",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h4.JPG",
    title: "1950's Gentleman",
  },
  {
    text: "An epic landscape shrouded in darkness by an eclipse",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h16.JPG",
    title: "Eclipse",
  },
  {
    text: "Supernova explosion inside a glass jar, the jar stands on the kitchen table, 4K",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h3.JPG",
    title: "The Jar",
  },
  {
    text: "A mystical fairy shimmering with sparks - novelistic style",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l3.JPG",
    title: "Fairy",
  },
  {
    text: "A very foggy city landscape, at night, dark ambient, very slightly purple color palette",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h6.JPG",
    title: "Purple City",
  },
  {
    text: "Portrait of a royal woman wearing a crown and holding a baton - oil painting style",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l10.JPG",
    title: "Royal Woman",
  },
];
