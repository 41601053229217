<script setup>
import { onBeforeUnmount, ref } from "vue";
import { Slide } from "vue3-burger-menu";

const isSlideMenuOpen = ref(false);

const handleOpenMenu = () => {
  isSlideMenuOpen.value = true;
  document.querySelector(".bm-menu").addEventListener("click", handleCloseMenu);
};

const handleCloseMenu = () => {
  isSlideMenuOpen.value = false;
  document
    .querySelector(".bm-menu")
    .removeEventListener("click", handleCloseMenu);
};

onBeforeUnmount(handleCloseMenu);
</script>

<template>
  <div
    :class="isSlideMenuOpen ? 'hamburger-menu-overlay' : ''"
    @click="isSlideMenuOpen = false"
  ></div>
  <Slide
    right
    @openMenu="handleOpenMenu"
    @closeMenu="handleCloseMenu"
    :isOpen="isSlideMenuOpen"
  >
    <slot></slot>
  </Slide>
</template>

<style>
.bm-burger-button {
  left: auto !important;
  width: 23px !important;
  height: 23px !important;
  right: 36px !important;
  top: 27px !important;
}

.bm-overlay {
  background: inherit !important;
}

.bm-menu {
  background: #f5f5f5 !important;
}

.hamburger-menu-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
  position: fixed;
  display: none;
}

.bm-overlay .hamburger-menu-overlay {
  display: block !important;
}

.bm-item-list > * > span {
  color: inherit !important;
  margin-left: 0 !important;
}
</style>
