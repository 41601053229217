<script setup>
import { computed } from "@vue/reactivity";

const props = defineProps([
  "product",
  "isSelected",
  "clickHandler",
  "badgeText",
]);

const priceText = computed(() => {
  if (!props.product.price) {
    return "No price";
  }
  if (props.product.price.type !== "recurring") {
    return [
      `$${(props.product.price.unit_amount / 100).toFixed(2)}`,
      `, ${props.product.price.nickname}`,
    ];
  } else {
    return [
      `$${(props.product.price.unit_amount / 100).toFixed(2)}`,
      `/${props.product.price.recurring.interval}`,
    ];
  }
});
</script>

<template>
  <div
    :class="`product-btn ${isSelected ? 'selected' : ''}`"
    @click="clickHandler"
  >
    <span>
      {{ priceText[0] }}<span id="priceText2">{{ priceText[1] }}</span>
    </span>
    <span v-if="badgeText" class="badge">{{ badgeText }}</span>
  </div>
</template>

<style scoped>
.product-btn {
  background: #ffffff;
  border: 3px solid rgba(17, 26, 24, 0.1);
  border-radius: 18px;
  padding: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin: 12px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
