<script setup>
import { sendEvent } from "@/helpers/cerebro";
import { initiatePaddleCheckout } from "@/helpers/paddle";
import { ref } from "vue";
import BlackButton from "./BlackButton.vue";
import ComparePaywallProduct from "./ComparePaywallProduct.vue";
import CustomSpinner from "./CustomSpinner.vue";

const props = defineProps([
  "advantages",
  "isMobile",
  "isPopup",
  "isCard",
  "paywallSource",
  "products",
  "preSelectedProductIndex",
  "showUnitPriceIn",
  "closeFunc",
]);

const isCheckoutInitiated = ref(false);
const selectedProductIndex = ref(props.preSelectedProductIndex);
const lang = navigator?.language?.split("-")[0] == "tr" ? "tr" : "en";

const onClickProduct = (index) => {
  selectedProductIndex.value = index;
  sendEvent({
    eventName: "click_product",
    properties: {
      selectedProductId: props.products[index].priceId,
      offersFreeTrial: props.product[index].price.offers_free_trial,
      source: props.paywallSource,
      paywallType: "compare",
      productIds: props.products.map((product) => product.priceId),
    },
  });
};

const checkoutFunc = async (product, advantages) => {
  initiatePaddleCheckout(product, advantages, props.paywallSource);
  if (props.closeFunc) props.closeFunc();
};
</script>
<template>
  <div class="products-container">
    <div v-if="!props.isMobile && !props.isCard" class="products-header">
      <h1>Try Wonder Pro</h1>
      <p>Start creating with Wonder and see the magic ✨</p>
    </div>
    <div class="products-body">
      <Transition name="fade" mode="out-in">
        <div v-if="!props.products?.length" class="spinner">
          <CustomSpinner />
        </div>
        <div v-else class="products-list">
          <ComparePaywallProduct
            v-for="(product, index) in props.products"
            :key="`product-${product.id}`"
            :product="product"
            :showUnitPriceIn="props.showUnitPriceIn"
            :hideDiscountedUnitPrice="props.isMobile && props.isCard"
            :selected="selectedProductIndex === index"
            :onClick="() => onClickProduct(index)"
          >
          </ComparePaywallProduct>
        </div>
      </Transition>
      <div class="payment-providers-container">
        <div class="pay-safe">
          <img
            src="@/assets/icons/shield_icon_green.svg"
            alt="Pay safe & secure"
          />
          <p>Pay safe & secure</p>
        </div>
        <div v-if="!props.isMobile" class="providers">
          <img src="@/assets/images/providerPayPal.svg" alt="PayPal" />
          <img src="@/assets/images/providerApplePay.svg" alt="ApplePay" />
          <img src="@/assets/images/providerGooglePay.svg" alt="GooglePay" />
          <img src="@/assets/images/providerVisa.svg" alt="Visa" />
          <img src="@/assets/images/providerMasterCard.svg" alt="MasterCard" />
          <img src="@/assets/images/providerMaestro.svg" alt="Maestro" />
          <img src="@/assets/images/providerDiscover.svg" alt="Discover" />
          <img src="@/assets/images/providerAmex.svg" alt="Amex" />
        </div>
      </div>
      <div class="submit-container">
        <BlackButton
          :clickHandler="
            () => checkoutFunc(products[selectedProductIndex], advantages)
          "
          :disabled="
            !products?.length ||
            selectedProductIndex < 0 ||
            selectedProductIndex >= products?.length ||
            isCheckoutInitiated
          "
        >
          Get My Plan
        </BlackButton>
        <div class="footer">
          <span class="legal">
            By continuing an account, you agree to our
            <a
              href="https://static.wonderai.app/privacy"
              target="_blank"
              class="legal-link"
              >Privacy Policy</a
            >,
            <a
              href="https://static.wonderai.app/terms-conditions"
              target="_blank"
              class="legal-link"
              >Terms of Use</a
            >
            and
            <a
              :href="`https://static.wonderai.app/refund-${lang}.html`"
              target="_blank"
              class="legal-link"
              >Refund & Cancellation Policy</a
            >.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.products-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.products-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 335px;
}

.products-header h1 {
  align-self: stretch;
  color: #111a18;
  font-size: 28px;
  font-weight: 700;
  margin: 0;
}

.products-header p {
  align-self: stretch;
  color: #111a1899;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.products-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.products-body .spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 100%;
}

.products-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  align-self: stretch;
}

.payment-providers-container {
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.payment-providers-container .pay-safe {
  display: flex;
  height: 24px;
  padding: 4.22px 8px 2.94px 8px;
  align-items: center;
  gap: 6px;
}

.payment-providers-container .pay-safe p {
  color: #111a18;
  font-size: 14px;
  font-weight: 600;
}

.payment-providers-container .providers {
  display: flex;
  padding: 0px 37px;
  justify-content: center;
  gap: 4px;
}

.submit-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;
}

.submit-container .footer {
  display: flex;
  justify-content: space-between;
  align-self: stretch;
}

.submit-container .footer .legal {
  color: #111a1880;
  font-size: 12px;
  font-weight: 500;
}

.submit-container .footer .legal .legal-link {
  color: #111a18;
  font-weight: 700;
}

@media screen and (max-width: 1024px) {
  .products-container {
    width: auto;
  }

  .products-body {
    width: 335px;
  }

  .column-view .products-body {
    width: 100%;
  }

  .payment-providers-container .pay-safe p {
    font-size: 12px;
  }
}
</style>
