<script setup>
import CustomSpinner from "@/components/CustomSpinner.vue";
import OnboardingDynamic from "@/components/OnboardingDynamic.vue";
import OnboardingStatic from "@/components/OnboardingStatic.vue";
import { sendEvent } from "@/helpers/cerebro";
import { getQueryParams } from "@/helpers/page";
import { useUserStore } from "@/store/user";
import { computed, onMounted } from "vue";

const userStore = useUserStore();

const onboardingId = computed(() => getQueryParams().id || "default");

const onboardingStructure = computed(() => {
  return userStore.getParameter("onboarding_structure")?.[onboardingId.value];
});

onMounted(() => {
  setTimeout(() => {
    if (!onboardingStructure.value) {
      userStore.setParameter("onboarding_structure", {
        [onboardingId.value]: {
          onboarding_type: "static",
        },
      });
      sendEvent({
        eventName: "onboarding_structure_failed_to_load",
        properties: { onboardingId: onboardingId.value },
      });
    }
  }, 5000);
});
</script>
<template>
  <Transition name="fade" mode="out-in">
    <div v-if="!onboardingStructure" class="spinner">
      <CustomSpinner />
    </div>
    <div v-else-if="onboardingStructure.onboarding_type === 'dynamic'">
      <OnboardingDynamic
        :id="onboardingId"
        :pages="onboardingStructure.pages"
      />
    </div>
    <div v-else>
      <OnboardingStatic />
    </div>
  </Transition>
</template>
<style scoped>
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
