<script setup>
import DiscountIcon from "@/assets/icons/discount.png";
import FastForwardIcon from "@/assets/icons/fast_forward.png";
import SparklesIcon from "@/assets/icons/sparkles.png";
import BoxPaywallProduct from "@/components/BoxPaywallProduct.vue";
import { sendEvent } from "@/helpers/cerebro";
import { initiatePaddleCheckout } from "@/helpers/paddle";
import { getProductIdsByCode } from "@/helpers/payment";
import { onMounted, ref } from "vue";
import CustomSpinner from "./CustomSpinner.vue";

const props = defineProps(["isPopup", "paywallSource", "paywallStructure"]);

const isCheckoutInitiated = ref(false);
const products = ref(null);

const advantages = [
  {
    icon: FastForwardIcon,
    title: "Fast Processing",
    subtitle: "Get faster results",
  },
  {
    icon: SparklesIcon,
    title: "50 credits/day",
    subtitle: "Expand your creativity",
  },
  {
    icon: DiscountIcon,
    title: "50% off credit packs",
    subtitle: "Enjoy Wonder without limits",
  },
];

onMounted(async () => {
  try {
    await loadProducts();
  } catch (error) {
    console.error(error);
  }
  setTimeout(async () => {
    if (!products.value || products.value.length === 0) {
      products.value = await getProductIdsByCode([
        "pro.1week.t6",
        "pro.lifetime.t6",
      ]);
      sendEvent({
        eventName: "paywall_products_failed_to_load",
        properties: { source: props.paywallSource },
      });
    }
  }, 5000);
  sendEvent({
    eventName: "paywall",
    properties: {
      source: props.paywallSource,
      paywallType: "box",
      paddleIdentifiers: products.value.map((product) => product.iap_id),
      productIdentifiers: products.value.map((product) => product.price.id),
    },
    eventGroup: "paywall",
  });
});

const loadProducts = async () => {
  products.value = await getProductIdsByCode(
    props.paywallStructure?.products.map(
      (product) => `${product.id}.${product.tier}`
    )
  );
  products.value?.forEach((product, index) => {
    product.tag = props.paywallStructure.products[index].tag;
    product.price_without_discount =
      props.paywallStructure.products[index].price_without_discount;
  });
};

const onClickProduct = (product) => {
  if (isCheckoutInitiated.value) return;
  isCheckoutInitiated.value = true;
  sendEvent({
    eventName: "click_product",
    properties: {
      selectedProductId: product.priceId,
      offersFreeTrial: product.price.offers_free_trial,
      source: props.paywallSource,
      paywallType: "box",
      productIds: products.value.map((product) => product.priceId),
    },
  });
  initiatePaddleCheckout(product, advantages, props.paywallSource);
};
</script>
<template>
  <div id="wrapper">
    <h1 v-if="props.isPopup">
      Try <span class="colorful">Wonder</span> Premium
    </h1>
    <div class="paywall-body">
      <img
        src="@/assets/images/paywall_img_left.png"
        alt=""
        class="paywall-images"
        v-if="props.isPopup"
      />
      <Transition name="fade" mode="out-in">
        <div v-if="!products?.length" class="spinner">
          <CustomSpinner />
        </div>
        <div v-else class="products-list">
          <BoxPaywallProduct
            v-for="product in products"
            :key="`product-${product.id}`"
            :product="product"
            :onclick="() => onClickProduct(product)"
            :advantages="advantages"
          ></BoxPaywallProduct>
        </div>
      </Transition>
      <img
        src="@/assets/images/paywall_img_right.png"
        alt=""
        class="paywall-images"
        v-if="props.isPopup"
      />
    </div>
  </div>
</template>

<style scoped>
#wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

h1 {
  margin-top: 20px;
  margin-bottom: 64px;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: -0.02em;
}
.paywall-body {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.products-list {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.paywall-images {
  height: 350px;
}

.colorful {
  background: radial-gradient(
    55.91% 83.76% at 7.79% 24.37%,
    #01faeb 0%,
    #6b46ff 43.87%,
    #ff008f 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
}

@media screen and (max-width: 1024px) {
  .products-list {
    flex-direction: column;
    gap: 10px;
  }
}
</style>
