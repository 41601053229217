<script setup>
import {
  getPlanNameFromProduct,
  getPriceTextFromProduct,
  getUnitPricePerIntervalFromProduct,
} from "@/helpers/product";
import { computed } from "vue";

const props = defineProps([
  "product",
  "selected",
  "onClick",
  "showUnitPriceIn",
  "hideDiscountedUnitPrice",
]);

const unitPrice = computed(() => {
  if (props.product) {
    return getUnitPricePerIntervalFromProduct(
      props.product,
      props.showUnitPriceIn
    );
  }
  return null;
});

const unitPriceDiscount = computed(() => {
  if (props.product) {
    return getUnitPricePerIntervalFromProduct(
      props.product,
      props.showUnitPriceIn,
      true
    );
  }
  return null;
});
</script>
<template>
  <div
    v-if="props.product"
    :class="`product ${props.selected ? 'active' : ''}`"
  >
    <div v-if="props.product.tag" class="tag-container">
      <div v-if="props.product.tag === 'most_popular'" class="tag">
        <img
          v-if="!props.selected"
          src="@/assets/icons/shield_icon_gradient.svg"
          alt="Most Popular"
        />
        <img
          v-else
          src="@/assets/icons/shield_icon_white.svg"
          alt="Most Popular"
        />
        MOST POPULAR
      </div>
      <div v-else class="tag">
        {{ props.product.tag }}
      </div>
    </div>
    <div :class="`offer-border ${!props.product.tag ? 'no-tag' : ''}`">
      <div class="offer" @click="props.onClick">
        <div class="details">
          <h2>
            {{ getPlanNameFromProduct(props.product) + " Plan" }}
          </h2>
          <div class="total-price">
            <p v-if="props.product.price_without_discount" class="line-through">
              {{
                `$${Number(props.product.price_without_discount).toFixed(2)}`
              }}
            </p>
            <p>
              {{ getPriceTextFromProduct(props.product) }}
            </p>
          </div>
        </div>
        <div
          v-if="props.product.price.type === 'recurring'"
          class="unit-price-container"
        >
          <p
            v-if="
              props.product.price_without_discount &&
              !props.hideDiscountedUnitPrice
            "
            class="line-through"
          >
            {{ `$${unitPriceDiscount}` }}
          </p>
          <div class="final-price-container">
            <span class="currency">$</span>
            <span class="integer">{{ unitPrice?.split(".")[0] }}</span>
            <div class="fraction-per-container">
              <span class="fractional">{{ unitPrice?.split(".")[1] }}</span>
              <span class="per"> per {{ props.showUnitPriceIn }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.product {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  align-self: stretch;
}

.tag-container {
  display: flex;
}

.tag {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  border-radius: 12px 12px 0px 0px;
  border-top: 1px solid rgba(17, 26, 24, 0.1);
  border-right: 1px solid rgba(17, 26, 24, 0.1);
  border-left: 1px solid rgba(17, 26, 24, 0.1);
  background: #fff;
  backdrop-filter: blur(25px);
  font-size: 10px;
  font-weight: 600;
  gap: 10px;
}

.active .tag {
  border-color: transparent;
  background: linear-gradient(139deg, #bd00ff 0%, #4f95ff 100%), #fff;
  background-origin: border-box;
  color: #fff;
}

.no-tag,
.active .no-tag,
.no-tag .offer {
  border-top-right-radius: 16px;
}

.offer-border {
  align-self: stretch;
  border-radius: 16px 0px 16px 16px;
}

.active .offer-border {
  background: linear-gradient(139deg, #bd00ff 0%, #4f95ff 100%), #fff;
  background-origin: border-box;
  backdrop-filter: blur(25px);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
}

.offer {
  display: flex;
  padding: 12px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 16px 0px 16px 16px;
  border: 1px solid rgba(17, 26, 24, 0.1);
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.active .offer {
  position: relative;
  background-image: linear-gradient(
    139deg,
    rgba(189, 0, 255, 0.1) 0%,
    rgba(79, 149, 255, 0.1) 96.68%
  );
  background-clip: padding-box;
  border-color: transparent;
  box-shadow: none;
}

.offer .details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.offer .details h2 {
  color: #111a18;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.offer .details p {
  color: rgba(17, 26, 24, 0.5);
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

.offer .total-price {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.offer .unit-price-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.offer .unit-price-container .final-price-container {
  display: flex;
  padding: 6px 10px;
  border-radius: 10px;
  background: #f5f5f5;
  align-items: flex-start;
}

.active .offer .unit-price-container .final-price-container {
  background: #fff;
}

.offer .unit-price-container .final-price-container .currency {
  color: #111a18;
  font-size: 12px;
  font-weight: 500;

  display: inline-block;
  vertical-align: top;
  height: 0.9em;
  line-height: 0.9em;
}

.offer .unit-price-container .final-price-container .integer {
  color: #111a18;
  font-size: 28px;
  font-weight: 500;

  display: inline-block;
  vertical-align: top;
  height: 0.75em;
  line-height: 0.8em;
}

.offer .unit-price-container .final-price-container .fraction-per-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}

.offer
  .unit-price-container
  .final-price-container
  .fraction-per-container
  .fractional {
  color: #111a18;
  font-size: 12px;
  font-weight: 500;

  display: inline-block;
  vertical-align: top;
  height: 0.75em;
  line-height: 0.8em;
}

.offer
  .unit-price-container
  .final-price-container
  .fraction-per-container
  .per {
  color: #111a18;
  font-size: 10px;
  font-weight: 500;
  opacity: 0.5;

  display: inline-block;
  margin-top: 3px;
  vertical-align: top;
  height: 0.75em;
  line-height: 0.8em;
}
</style>
