<script setup>
import { ref, watch } from "vue";

import HrWithText from "../components/HrWithText.vue";

import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";

import ErrorIcon from "@/assets/icons/ErrorIcon.vue";
import { sendEvent } from "@/helpers/cerebro";
import { isStaging } from "@/helpers/environment";
import { setUserData } from "../helpers/userManager";
import { sendVerificationEmail } from "../helpers/verificationEmail.js";

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

const providerDetails = {
  google: {
    provider: googleProvider,
    eventName: "click_google_button",
  },
  facebook: {
    provider: facebookProvider,
    eventName: "click_facebook_button",
  },
  apple: {
    provider: appleProvider,
    eventName: "click_apple_button",
  },
};

const errorCodeToMessage = {
  "auth/network-error": "Oops! Something went wrong. Please try again.",
  "auth/email-already-in-use": "Email already in use.",
  "auth/invalid-email": "Invalid email.",
  "auth/weak-password": "Weak password.",
};

const props = defineProps(["type", "bottomText"]);
const email = ref("");
const password = ref("");
const errMsg = ref("");
const forgotPassword = ref(false);
const forgotStep = ref("idle");

const getErrorMessage = (code) => {
  if (
    code == "auth/cancelled-popup-request" ||
    code == "auth/popup-closed-by-user"
  ) {
    return "";
  }
  return errorCodeToMessage[code] || "Please try again";
};

const openForgotPassword = () => {
  sendEvent({
    eventName: "click_forgot_password",
    eventGroup: "authentication",
  });
  errMsg.value = "";
  forgotPassword.value = true;
};

const sendForgotPasswordEmail = () => {
  sendEvent({
    eventName: "click_send_instructions_button",
    page: "forgotPassword",
  });
  if (forgotStep.value == "idle") {
    errMsg.value = "";
    forgotStep.value = "sending";
    sendPasswordResetEmail(getAuth(), email.value)
      .then(() => {
        forgotStep.value = "sent";
        sendEvent({
          eventName: "send_instructions",
          page: "forgotPassword",
          result: "success",
        });
      })
      .catch((err) => {
        if (isStaging()) console.log(err);
        sendEvent({
          eventName: "send_instructions",
          page: "forgotPassword",
          result: "success",
          properties: {
            code: err.code,
            message: err.message,
          },
        });
        if (err.code == "auth/invalid-email") {
          errMsg.value = "Invalid email.";
        } else if (err.code == "auth/missing-email") {
          errMsg.value = "E-mail address is missing.";
        } else if (err.code == "auth/user-not-found") {
          errMsg.value = "This email is not registered.";
        } else {
          errMsg.value = "Unexpected error occured";
        }
        forgotStep.value = "idle";
      });
  }
};

const register = async () => {
  errMsg.value = "";
  if (props.type == "signin") {
    sendEvent({
      eventName: "click_log_in_button",
      eventGroup: "authentication",
    });
    try {
      await signInWithEmailAndPassword(getAuth(), email.value, password.value);
    } catch (error) {
      errMsg.value = getErrorMessage(error.code);
    }
  } else {
    sendEvent({
      eventName: "click_sign_up_button",
      eventGroup: "authentication",
    });
    createUserWithEmailAndPassword(getAuth(), email.value, password.value)
      .then(async (data) => {
        try {
          await sendVerificationEmail();
          await setUserData({
            userId: data.user.uid,
            email: data.user.email,
            createdAt: data.user.createdAt,
            providerData: data.user.providerData,
          });
        } catch (error) {
          if (isStaging()) console.log(error);
        }
      })
      .catch((error) => {
        if (isStaging()) console.log(error.code);
        errMsg.value = getErrorMessage(error.code);
      });
  }
};

const supportedPopupSignInMethods = [
  googleProvider.PROVIDER_ID,
  facebookProvider.PROVIDER_ID,
  appleProvider.PROVIDER_ID,
];

const signInWithProvider = async (providerName) => {
  errMsg.value = "";
  sendEvent({
    eventName: providerDetails[providerName].eventName,
    eventGroup: "authentication",
  });
  try {
    const response = await signInWithPopup(
      getAuth(),
      providerDetails[providerName].provider
    );
  } catch (err) {
    if (err.code === "auth/account-exists-with-different-credential") {
      const providers = await fetchSignInMethodsForEmail(
        getAuth(),
        err.customData.email
      );
      const provider = providers[0];
      const providerToName = {
        password: "e-mail and password",
        "google.com": "Google login",
        "facebook.com": "Facebook login",
        "apple.com": "Apple login",
      };
      errMsg.value = `You have logged in before using ${
        providerToName[provider]
          ? providerToName[provider]
          : "a different method"
      } for the same e-mail. Please use that method again to login.`;
      // const linkedProvider = getProvider(firstPopupProviderMethod);
      // linkedProvider.setCustomParameters({ login_hint: err.email });

      // const result = await getAuth().signInWithPopup(linkedProvider);
      // result.user.linkWithCredential(err.credential);
    } else {
      if (isStaging()) console.log(err.code);
      errMsg.value = getErrorMessage(err.code);
    }
  }
};

const activateEmailForm = () => {
  document.getElementById("active-signing-email-btn").style.display = "none";
  document.getElementById("email-form").style.display = "block";
  sendEvent({
    eventName: "click_email_button",
    eventGroup: "authentication",
  });
};

watch(errMsg, (newVal) => {
  if (newVal) {
    setTimeout(() => {
      errMsg.value = "";
    }, 5000);
  }
});
</script>

<template>
  <div class="container">
    <div id="signin-img-box">
      <img
        src="https://storage.googleapis.com/wonder-eed94.appspot.com/website_images/signin_image.png"
        alt="Signin image"
        id="signin-img"
      />
    </div>
    <div id="signin-form-box" v-if="!forgotPassword">
      <h1>
        Welcome {{ type == "signin" ? "back" : "" }} to
        <span class="gradient-text">Wonder</span>
      </h1>
      <p class="hidden-md-up faded-text">
        {{ type == "signin" ? "Login" : "Sign up" }} to publish artworks in your
        gallery
      </p>
      <button
        class="btn hidden-md-up"
        id="active-signing-email-btn"
        @click="activateEmailForm"
      >
        {{ type == "signin" ? "Login" : "Sign up" }} with e-mail
      </button>
      <div id="signin-form">
        <Transition name="fade">
          <div class="error-message" v-if="errMsg">
            <ErrorIcon />
            <span>{{ errMsg }}</span>
          </div>
        </Transition>
        <div class="hidden-sm-down" id="email-form">
          <div class="input-box">
            <input
              class="form-control"
              type="email"
              placeholder="E-mail address"
              id="email"
              v-model="email"
            />
          </div>
          <div class="input-box">
            <input
              class="form-control"
              type="password"
              placeholder="Password"
              name="password"
              id="password"
              v-model="password"
            />
          </div>
          <div style="text-align: right" v-if="type == 'signin'">
            <span class="action-text" @click="openForgotPassword"
              >Forgot password?</span
            >
          </div>
          <button @click="register" class="btn signin-btn">
            {{ type == "signin" ? "Log in" : "Sign up" }}
          </button>
        </div>
        <HrWithText text="or continue with"></HrWithText>
        <div id="other-logins">
          <button
            class="btn"
            id="apple-btn"
            @click="
              () => {
                signInWithProvider('apple');
              }
            "
          >
            <img
              src="https://storage.googleapis.com/wonder-eed94.appspot.com/website_images/apple_icon.png"
              alt="apple"
            />
          </button>
          <button
            class="btn"
            id="google-btn"
            @click="
              () => {
                signInWithProvider('google');
              }
            "
          >
            <img src="@/assets/images/google_icon.png" alt="google" />
          </button>
          <button
            class="btn"
            id="facebook-btn"
            @click="
              () => {
                signInWithProvider('facebook');
              }
            "
          >
            <img src="@/assets/images/facebook_icon.png" alt="facebook" />
          </button>
        </div>
        <p class="mute-text" v-if="type == 'signin'">
          Don't have an account?
          <router-link
            :to="{ name: 'Signup' }"
            @click="
              () => {
                sendEvent({
                  eventName: 'click_sign_up',
                  eventGroup: 'authentication',
                });
              }
            "
          >
            <span class="action-text"> Sign up </span>
          </router-link>
        </p>
        <p class="mute-text" v-else>
          Already have an account?
          <router-link
            :to="{ name: 'Signin' }"
            @click="
              () => {
                sendEvent({
                  eventName: 'click_log_in',
                  eventGroup: 'authentication',
                });
              }
            "
          >
            <span class="action-text">Log in</span>
          </router-link>
        </p>
      </div>
    </div>
    <div id="signin-form-box" v-else>
      <h1>Forgot Password</h1>
      <div id="signin-form">
        <Transition name="fade">
          <div class="error-message" v-if="errMsg">
            <ErrorIcon />
            <span>{{ errMsg }}</span>
          </div>
        </Transition>
        <p class="faded-text">
          Enter the email address you used when you joined and we’ll send you a
          link to reset your password.
        </p>
        <div class="input-box">
          <input
            class="form-control"
            type="email"
            placeholder="E-mail address"
            id="email"
            v-model="email"
          />
        </div>
        <button @click="sendForgotPasswordEmail" class="btn signin-btn">
          {{
            forgotStep == "sending"
              ? "Sending"
              : forgotStep == "sent"
              ? "Sent successfully"
              : "Send"
          }}
        </button>
        <p class="faded-text">
          By continuing, you agree to our
          <a
            href="https://static.wonderai.app/privacy"
            target="_blank"
            class="legal-link"
            >Privacy Policy</a
          >
          and
          <a
            href="https://static.wonderai.app/terms-conditions"
            target="_blank"
            class="legal-link"
            >Terms of Use</a
          >.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  padding: 0px 100px;
  color: #000;
  background-color: #fff;
}

#signin-img-box {
  padding: 20px;
  flex: 6;
}

#signin-form-box {
  padding: 20px;
  flex: 4;
}

#signin-img {
  max-height: 600px;
  max-height: 90vh;
}

#signin-form-box {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

h1 {
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  margin: 0;
}

#signin-form {
  width: 335px;
  margin: 0 auto;
}

.input-box {
  width: 100%;
  margin: 20px 0px;
}

.signin-btn {
  width: 335px;
  background: #000;
  color: #fff;
  margin-top: 40px;
}

#other-logins {
  display: flex;
  justify-content: space-between;
}

#other-logins > .btn {
  width: 160px;
  height: 60px;
  padding: 14px;
}

#other-logins > .btn + .btn {
  margin-left: 10px;
}
#other-logins > .btn > img {
  width: 32px;
  height: 32px;
}

#apple-btn {
  background-color: #000;
}

#google-btn {
  border: 1px solid #111a1880;
  background-color: #fff;
}

#facebook-btn {
  background-color: #4267b2;
}

.error-message {
  color: #ff005c;
  background-color: #fff6fa;
  border-radius: 12px;
  height: 70px;
  width: 335px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 54px;
  gap: 16px;
}

@media (max-aspect-ratio: 3/2) {
  .container {
    flex-direction: column-reverse;
  }
  #signin-img {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.02em;
  }

  .container {
    padding: 0;
  }

  .faded-text {
    font-size: 14px;
  }

  .signin-btn {
    margin-top: 15px;
  }

  #active-signing-email-btn {
    width: 100%;
    color: #000;
    border-radius: 16px;
    background: #f5f5f5;
  }

  .error-message {
    top: 16px;
  }
}
</style>
