<script setup>
import DiscountIconDesktop from "@/assets/icons/discount.png";
import DiscountIconMobile from "@/assets/icons/discount_dark.png";
import FastForwardIconDesktop from "@/assets/icons/fast_forward.png";
import FastForwardIconMobile from "@/assets/icons/fast_forward_dark.png";
import MagicWandIconDesktop from "@/assets/icons/magic_wand.png";
import MagicWandIconMobile from "@/assets/icons/magic_wand_dark.png";
import SparklesIconDesktop from "@/assets/icons/sparkles.png";
import SparklesIconMobile from "@/assets/icons/sparkles_dark.png";
import WonderArtworkIconMobile from "@/assets/icons/wonder_artwork.png";
import WonderArtworkIconDesktop from "@/assets/icons/wonder_artwork_white.png";
import { sendEvent } from "@/helpers/cerebro";
import useDeviceType from "@/helpers/composables/useDeviceType";
import { getProductIdsByCode } from "@/helpers/payment";
import { computed, onMounted, ref } from "vue";
import ComparePaywallAdvantagesContainer from "./ComparePaywallAdvantagesContainer.vue";
import ComparePaywallProductsContainer from "./ComparePaywallProductsContainer.vue";

const props = defineProps([
  "isPopup",
  "paywallSource",
  "paywallStructure",
  "isCard",
  "closeFunc",
  "skipFunc",
]);

const products = ref(null);
const { deviceType } = useDeviceType();
const isMobile = computed(() => deviceType.value === "mobile");
const preSelectedProductIndex = computed(
  () => props.paywallStructure?.selected_index ?? 1
);
const showUnitPriceIn = computed(
  () => props.paywallStructure?.show_unit_price_in ?? "day"
);

onMounted(async () => {
  try {
    await loadProducts();
  } catch (error) {
    console.error(error);
  }
  setTimeout(async () => {
    if (!products.value || products.value.length === 0) {
      products.value = await getProductIdsByCode([
        "pro.1week.t6",
        "pro.lifetime.t6",
      ]);
      sendEvent({
        eventName: "paywall_products_failed_to_load",
        properties: { source: props.paywallSource },
      });
    }
  }, 5000);
  sendEvent({
    eventName: "paywall",
    properties: {
      source: props.paywallSource,
      paywallType: "compare",
      paddleIdentifiers: products.value.map((product) => product.iap_id),
      productIdentifiers: products.value.map((product) => product.price.id),
    },
    eventGroup: "paywall",
  });
});

const loadProducts = async () => {
  products.value = await getProductIdsByCode(
    props.paywallStructure.products.map(
      (product) => `${product.id}.${product.tier}`
    )
  );
  products?.value.forEach((product, index) => {
    product.tag = props.paywallStructure.products[index].tag;
    product.price_without_discount =
      props.paywallStructure.products[index].price_without_discount;
  });
};

const advantages = computed(() => [
  {
    icon: isMobile.value ? WonderArtworkIconMobile : WonderArtworkIconDesktop,
    title: "Creations from SDXL",
    subtitle: "More accurate & detailed artworks",
    free: true,
    pro: true,
  },
  {
    icon: isMobile.value ? FastForwardIconMobile : FastForwardIconDesktop,
    title: "Fast Processing",
    subtitle: "Get faster results",
    free: false,
    pro: true,
  },
  {
    icon: isMobile.value ? SparklesIconMobile : SparklesIconDesktop,
    title: "50 credit pack/day",
    subtitle: "Expand your creativity",
    free: false,
    pro: true,
  },
  {
    icon: isMobile.value ? DiscountIconMobile : DiscountIconDesktop,
    title: "50% off credit packs",
    subtitle: "Enjoy Wonder without limits",
    free: false,
    pro: true,
  },
  {
    icon: isMobile.value ? MagicWandIconMobile : MagicWandIconDesktop,
    title: "Unlock PRO styles",
    subtitle: "Enables access to all styles",
    free: false,
    pro: true,
  },
]);
</script>
<template>
  <div id="wrapper" :class="`${isCard ? 'column-view' : ''}`">
    <span v-if="isPopup" id="skip" class="pointer" @click="props.skipFunc"
      >Skip</span
    >
    <h1 v-if="!isMobile && !isPopup && !isCard">
      Try <span class="gradient-text">Wonder</span> Premium
    </h1>
    <div
      id="paywall-body"
      :class="`${isCard ? '' : !isPopup ? 'bg-gradient' : 'popup'}`"
    >
      <div id="advantages-wrapper" :class="`${isPopup ? 'bg-gradient' : ''}`">
        <ComparePaywallAdvantagesContainer
          :advantages="advantages"
          :isMobile="isMobile"
          :isPopup="isPopup"
          :isCard="isCard"
        ></ComparePaywallAdvantagesContainer>
      </div>
      <img
        v-if="!isMobile && !isPopup && !isCard"
        id="container-seperator"
        src="@/assets/images/black_seperator.svg"
        alt=""
      />
      <div id="products-wrapper">
        <ComparePaywallProductsContainer
          :advantages="advantages"
          :isMobile="isMobile"
          :isPopup="isPopup"
          :isCard="isCard"
          :paywallSource="props.paywallSource"
          :products="products"
          :preSelectedProductIndex="preSelectedProductIndex"
          :showUnitPriceIn="showUnitPriceIn"
          :closeFunc="props.closeFunc"
        ></ComparePaywallProductsContainer>
      </div>
    </div>
  </div>
</template>

<style scoped>
#wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.popup {
  width: 100vw;
  height: 100vh;
  margin: 0;
}

#skip {
  position: absolute;
  top: 40px;
  right: 40px;
  font-size: 18px;
  font-weight: 700;
  text-decoration-line: underline;
}

h1 {
  margin-top: 20px;
  margin-bottom: 32px;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: -0.02em;
}

#paywall-body {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  border: 1px solid rgba(17, 26, 24, 0.2);
  gap: 56px;
}

.column-view #paywall-body {
  flex-direction: column;
  border: none;
  border-radius: 0;
  gap: 20px;
}

#paywall-body.popup {
  border: none;
  border-radius: 0;
  gap: 0;
}

.bg-gradient {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fbf7ff 100%);
}

#advantages-wrapper {
  padding: 60px 0 60px 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.column-view #advantages-wrapper {
  padding: 0;
  width: 100%;
}

.popup #advantages-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(17, 26, 24, 0.11);
  width: 60%;
  height: 100%;
  padding: 0;
}

#container-seperator {
  width: 4px;
}

#products-wrapper {
  padding-right: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
}

.column-view #products-wrapper {
  padding: 0;
  width: 100%;
}

.popup #products-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100%;
  padding: 0 100px;
}

@media screen and (max-width: 1024px) {
  #wrapper {
    background: linear-gradient(
      163deg,
      rgba(189, 0, 255, 0.05) 11.23%,
      rgba(79, 149, 255, 0.05) 64.98%
    );
  }

  #wrapper.column-view {
    background: none;
  }

  #skip {
    font-size: 14px;
    position: relative;
    align-self: flex-end;
    top: auto;
    right: auto;
    margin: 20px 20px 10px 0;
  }

  #paywall-body {
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    background: none;
    gap: 0px;
    border: none;
  }

  .column-view #paywall-body {
    gap: 0;
  }

  #advantages-wrapper {
    padding: 0;
  }

  .popup #advantages-wrapper {
    width: 100%;
    height: auto;
    border: none;
  }

  #products-wrapper {
    padding: 0;
  }

  .popup #products-wrapper {
    width: 100%;
    height: auto;
  }
}
</style>
