<script setup>
import { useUserStore } from "@/store/user";
import { computed } from "@vue/reactivity";
import { onMounted, onUnmounted, ref, watch } from "vue";

import { db } from "@/firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { text2VidStyles } from "../constants/aiStyles";
import featuredVids from "../constants/featuredVids";
import { GeneralConstants } from "../constants/general";
import promptExamples from "../constants/promptExamples";
import requestWithIdToken from "../helpers/requestWithIdToken";

import CustomModal from "@/components/CustomModal.vue";
import FeaturedVideoBox from "@/components/FeaturedVideoBox.vue";
import InfiniteLoadingBar from "@/components/InfiniteLoadingBar.vue";
import StyleBox from "@/components/StyleBox.vue";
import TipCard from "@/components/TipCard.vue";
import { getCerebroId, sendEvent } from "@/helpers/cerebro";
import { getFileNameFromUrl, scrollToTop, wordCount } from "@/helpers/common";
import useConfigFetchStatus, {
  CONFIG_FETCH_VALUES,
} from "@/helpers/composables/useConfigFetchStatus";
import useDeviceType from "@/helpers/composables/useDeviceType";
import { isStaging } from "@/helpers/environment";
import router from "@/router";
import CarouselSlider from "../components/CarouselSlider.vue";
import CustomSpinner from "../components/CustomSpinner.vue";

const userStore = useUserStore();

const { configFetchStatus } = useConfigFetchStatus();

const showModal = ref(false);
const carousel = ref(null);
const styleBoxItemStyle = ref({});
const { deviceType } = useDeviceType();
const isMobile = computed(() => deviceType.value === "mobile");

const prompt = ref("");
const style = ref("");
const promptSource = ref();

const status = ref("waiting");
const createdId = ref("");
const createdVideoData = ref();
const createdPromptSource = ref();

const text2vidConfig = computed(() => {
  return userStore.getParameter("text2vid_configuration");
});

const text2vidPageEnabled = computed(() => {
  return userStore.getParameter("text2vid_configuration")?.enabled ?? false;
});

watch(configFetchStatus, (newValue) => {
  if (newValue == CONFIG_FETCH_VALUES.FETCHED && !text2vidPageEnabled.value) {
    router.replace({ name: "Home" });
  }
});

const creationCost = computed(
  () =>
    userStore.getParameter("credit_configuration_for_features")?.text_to_video
      ?.feature_cost ?? 3
);

const mainStyles = computed(() => {
  let tempStyles = [];

  if (configFetchStatus.value == CONFIG_FETCH_VALUES.FETCHED)
    tempStyles = userStore.getParameter("text2vid_styles");
  else if (configFetchStatus.value == CONFIG_FETCH_VALUES.FAILED)
    tempStyles = text2VidStyles;

  const isPro = userStore.isUserPro();
  return tempStyles
    .filter((item) => item.is_active && (!item.is_premium || isPro))
    .sort((a, b) => {
      return b.priority_point - a.priority_point;
    });
});

const mainFeaturedVids = computed(() => {
  let tempTemplates = [];

  if (configFetchStatus.value == CONFIG_FETCH_VALUES.FETCHED)
    tempTemplates = userStore.getParameter("text2vid_templates");
  else if (configFetchStatus.value == CONFIG_FETCH_VALUES.FAILED)
    tempTemplates = featuredVids;

  const isPro = userStore.isUserPro();
  return tempTemplates
    .filter((item) => !item.is_premium || isPro)
    .sort((a, b) => b.priority_point - a.priority_point);
});

onMounted(() => {
  const element = document.querySelector(".style-box-container");
  if (!element) return;

  const resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      const computedStyle = window.getComputedStyle(entry.target);
      styleBoxItemStyle.value = {
        width: computedStyle.width,
        marginLeft: computedStyle.marginLeft,
        marginRight: computedStyle.marginRight,
      };
      scrollToSelectedItem();
    }
  });

  resizeObserver.observe(element);

  // Cleanup function to unobserve when component unmounts
  onUnmounted(() => {
    resizeObserver.unobserve(element);
  });
});

const generate = async () => {
  if (status.value != "processing" && prompt.value) {
    eventSender({ eventName: "click_create" });
    createdPromptSource.value = promptSource.value;
    status.value = "processing";
    const user = userStore.user;
    try {
      let data = {
        prompt: prompt.value,
        style: style.value,
        userId: user.uid,
        platform: "web",
        cerebroId: getCerebroId(),
      };

      if (text2vidConfig.value) {
        data.modelName = text2vidConfig.value.model_name;
      }

      const res = await requestWithIdToken({
        method: "post",
        url: process.env.VUE_APP_PROCESS_STARTER_BASE_URL + "/v2/t2v/process",
        data,
      });

      if (res.data.success) {
        const payload = res.data.payload;
        createdId.value = payload.docId;
      } else {
        // handle error
        if (res.data.error == "User has reached limits.") {
          openDailyLimitModal();
        } else {
          alert(res.data.error);
        }
        status.value = "waiting";
      }
    } catch (error) {
      console.log(error);
      // handle error
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        if (error.response.data.error == "User has reached limits.") {
          openDailyLimitModal();
        } else {
          alert(error.response.data.error);
        }
      } else {
        alert("Unexpected error occured");
      }
      status.value = "waiting";
    }
    userStore.setUser(user, true, router.options.history.state.current);
  }
};

const insertRandomPrompt = () => {
  prompt.value =
    promptExamples[Math.floor(Math.random() * promptExamples.length)];
  promptSource.value = "inspiration";

  eventSender({ eventName: "click_inspiration" });
};

const handleStyleBoxClick = (styleCode) => {
  if (status.value == "waiting" || status.value == "processed") {
    if (style.value == styleCode) {
      style.value = "";
    } else {
      style.value = styleCode;
    }
  }
};

const usePrompt = (pr, st) => {
  prompt.value = pr;
  style.value = st;
  promptSource.value = "promptExample";
  scrollToTop();
  scrollToSelectedItem();
};

const scrollToSelectedItem = () => {
  const selectedIndex = mainStyles.value.findIndex(
    (styleObj) => styleObj.id === style.value
  );
  if (selectedIndex !== -1) {
    if (!carousel.value || !styleBoxItemStyle.value) return;

    const itemWidth =
      parseFloat(styleBoxItemStyle.value.marginLeft) +
      parseFloat(styleBoxItemStyle.value.width) +
      parseFloat(styleBoxItemStyle.value.marginRight);

    const targetPosition = selectedIndex * itemWidth;
    carousel.value.scrollToPosition(targetPosition);
  } else {
    carousel.value.scrollToPosition(0);
    style.value = "";
  }
};

watch(createdId, (newValue) => {
  if (newValue) {
    if (isStaging()) console.log("new value", newValue);
    const collectionName = isStaging()
      ? GeneralConstants.stagingVideoCollectionName
      : GeneralConstants.videoCollectionName;
    const unsub = onSnapshot(doc(db, collectionName, newValue), (doc) => {
      createdVideoData.value = { id: doc.id, ...doc.data() };
      if (createdVideoData.value.status == "COMPLETED") {
        eventSender({ eventName: "create", result: true });
        status.value = "processed";

        unsub();
      } else if (createdVideoData.value.status == "FAILED") {
        eventSender({ eventName: "create", result: false });
        status.value = "processed";

        unsub();
      }
    });
  }
});

const getCreatedEventProperties = () => ({
  prompt: createdVideoData.value.prompt,
  style: createdVideoData.value.style ?? "",
  artURL: createdVideoData.value.result,
  promptSource: createdPromptSource.value,
  wordCount: wordCount(createdVideoData.value.prompt),
  promptLength: createdVideoData.value.prompt.length,
  cerebroId: createdVideoData.value.cerebroId,
  syncUserId: createdVideoData.value.userId,
});

const eventSender = ({ eventName, result }) => {
  const getEventGroup = (eventName) => {
    switch (eventName) {
      case "click_inspiration":
      case "click_create":
      case "create":
        return "creation";
      case "daily_limit_reached":
      case "click_get_more_credits":
        return "paywall";
      default:
        return undefined;
    }
  };

  const getEventProperties = (eventName, result) => {
    const commonProperties = {
      prompt: prompt.value,
      wordCount: wordCount(prompt.value),
      promptLength: prompt.value.length,
    };

    switch (eventName) {
      case "click_inspiration":
        return commonProperties;
      case "click_create":
        return {
          ...commonProperties,
          style: style.value,
          promptSource: promptSource.value,
        };
      case "create":
        return result
          ? getCreatedEventProperties()
          : {
              ...commonProperties,
              style: style.value,
              promptSource: createdPromptSource.value,
            };
      case "daily_limit_reached":
        return { source: "dailyLimit" };
      default:
        return undefined;
    }
  };

  const eventBody = {
    eventName,
  };

  if (result !== undefined) {
    eventBody.result = result ? "success" : "fail";
  }
  const eventGroup = getEventGroup(eventName);
  if (eventGroup) {
    eventBody.eventGroup = eventGroup;
  }
  const properties = getEventProperties(eventName, result);
  if (properties) {
    eventBody.properties = properties;
  }

  sendEvent(eventBody);
};

const openDailyLimitModal = () => {
  eventSender({
    eventName: "daily_limit_reached",
  });
  showModal.value = true;
};

const getMoreCredits = () => {
  eventSender({ eventName: "click_get_more_credits" });
  showModal.value = false;
  router.push({ name: "Credits" });
};

const downloadClicked = async (videoSrc) => {
  sendEvent({
    eventName: "click_download",
    properties: getCreatedEventProperties(),
  });
  try {
    const video = await fetch(videoSrc);
    const videoBlob = await video.blob();
    const videoURL = URL.createObjectURL(videoBlob);

    const link = document.createElement("a");
    link.href = videoURL;
    link.download = getFileNameFromUrl(videoSrc);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    if (isStaging()) console.error(error);
  }
};
</script>

<template>
  <Transition name="fade" mode="out-in">
    <CustomSpinner
      v-if="configFetchStatus == CONFIG_FETCH_VALUES.FETCHING"
    ></CustomSpinner>
    <div
      v-else-if="
        configFetchStatus == CONFIG_FETCH_VALUES.FETCHED && text2vidPageEnabled
      "
      id="content"
    >
      <div id="prompt-container">
        <div id="prompt-top">
          <div class="title-subtitle-section">
            <span class="title">Create videos with Text2Vid</span>
            <span class="subtitle">
              Text2Vid is a text-to-video AI model that can create realistic and
              imaginative scenes from text instructions.
            </span>
          </div>
          <div class="inspiration-section">
            <span
              class="faded-text semi-bold pointer"
              id="need-inspiration"
              @click="insertRandomPrompt"
            >
              <u>I need inspiration</u>
              💡
            </span>
          </div>
        </div>
        <div id="prompt-body">
          <div class="input-icon-container">
            <img
              src="@/assets/icons/plus_icon.svg"
              alt="+"
              class="prefix-icon"
            />
            <textarea
              type="text"
              placeholder="Type anything"
              rows="1"
              id="prompt-input"
              v-model="prompt"
              @input="() => (promptSource = 'user')"
              :disabled="status == 'processing'"
            ></textarea>
          </div>
          <button
            v-if="!isMobile"
            @click="generate"
            id="create-btn"
            :disabled="status == 'processing' || !prompt"
          >
            Generate
            <div class="credit-count">
              <img src="@/assets/icons/credits_icon.svg" alt="Credits" />
              {{ creationCost }}
            </div>
          </button>
        </div>
        <div id="styles-box">
          <p class="home-title">
            Select your style <span class="faded-text">(optional)</span>
          </p>
          <CarouselSlider ref="carousel">
            <StyleBox
              v-for="(styleObj, index) in mainStyles"
              :key="`ai-style-${index}`"
              :name="styleObj.name"
              :image="styleObj.image_url"
              parentClass="style-box-container"
              :onClick="() => handleStyleBoxClick(styleObj.id)"
              :isActive="styleObj.id == style"
              :disabled="status == 'processing'"
            ></StyleBox>
          </CarouselSlider>
        </div>
        <button
          v-if="isMobile"
          @click="generate"
          id="create-btn"
          :disabled="status == 'processing' || !prompt"
        >
          Generate
          <div class="credit-count">
            <img src="@/assets/icons/credits_icon.svg" alt="Credits" />
            {{ creationCost }}
          </div>
        </button>
        <div v-if="status == 'processed'" id="create-result">
          <div v-if="createdVideoData.status == 'COMPLETED'">
            <p class="home-title">Results</p>
            <div class="result-vid-box">
              <video
                :src="createdVideoData.result"
                loop
                muted
                autoplay
                playsinline
                class="result-vid"
              ></video>
              <div class="result-overlay">
                <div>
                  <img
                    src="@/assets/icons/download_icon.png"
                    alt="Download"
                    id="download-icon"
                    @click="() => downloadClicked(createdVideoData.result)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="createdVideoData.status == 'FAILED'">
            <p>An unexpected error occured while generating your image.</p>
          </div>
        </div>
      </div>
      <div id="featureds" v-if="status == 'waiting'">
        <h1 id="featured-title">
          Explore <span class="gradient-text">Videos</span>
        </h1>
        <div class="grid-container">
          <FeaturedVideoBox
            v-for="(item, index) in mainFeaturedVids"
            :key="`featured-vid-${index}`"
            :vidLink="item.video_url"
            :imgLink="item.image_url"
            :prompt="item.text"
            :style="item.style"
            :clickHandler="usePrompt"
          ></FeaturedVideoBox>
        </div>
      </div>
      <div v-if="status == 'processing'" id="loading-box">
        <InfiniteLoadingBar mb="32px" mt="0px"></InfiniteLoadingBar>
        <TipCard
          imgLink="https://s-media-cache-ak0.pinimg.com/736x/61/4f/1b/614f1b3a481f68b4d492f71c5fe3fc5f.jpg"
          title="Tip #1"
          text="Describe the context in which an item appears"
          example='"A blue orange sliced in half laying on a blue floor in front of a blue
        wall."'
        ></TipCard>
      </div>
      <CustomModal v-model="showModal">
        <div class="daily-limit-modal">
          <img
            src="@/assets/images/daily_limit_image.png"
            class="modal-img"
            alt="Daily limit"
          />
          <p class="modal-title">You have reached your daily limit</p>
          <p class="modal-text">
            You can only generate 5 artworks a day on free plan without having
            credits. Buy more credits to create artworks.
          </p>
          <button @click="getMoreCredits">Get More Credits</button>
        </div>
      </CustomModal>
    </div>
  </Transition>
</template>

<style scoped>
.faded-text {
  color: #111a1880;
}

.semi-bold {
  font-weight: 600;
}

#content {
  box-sizing: border-box;
  text-align: left;
  transition: all 0.33s;
  width: 100%;
}

#prompt-container {
  padding: 45px 150px;
  transition: all 0.33s;
}

#need-inspiration {
  font-size: 20px;
  white-space: nowrap;
}

#prompt-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  gap: 12px;
}

#prompt-top .title-subtitle-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: min-content;
}

#prompt-top .title-subtitle-section .title {
  color: #111a18;
  font-size: 28px;
  font-weight: 600;
  width: max-content;
}

#prompt-top .title-subtitle-section .subtitle {
  color: #111a1880;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  width: 100%;
}

#prompt-top .inspiration-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
}

#prompt-body {
  display: flex;
  margin-bottom: 40px;
}

.input-icon-container {
  position: relative;
  display: inline-flex;
  width: 100%;
}

.prefix-icon {
  position: absolute;
  top: 22px;
  left: 20px;
  color: #111a18;
  /* Adjust the font-size if you want the "+" larger or smaller */
  font-size: 14px;
  /* Ensure the "+" does not affect the input box's click area */
  pointer-events: none;
}

#prompt-input {
  flex: 1;
  border: 3px solid rgba(17, 26, 24, 0.05);
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: none;
  text-shadow: none;
  outline: none;
  padding: 14px 10px 14px 40px;
  font-family: "Poppins";
  font-size: 14px;
  resize: vertical;
  min-height: 4em;
}

#prompt-input::placeholder {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #111a18;
  opacity: 0.5;
}

#styles-box .home-title {
  margin-bottom: 24px;
}

.home-title {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

#create-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 154px;
  height: 54px;
  background-color: #111a18;
  text-shadow: none;
  outline: none;
  border: none;
  border-radius: 12px;
  color: #fff;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  margin-left: 16px;
  cursor: pointer;
  gap: 8px;
  padding: 14px 12px 14px 14px;
}

.credit-count {
  display: flex;
  align-items: center;
  gap: 4px;
}

#create-btn:disabled {
  background: #111a18;
  opacity: 0.5;
  cursor: not-allowed;
}

#featureds {
  width: 100%;
  margin: auto;
  background: #f2f2f2;
  border-radius: 40px;
  padding: 40px 0 40px 0;
  text-align: center;
}

#create-result .home-title {
  margin: 64px 0 24px 0;
}

.result-vid-box {
  width: 25%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.result-vid {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 12px;
}

.result-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
  padding: 16px 16px;
  display: flex;
}

#download-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

#featured-title {
  font-weight: 600;
  font-size: 56px;
  line-height: 84px;
  color: #111a18;
  letter-spacing: -0.02em;
  margin: 0;
}

#featureds .grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  grid-auto-rows: minmax(100px, auto); /* Auto height for rows */
  gap: 10px; /* Space between items */
  padding: 56px 32px 0px 32px;
}

#loading-box {
  width: 100%;
  max-width: 680px;
  margin: auto;
  margin-bottom: 160px;
}

.style-box-container {
  width: 120px;
  height: 120px;
}

.text-center {
  text-align: center;
}

.daily-limit-modal {
  padding: 35px 65px;
}

.modal-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  color: #111a18;
}

.modal-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(17, 26, 24, 0.5);
}

.modal-img {
  width: 100%;
  max-width: 435px;
}

@media screen and (max-width: 1024px) {
  #prompt-container {
    padding: 30px 15px;
  }

  #prompt-top .title-subtitle-section {
    width: auto;
  }

  #prompt-top .title-subtitle-section .title {
    width: 100%;
    font-size: 20px;
    letter-spacing: -0.4px;
  }

  #prompt-top .title-subtitle-section .subtitle {
    font-size: 12px;
    letter-spacing: -0.4px;
  }

  #prompt-body {
    margin-bottom: 0;
  }

  #need-inspiration {
    font-size: 14px;
  }

  #prompt-input {
    height: 8em;
  }

  #prompt-input::placeholder {
    font-size: 12px;
    color: #111a18;
    opacity: 0.5;
  }

  .home-title {
    font-size: 20px;
    letter-spacing: -0.4px;
  }

  #styles-box {
    margin: 24px 0;
  }

  .style-box-container {
    width: 82px;
    height: 82px;
  }

  #featured-title {
    font-size: 24px;
    line-height: 36px;
  }

  #featureds {
    padding: 20px 0 20px 0;
  }

  #featureds .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    grid-auto-rows: minmax(100px, auto); /* Auto height for rows */
    gap: 10px; /* Space between items */
    padding: 16px 15px;
  }

  .result-vid-box {
    width: 50%;
  }

  .result-vid {
    padding: 5px;
  }

  #create-btn {
    width: 80%;
    width: calc(100% - 32px);
    margin: 0px 16px;
  }
}
</style>
