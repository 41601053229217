import { fetchPaddleProducts } from "./paddle";
import requestWithIdToken from "./requestWithIdToken";

const paymentHandler = process.env.VUE_APP_PAYMENT_HANDLER_BASE_URL;

export const getProductIdsByCode = async (ids) => {
  const productsResponse = await requestWithIdToken({
    method: "GET",
    url: `${paymentHandler}/get-selected-products`,
    params: {
      iap_ids: ids,
      platform: "web",
    },
  });
  return await fetchPaddleProducts(productsResponse.data);
};