<script setup>
import { validatePaddlePurchase } from "@/helpers/paddle";
import { convertToUsd } from "@/helpers/priceLocalization";
import router from "@/router";
import { doc, onSnapshot } from "firebase/firestore";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { RouterLink, useRoute } from "vue-router";
import CustomSpinner from "../components/CustomSpinner.vue";
import { COLLECTION_NAMES } from "../constants/collectionNames";
import { db } from "../firebase/firebase";
import { sendEvent } from "../helpers/cerebro";
import { sendGTagEvent } from "../helpers/gtag";
import { useUserStore } from "../store/user";

const status = ref("checking");
const modal = ref(false);
const redirecting = ref("processing");

const userStore = useUserStore();
const userId = computed(() => userStore.userData.userId);

let unsubscribe;

const route = useRoute();
const purchaseValue = Number(route.query.pv) / 100;
const productId = route.query.pid;
const source = route.query.src;
const currency = route.query.cur;
const paymentMethod = route.query.pm;
const tid =
  route.query.s ||
  route.query.session_id ||
  route.query.payment_intent_id ||
  route.query.payment_intent ||
  route.query.tid;
const ft = route.query.ft;

const checkIfThisPurhcaseIsAlreadyTracked = () => {
  const pidsString = localStorage.getItem("cdw_pids") || "[]";
  const pids = JSON.parse(pidsString);
  if (pids.find((item) => item === tid)) {
    return true;
  } else {
    pids.push(tid);
    localStorage.setItem("cdw_pids", JSON.stringify(pids));
    return false;
  }
};

const sendPurchaseEvents = async (authUser, payload) => {
  if (checkIfThisPurhcaseIsAlreadyTracked()) {
    status.value = "success";
    console.log(`tracked`);
    return;
  }
  const usdPrice = await convertToUsd(purchaseValue, currency ?? "USD");
  sendEvent({
    eventName: "purchase",
    properties: {
      productIdentifier: productId,
      localPrice: purchaseValue,
      localCurrency: currency ?? "USD",
      price: usdPrice,
      currency: "USD",
      source,
      paymentProvider: "paddle",
      offersFreeTrial: ft,
      ...(paymentMethod && { paymentMethod }),
    },
  });
  sendGTagEvent({
    event: "transaction_complete",
    purchase_value: purchaseValue,
    user_id: authUser.uid,
    transaction_id: tid,
    currency_code: "USD",
    item_purchased: payload?.itemName,
    item_id_number: productId,
    category: payload?.action == "credit" ? "One Time" : "Subscription",
    item_value: purchaseValue,
    quantity_bought: 1,
  });
  window.gtag("event", "purchase", {
    transaction_id: tid,
    value: purchaseValue,
    currency: "USD",
    tax: 0.0,
    shipping: 0.0,
    items: [
      {
        item_id: productId,
        item_name: payload?.itemName,
        item_category:
          payload?.action == "credit" ? "One Time" : "Subscription",
        item_brand: "Wonder AI",
        price: purchaseValue,
        quantity: 1,
      },
    ],
    user_id: authUser.uid,
  });
};

onMounted(async () => {
  const authUser = await userStore.getCurrentUser();
  if (!route.query.success) {
    sendEvent({ eventName: "purchase_failed" });
  }
  if (route.query.c === "true") {
    sendEvent({
      eventName: "checking_transaction_in_payment_result",
      properties: {
        transactionId: tid,
      },
    });
    validatePaddlePurchase({
      trxId: route.query.s,
      callback: async (result, payload) => {
        if (!result) {
          status.value = "error";
          return;
        }
        if (payload?.processed) {
          status.value = "success";
          return;
        }

        await sendPurchaseEvents(authUser, payload);
        status.value = "success";
        await userStore.setUser(authUser, true, undefined);
      },
    });
  } else {
    if (userStore.isUserPro()) {
      status.value = "success";
      sendPurchaseEvents(authUser, {
        itemName: "Pro Subscription",
        action: "subscription",
      });
    } else {
      unsubscribe = onSnapshot(
        doc(db, COLLECTION_NAMES.USERS, authUser.uid),
        (docSnap) => {
          if (docSnap.exists()) {
            const dbData = docSnap.data();
            userStore.editUserData(dbData);
            if (userStore.isUserPro()) {
              status.value = "success";
              sendPurchaseEvents(authUser, {
                itemName: "Pro Subscription",
                action: "subscription",
              });
            }
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!", authUser.uid);
            sendEvent({
              eventName: "onsnapshot_error",
              properties: {
                collectionName: COLLECTION_NAMES.USERS,
                userId: authUser.uid,
                db: !!db,
              },
            });
          }
        }
      );
    }
  }
});

watch(status, (newStatus) => {
  if (newStatus === "success") {
    setTimeout(() => {
      router.replace({ name: "Home" });
    }, 3000);
    setTimeout(() => {
      redirecting.value = "failed";
    }, 5000);
  }
});

onBeforeUnmount(() => {
  if (unsubscribe) {
    unsubscribe();
  }
});
</script>
<template>
  <div :class="`container ${modal ? 'modal' : ''}`">
    <div v-if="status === 'checking'" class="pt-48 checking-box">
      <p>Validating purchase...</p>
      <CustomSpinner></CustomSpinner>
    </div>
    <div v-else-if="status === 'success'" class="success-box">
      <img
        src="https://storage.googleapis.com/chatapp-website-assets/payment_result_green_check.svg"
        alt="Done"
        class="check-img"
      />
      <p class="pt-38 bold mt-24" style="text-align: center">
        Payment successfully verified. Redirecting to home page...
      </p>
      <div class="actions mt-24">
        <RouterLink
          v-if="redirecting == 'failed'"
          :to="{ name: 'Home' }"
          class="btn btn-success semi-bold pt-16"
          replace
        >
          Go to Home
        </RouterLink>
        <CustomSpinner v-else></CustomSpinner>
      </div>
    </div>
    <div v-else-if="status == 'error'">
      <p>
        We could not validate your payment. <br />
        Try refreshing this page and if the problem persists, contact support at
        <a
          :href="`mailto:wonderweb@codeway.co?subject=Support - Wonder Web - ${userId}/${getCerebroId()}${
            tid ? '- ' + tid : ''
          }`"
          >wonderweb@codeway.co</a
        >
      </p>
    </div>
    <div v-if="status === 'cancelled'">
      <p>Picked the wrong subscription? Go back and check all the options</p>
      <RouterLink :to="{ name: 'Payment' }"> Go to payment page</RouterLink>
    </div>
  </div>
</template>

<style scoped>
.container {
  width: 100%;
  height: 100vh;
  background: radial-gradient(
    68.8% 68.81% at 51.21% 46.86%,
    rgba(74, 161, 129, 0.3) 0%,
    rgba(74, 161, 129, 0) 100%
  ) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 660px;
  height: 450px !important;
}

.checking-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.check-img {
  width: 100px;
  height: 100px;
}

.success-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.actions {
  display: flex;
  gap: 16px;
}

.whatsapp-btn {
  background-color: rgba(74, 161, 129, 1);
  color: #fff;
}

.btn-dark {
  background-color: rgba(20, 20, 22, 1);
  color: #fff;
}

.btn {
  font-size: 20px;
}

@media screen and (max-width: 1280px) {
  .container {
    padding: 20px;
  }

  .modal {
    width: 100%;
  }

  .pt-48 {
    font-size: 32px;
  }

  .pt-24 {
    font-size: 16px;
  }

  .actions {
    font-size: 24px;
    flex-direction: column-reverse;
  }
}
</style>
