<script setup>
import { sendEvent } from "@/helpers/cerebro";
import useDeviceType from "@/helpers/composables/useDeviceType";
import { sendGTagEvent } from "@/helpers/gtag";
import router from "@/router";
import { onMounted, ref } from "vue";
import OnboardingDynamicPage from "./OnboardingDynamicPage.vue";

const props = defineProps(["id", "pages"]);

const { deviceType } = useDeviceType();
const currentStep = ref(0);

const sendOnboardingEvent = (eventName) => {
  const properties = {
    onboardingId: props.id,
  };

  if (
    eventName === "web_page_open" ||
    eventName === "click_onboarding_button" ||
    eventName === "skip_onboarding"
  ) {
    properties.screen = currentStep.value.toString();
    properties.screenName = props.pages[currentStep.value].name;
  }

  sendEvent({
    eventName,
    page: "Onboarding",
    properties,
  });
};

onMounted(() => {
  sendOnboardingEvent("web_page_open");
  sendOnboardingEvent("onboarding_start");
});

const endOnboarding = () => {
  sendOnboardingEvent("complete_onboarding");
  router.replace({ name: "Signup" });
};

const nextStep = () => {
  sendOnboardingEvent("click_onboarding_button");

  if (currentStep.value === 0) {
    sendGTagEvent({ event: "get_started" });
  } else if (currentStep.value < props.pages.length) {
    sendGTagEvent({
      event: "next",
      step: currentStep.value.toString(),
    });
  }

  if (currentStep.value < props.pages.length - 1) {
    currentStep.value++;
    sendOnboardingEvent("web_page_open");
  } else {
    localStorage.setItem("ocid", props.id);
    endOnboarding();
  }
};

const skipOnboarding = () => {
  sendGTagEvent({ event: "skip_demo" });
  sendOnboardingEvent("skip_onboarding");
  endOnboarding();
};
</script>
<template>
  <Transition name="fade" mode="out-in">
    <div :key="`step-${currentStep}`">
      <OnboardingDynamicPage
        :page="props.pages[currentStep]"
        :next="nextStep"
        :skip="skipOnboarding"
        :isMobile="deviceType === 'mobile'"
      />
    </div>
  </Transition>
</template>
<style scoped></style>
