<script setup>
import { COUPON_MANAGER_PRODUCTS } from "@/constants/general";
import requestWithIdToken from "@/helpers/requestWithIdToken";
import { ref } from "vue";

const mode = ref("defineCode");
const duration = ref(7);
const description = ref("");
const type = ref("subscription");
const product = ref("com.wonder.consumable.5variation.10styles.t3");
const codeToCheck = ref();
const definedCode = ref();
const checkResult = ref();

const products = COUPON_MANAGER_PRODUCTS;

const createCoupon = async () => {
  mode.value = "generatingCode";
  const data = { description: description.value, type: type.value };
  if (type.value == "subscription") {
    data.duration = duration.value;
  } else {
    data.product = product.value;
  }
  try {
    const response = await requestWithIdToken({
      method: "POST",
      url: process.env.VUE_APP_COUPON_MANAGER_BASE_URL + "/create",
      data,
    });
    if (response && response.data && response.data.success) {
      definedCode.value = response.data.payload.code;
    } else {
      definedCode.value =
        response && response.data
          ? response.data.error
          : "Unexpected error on generating.";
    }
  } catch (error) {
    console.log(error);
    if (error && error.response && error.response.data) {
      definedCode.value = "Error: " + error.response.data.error;
    } else {
      definedCode.value = "Unexpected error occured.";
    }
  }
  mode.value = "codeDefined";
};

const checkCode = async () => {
  mode.value = "checkingCode";
  try {
    const response = await requestWithIdToken({
      method: "POST",
      url: process.env.VUE_APP_COUPON_MANAGER_BASE_URL + "/check",
      data: { code: codeToCheck.value },
    });
    if (response && response.data && response.data.success) {
      checkResult.value = response.data.payload;
      checkResult.value.isUsed = !!checkResult.value.isUsed;
    } else {
      checkResult.value = "Error: " + response.data.error;
    }
  } catch (error) {
    console.log(error);
    if (error && error.response && error.response.data) {
      checkResult.value = "Error: " + error.response.data.error;
    } else {
      checkResult.value = "Unexpected error occured.";
    }
  }
  mode.value = "checkResulted";
};
</script>

<template>
  <div class="container" style="display: flex; justify-content: space-between">
    <button @click="mode = 'defineCode'" class="btn">
      Define a coupon code
    </button>
    <button @click="mode = 'checkCode'" class="btn">Check a code</button>
  </div>
  <div v-if="mode == 'defineCode'" class="container text-left">
    <div>
      <label for="type">Type:</label>
      <br />
      <select name="type" class="form-control" v-model.number="type">
        <option value="subscription">Subscription</option>
        <option value="aiAvatar">AI Avatar</option>
      </select>
    </div>
    <div v-if="type == 'subscription'">
      <label for="duration">Duration:</label>
      <br />
      <select name="duration" class="form-control" v-model.number="duration">
        <option value="7">1 Week</option>
        <option value="30">1 Month</option>
        <option value="365">1 Year</option>
        <option value="10000">Lifetime</option>
      </select>
    </div>
    <div v-else>
      <label for="product">Product:</label>
      <br />
      <select name="product" class="form-control" v-model.number="product">
        <option v-for="product in products" :value="product" :key="product">
          {{ product }}
        </option>
      </select>
    </div>
    <div class="mt-20">
      <label for="description">Description:</label>
      <br />
      <input
        type="text"
        class="form-control"
        name="desciption"
        v-model="description"
        placeholder="Creating a code to give to influencer @joe"
      />
    </div>
    <div class="mt-20">
      <button class="btn btn-primary" @click="createCoupon">Create</button>
    </div>
  </div>
  <div v-if="mode == 'generatingCode'" class="container">
    <p>Generating code...</p>
  </div>
  <div v-if="mode == 'codeDefined'" class="container">
    <p>{{ definedCode }}</p>
  </div>
  <div v-if="mode == 'checkCode'" class="container">
    <input
      type="text"
      class="form-control"
      placeholder="Enter code to check"
      v-model="codeToCheck"
    />
    <br />
    <button class="btn btn-primary" @click="checkCode">Check</button>
  </div>
  <div v-if="mode == 'checkResulted'">
    <p>{{ checkResult }}</p>
  </div>
</template>

<style scoped>
.container {
  margin: 20px auto;
  max-width: 600px;
}

.text-left {
  text-align: left;
}

.mt-20 {
  margin-top: 20px;
}

.btn-primary {
  background-color: #3e83e2;
  color: #fff;
}
</style>
