export const wordCount = (str) => {
  if (!str) return 0;
  return str.split(" ").length;
};

export const scrollToTop = (duration = 500) => {
  const start = window.scrollY;
  const startTime = performance.now();

  const step = (currentTime) => {
    const timeElapsed = currentTime - startTime;
    const progress = Math.min(timeElapsed / duration, 1); // Ensure progress doesn't exceed 1

    const cosParameter = start / 2;
    const scrollPosition =
      cosParameter - cosParameter * Math.cos(progress * Math.PI);

    window.scrollTo(0, start - scrollPosition); // Scroll from current position towards the top

    if (timeElapsed < duration) {
      window.requestAnimationFrame(step);
    }
  };

  window.requestAnimationFrame(step);
};

export const getExtension = (filename) => {
  return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
};

export const getFileNameFromUrl = (url) => {
  return url.split(/[\\/]/).pop();
};

export const getExtensionFromUrl = (url) => {
  return getExtension(getFileNameFromUrl(url));
};
