<script setup>
import { sendEvent } from "@/helpers/cerebro";
import { cancelSubscription, stopCancellation } from "@/helpers/paddle";
import requestWithIdToken from "@/helpers/requestWithIdToken";
import { useUserStore } from "@/store/user";
import { computed, ref } from "vue";
import PaywallManager from "./PaywallManager.vue";

const paymentHandler = process.env.VUE_APP_PAYMENT_HANDLER_BASE_URL;

const userStore = useUserStore();
const status = ref("idle");

const durationToPlan = {
  week: "Wonder Web Weekly",
  month: "Wonder Web Monthly",
  year: "Wonder Web Yearly",
  lifetime: "Wonder Web Lifetime",
};

const isUserPro = computed(() => {
  return userStore.isUserPro();
});

const subscriptions = computed(() => {
  console.log(userStore.userData.subscriptions);
  return userStore.userData.subscriptions;
});

const getPlan = (subscription) => {
  const duration = subscription.duration;
  return durationToPlan[duration];
};

const subscriptionStatusText = (subscription) => {
  let text = "";
  if (subscription.isFreeTrial && subscription.isAutoRenewOn) {
    text = "Free Trial ends on ";
  } else if (subscription.isAutoRenewOn) {
    text = "Your plan renews on ";
  } else {
    text = "Your subscription is set to cancel on ";
  }

  text += getDateText(subscription.expiresAt);
  return text;
};

const getDateText = (date) => {
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const createPortal = async () => {
  sendEvent({ eventName: "click_manage_payments" });
  if (status.value == "idle") {
    status.value = "creatingPortal";
    const userData = userStore.userData;
    const response = await requestWithIdToken({
      method: "POST",
      url: `${paymentHandler}/create-portal-session`,
      data: { customer: userData.stripeCustomerId },
    });
    if (response.data && response.data.success) {
      window.location.href = response.data.payload.url;
    } else {
      alert("Error occured on creating management session");
    }
    status.value = "idle";
  }
};

const handleStopCancelSubscription = async (subscription, id) => {
  if (subscription.paymentProvider) {
    status.value = "busy";
    if (subscription.isAutoRenewOn) {
      sendEvent({
        eventName: "cancel_subscription_tapped",
        properties: { source: "Settings" },
      });
      await cancelSubscription(id);
    } else {
      sendEvent({
        eventName: "stop_cancelation_tapped",
        properties: { source: "Settings" },
      });
      await stopCancellation(id);
    }
    status.value = "idle";
  } else {
    sendEvent({
      eventName: "cancel_subscription_tapped",
      properties: { source: "Settings" },
    });
    createPortal();
  }
};
</script>
<template>
  <div class="sub-container gap-20">
    <h2>Manage Subscription</h2>
    <div v-if="!isUserPro" class="sub-details-container">
      <PaywallManager :isPopup="false" :isCard="true" />
    </div>
    <template v-else v-for="(subscription, id) in subscriptions" :key="id">
      <div class="sub-details-container">
        <span class="plan">{{ getPlan(subscription) }}</span>
        <span class="price">{{
          `$${subscription.price} per ${subscription.duration}`
        }}</span>
        <span v-if="subscription.duration != 'lifetime'" class="status">{{
          subscriptionStatusText(subscription)
        }}</span>
      </div>
      <div class="cancel-sub-container">
        <div
          class="cancel-sub-btn"
          @click="() => handleStopCancelSubscription(subscription, id)"
        >
          {{
            subscription.isAutoRenewOn
              ? "Cancel Subscription"
              : "Reactivate your subscription"
          }}
        </div>
        <div class="cancel-sub-info">
          Reach us your specific problem about your subscription.
        </div>
      </div>
    </template>
  </div>
</template>
<style scoped>
.sub-container {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.sub-container .sub-details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.sub-container .sub-details-container .plan {
  color: #15141a99;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 8px;
}

.sub-container .sub-details-container .price {
  color: #15141a;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 12px;
}

.sub-container .sub-details-container .status {
  color: #15141a99;
  font-size: 16px;
  font-weight: 400;
}

.sub-container .cancel-sub-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.sub-container .cancel-sub-container .cancel-sub-btn {
  color: #15141a99;
  font-size: 16px;
  font-weight: 700;
  text-decoration-line: underline;
  cursor: pointer;
}

.sub-container .cancel-sub-container .cancel-sub-info {
  color: #15141a66;
  font-size: 12px;
  font-weight: 400;
}
</style>
