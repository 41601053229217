<script setup>
import { sendEvent } from "@/helpers/cerebro";
import { useUserStore } from "@/store/user";
import { computed } from "vue";

const userStore = useUserStore();

const creditCount = computed(() => {
  return userStore.getCredits();
});

const isPro = computed(() => {
  return userStore.isUserPro();
});
</script>
<template>
  <div class="card-container">
    <div v-if="isPro" class="card-credit">
      <router-link
        v-if="creditCount >= 0"
        :to="{ name: 'Credits' }"
        class="credit-btn"
        @click="
          () => {
            sendEvent({
              eventName: 'click_credits',
              properties: { source: 'Navbar' },
            });
          }
        "
      >
        <img src="@/assets/icons/credits_icon.svg" alt="Credit" />
        <div class="credit-container">
          <span class="credit">{{ creditCount }}</span>
          <span class="label-credit">{{
            creditCount == 1 ? "credit" : "credits"
          }}</span>
        </div>
      </router-link>
    </div>
    <div v-else class="card-pro">
      <router-link
        :to="{ name: 'Payment' }"
        class="pro-btn"
        @click="
          () => {
            sendEvent({
              eventName: 'click_pro',
              properties: { source: 'Navbar' },
            });
          }
        "
      >
        <img src="@/assets/images/wonder_logo_creditbox.png" alt="Logo" />
        PRO
      </router-link>
    </div>
  </div>
</template>
<style scoped>
.card-container {
  margin-right: 40px;
  height: 42px;
}

.card-credit,
.card-pro {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #111a18;
  color: #fff;
  border-radius: 11px;
}

.card-credit img {
  width: 16px;
  height: 16px;
}

.card-pro img {
  height: 20px;
}

.credit-btn {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.credit-container {
  display: flex;
  flex-direction: column;
}

.credit-btn .credit {
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
}

.credit-btn .label-credit {
  font-size: 11px;
  font-weight: 700;
  line-height: 100%;
  opacity: 0.5;
}

.pro-btn {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 10px;
  font-size: 17px;
  font-weight: 600;
}
</style>
