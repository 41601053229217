export default [
  {
    text: "Glass mosaic portrait of profile of a girl with her hair fluttering in the wind, wearing a transparent dress with sleeves, her arm up parallel to the ground, she has the sun lying on her hand, in voronoi minkowski style, soviet realism style",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h1.JPG",
    style: "",
  },
  {
    text: "Pixel art, spider-man on a rooftop in the city",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h18.JPG",
    style: "",
  },
  {
    text: "A duck swimming in a pond, in a forest",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l1.JPG",
    style: "s56",
  },
  {
    text: "Portrait of a coal miner man, wearing work clothes and hard hat",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l6.JPG",
    style: "s59",
  },
  {
    text: "A cute kawaii plush toy, white bunny on a meadow, blue sky and warm light, vibrant colors, highly detailed, sharp focus, studio photo, highly detailed, dynamic shot",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h20.JPG",
    style: "",
  },
  {
    text: "An ancient warrior",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l4.JPG",
    style: "s37",
  },
  {
    text: "A creepy man in a house looking inside through the window",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l11.JPG",
    style: "s62",
  },
  {
    text: "A portrait of a young black man donned in a vermilion Renaissance costume, juxtaposed with a modern flair, afro hair, vibrant colors, funky",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h19.JPG",
    style: "",
  },
  {
    text: "A quirky man sitting on a bench in a suburban street",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l5.JPG",
    style: "s69",
  },
  {
    text: "Mixed media magic, art by James R. Eads, Sunset over the troubled sea, drifting fishing boats, flashes of lightning on the horizon, vibrant colors, warm colors, sunset influencing the sun, a fantastic vision, a detailed, complex and quality background, Old film grain, rust style, vantablack aura, golden ratio, rule of thirds, cinematic lighting Dark and magical realism, Complementary poisonous colors with deep zoom Memphis style",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h7.JPG",
    style: "",
  },
  {
    text: "A portrait of a woman with cracked glass face, alejandro burdisio, white and gold, paul hedley, insanely detailed, octane render, trending on artstation, perfect light, abstract vector fractal, wave function, Zentangle, 3d shading intricate details, HDR, beautifully shot, hyperrealistic, sharp focus, skin looks like old porcelain, cracks everywhere, broken skin, cracks all over the face",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h10.JPG",
    style: "",
  },
  {
    text: "A superhero and villian fighting",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l15.JPG",
    style: "s26",
  },
  {
    text: "A highly detailed epic cinematic concept art CG render digital painting artwork, Sadie Sink, by Greg Rutkowski, Ilya Kuvshinov, trending on ArtStation, subtle muted cinematic colors, made in Blender, octane render, excellent composition, cinematic atmosphere, dynamic dramatic cinematic lighting, precise correct anatomy, aesthetic, very inspirational, arthouse",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h9.JPG",
    style: "",
  },
  {
    text: "Classic car made of soap bubbles, action footage on a road driving fast at night",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h2.JPG",
    style: "",
  },
  {
    text: "A raging bull",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l14.JPG",
    style: "s61",
  },
  {
    text: "The fool, tarot card art, occult symbolism",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h17.JPG",
    style: "",
  },
  {
    text: "Sun setting over the sea in a peaceful beach environment",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l16.JPG",
    style: "s44",
  },
  {
    text: "A dog wearing suit and holding a pistol",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l2.JPG",
    style: "s76",
  },
  {
    text: "Highly detailed linework reminiscent of Carne Griffiths, scarecrow, crows, imbued with Wadim Kashim's bold color and texture, light and airy as Carl Larsson's compositions, dark background, dark, evil, field, fire, featuring Pascal Blanche-style hyper realistic characters, pastel, elegance, dramatic lighting, expressive camera angle, matte, concept art, corn field, style by Arthur Rackham and Brian Froud",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h5.JPG",
    style: "",
  },
  {
    text: "A beautiful black woman with short hair wearing an orange blouse",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l19.JPG",
    style: "s23",
  },
  {
    text: "Close-up macro photo of a bright green eye",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l9.JPG",
    style: "s17",
  },
  {
    text: "Masterpiece dapper portrait of tintin by studio harcourt, by Arlington Nelson Lindenmuth, monochromatic palette, dark palette, highly professional, dramatic lighting, extremely beautiful, intricate details, masterpiece, best quality, close up portrait, Googie Art Style, dynamic, dramatic, 1950's looking at viewer, dynamic pose, silky clothing",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h4.JPG",
    style: "",
  },
  {
    text: "An epic landscape shrouded in darkness by an eclipse",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h16.JPG",
    style: "",
  },
  {
    text: "Supernova explosion inside a glass jar, the jar stands on the kitchen table, 4K",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h3.JPG",
    style: "",
  },
  {
    text: "A mystical fairy shimmering with sparks",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l3.JPG",
    style: "s34",
  },
  {
    text: "A very foggy city landscape, at night, dark ambient, very slightly purple color palette",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h6.JPG",
    style: "",
  },
  {
    text: "Portrait of a royal woman wearing a crown and holding a baton",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l10.JPG",
    style: "s18",
  },
  {
    text: "Masterpiece, best quality, detailed, intricate, trailer park, woman, tomboy, crew cut hair, red hair, freckles, skinny, overalls, boots, shirtless, kneeling, looking up, high angle view",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h15.JPG",
    style: "",
  },
  {
    text: "A can of tomato soup",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l20.JPG",
    style: "s48",
  },
  {
    text: "Illustration in the style of alphonse mucha, 24 year old English woman, on a bicycle, rural forest road background, 1girl, blonde hair falling on her shoulders, looking toward camera, smiling, freckles, small nose, warm lighting, intricate detail",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h8.JPG",
    style: "",
  },
  {
    text: "A muscular rpg game character wearing a mask and a cape",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l18.JPG",
    style: "s20",
  },
  {
    text: "Surrealism painting, extreme detail, fine textures, deep shadows, dark, sharp lines, a happy otter pirate, detailed, interesting background, realistic shadows, sharp contrast, vivid colors, dark hues, strong contrast, cute cartoon characters, adventure, pirate ship in the background",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h11.JPG",
    style: "",
  },
  {
    text: "A serene river in a lush meadow, snowy mountains in the background",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l7.JPG",
    style: "s25",
  },
  {
    text: "Black ink on parchment, the silhouette of a woman in a wide-brimmed hat",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h12.JPG",
    style: "",
  },
  {
    text: "Desert mirage of a pond of water and palm trees, haven in a desert",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l8.JPG",
    style: "s24",
  },
  {
    text: "Astronaut alone floating in space, vast endless universe, junji ito style, geometric occult symbols",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h13.JPG",
    style: "",
  },
  {
    text: "Trench warfare in world war 2",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l12.JPG",
    style: "s31",
  },
  {
    text: "Rough loose, concept art oil painting, portrait ethereal by Jama Jurabaev, extremely detailed, brush art",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/h14.JPG",
    style: "",
  },
  {
    text: "A frog standing on a lily on a pond",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l17.JPG",
    style: "s57",
  },
  {
    text: "A girl walking down the streets of tokyo in 90s",
    imgLink:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/temp/l13.JPG",
    style: "s40",
  },
];
