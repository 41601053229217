<script setup>
import CreditProductBox from "@/components/CreditProductBox.vue";
import CustomSpinner from "@/components/CustomSpinner.vue";
import { sendEvent } from "@/helpers/cerebro";
import { initiatePaddleCheckout } from "@/helpers/paddle";
import { getProductIdsByCode } from "@/helpers/payment";
import { useUserStore } from "@/store/user";
import { computed, onMounted, ref, watch } from "vue";

const userStore = useUserStore();
const paywallSource = "credits";
const paywallStructure = computed(
  () => (userStore.getParameter("paywall_structure") ?? "{}")[paywallSource]
);
const preSelectedProductIndex = computed(
  () => paywallStructure.value?.selected_index ?? 1
);
const isCheckoutInitiated = ref(false);
const products = ref(null);
const isPro = computed(() => userStore.isUserPro());

const advantages = [
  {
    icon: "https://storage.googleapis.com/wonder-eed94.appspot.com/website_images/tick_in_circle.png",
    title: "Advanced artwork creation.",
  },
  {
    icon: "https://storage.googleapis.com/wonder-eed94.appspot.com/website_images/tick_in_circle.png",
    title: "More generations after your daily limit",
  },
  {
    icon: "https://storage.googleapis.com/wonder-eed94.appspot.com/website_images/tick_in_circle.png",
    title: "1 credit allows you to generate 2 artworks.",
  },
];

watch(paywallStructure, async (newVal) => {
  try {
    await loadProducts();
  } catch (error) {
    console.error(error);
  }
});

const loadProducts = async () => {
  if (!paywallStructure.value?.products?.length) return;
  products.value = await getProductIdsByCode(
    paywallStructure.value.products?.map(
      (product) => `${product.id}.${product.tier}`
    )
  );
  products?.value.forEach((product, index) => {
    product.tag = paywallStructure.value.products[index].tag;
    product.price_without_discount =
      paywallStructure.value.products[index].price_without_discount;
  });
};

watch(products, () => {
  if (products.value?.length) {
    sendEvent({
      eventName: "paywall",
      properties: {
        source: paywallSource,
        paywallType: "credit",
        paddleIdentifiers: products.value.map((product) => product.iap_id),
        productIdentifiers: products.value.map((product) => product.price.id),
      },
      eventGroup: "paywall",
    });
  }
});

onMounted(async () => {
  try {
    await loadProducts();
  } catch (error) {
    console.error(error);
  }
  setTimeout(async () => {
    if (!products.value || products.value.length === 0) {
      products.value = await getProductIdsByCode([
        "consumable.credit.100.t6",
        "consumable.credit.200.t6",
        "consumable.credit.500.t6",
      ]);
      sendEvent({
        eventName: "paywall_products_failed_to_load",
        properties: { source: paywallSource },
      });
    }
  }, 5000);
});

const checkoutFunc = async (product, advantages) => {
  if (isCheckoutInitiated.value) return;
  isCheckoutInitiated.value = true;
  initiatePaddleCheckout(product, advantages, paywallSource);
};
</script>
<template>
  <div class="container">
    <div>
      <h1 class="title">Buy More <span class="gradient-text">Credits</span></h1>
      <p class="subtitle">
        1 credit allows you to generate 2 artworks.<br />
        You can look at our subscription plans to get discounted packages up to
        %50 off.
      </p>
    </div>
    <div v-if="products?.length > 0" class="credit-boxes-container">
      <CreditProductBox
        v-for="(product, index) in products"
        :key="product.id"
        :creditCount="product.credit_count"
        :promptCount="product.credit_count * 2"
        :priceWithoutDiscount="product.price_without_discount"
        :priceObj="product.price"
        :isPromoted="product.tag === 'most_popular'"
        :isSelected="index === preSelectedProductIndex"
        :product="product"
        :onclick="() => checkoutFunc(product, advantages)"
      />
    </div>
    <div class="loading" v-else>
      <CustomSpinner></CustomSpinner>
    </div>
    <RouterLink :to="{ name: 'Settings' }" v-if="!isPro" class="footer">
      <span v-if="!isPro" class="discount">
        🔥 <u>Get up to 51% off with Subscription</u>
      </span>
      <br />
      <span class="plans">See plans</span>
    </RouterLink>
  </div>
  <div class="background"></div>
</template>

<style scoped>
.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  bottom: 0;
  z-index: -1;
  background: url("~@/assets/images/credit_paywall_bg.png") no-repeat center /
    cover;
}

.credit-boxes-container {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding: 50px 150px;
  max-width: 1480px;
  margin: auto;
  gap: 20px;
}

.loading {
  height: 460px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.02em;
}

.subtitle {
  color: rgba(26, 25, 36, 0.5);
  text-align: center;
  font-size: 20px;
  font-weight: 400;
}

.footer .discount {
  font-size: 20px;
  font-weight: 500;
}

.footer .plans {
  font-size: 17px;
  font-weight: 400;
  opacity: 0.5;
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 0 20px;
  }

  .title {
    font-size: 32px;
    line-height: 48px;
    text-align: left;
  }

  .subtitle {
    font-size: 12px;
    text-align: left;
  }

  .credit-boxes-container {
    padding: 20px;
    flex-direction: column;
    gap: 20px;
  }

  .footer .discount {
    font-size: 16px;
  }

  .footer .plans {
    font-size: 12px;
  }
}
</style>
