import { onMounted, onUnmounted, ref } from "vue";

export default function useDeviceType() {
  const deviceType = ref("desktop"); // Default to 'desktop'

  const checkDeviceType = () => {
    const width = window.innerWidth;
    if (width <= 1024) {
      deviceType.value = "mobile";
    } else {
      deviceType.value = "desktop";
    }
  };

  onMounted(() => {
    checkDeviceType();
    window.addEventListener("resize", checkDeviceType);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", checkDeviceType);
  });

  return { deviceType };
}
