import { useUserStore } from "@/store/user";
import { getAuth, signOut } from "firebase/auth";
import { getCerebroId, sendEvent, sendUserEvent } from "./cerebro";
import { sha256 } from "./crypto";
import { sendGTagEvent } from "./gtag";
import requestWithIdToken from "./requestWithIdToken";

const providerToName = {
  password: "mail",
  "google.com": "google",
  "facebook.com": "facebook",
  "apple.com": "apple",
};

export const setUserData = async (userData) => {
  if (typeof userData == "object") {
    userData.webCerebroId = getCerebroId();
  }
  return await requestWithIdToken({
    method: "POST",
    url: process.env.VUE_APP_USER_MANAGER_BASE_URL + "/set",
    data: userData,
  });
};

export const getUserData = async (uid) => {
  return await requestWithIdToken({
    method: "POST",
    url: process.env.VUE_APP_USER_MANAGER_BASE_URL + "/get",
    data: { userId: uid },
  });
};

export const handleSignout = async () => {
  await sendEvent({ eventName: "click_sign_out" });
  signOut(getAuth()).then(() => {});
};

export const mergeOldUsersData = async (uid) => {
  return await requestWithIdToken({
    method: "POST",
    url: process.env.VUE_APP_USER_MANAGER_BASE_URL + "/merge-old-version-data",
    data: { userId: uid },
  });
};

export const loginActions = async (
  authUser,
  dataToSet,
  isJustRefetch,
  source
) => {
  // add email of user to the dataLayer
  sendGTagEvent({ em: await sha256(authUser.email) });

  // get User Data from db
  const userDataResponse = await getUserData(authUser.uid);
  const userDataPayload =
    userDataResponse && userDataResponse.data && userDataResponse.data.payload
      ? userDataResponse.data.payload
      : undefined;

  // check and merge old user data if needed
  if (
    (userDataResponse.data.success == false &&
      userDataResponse.data.error == "No user data") ||
    (userDataResponse.data.success &&
      userDataResponse.data.payload.username == undefined)
  ) {
    const oldData = await mergeOldDataActions(authUser.uid);

    if (oldData) {
      return oldData;
    }
  }

  if (userDataPayload && !userDataPayload.webCerebroId) {
    dataToSet.cerebroId = getCerebroId();
  } else if (userDataPayload && userDataPayload.webCerebroId) {
    const userStore = useUserStore();
    userStore.setCerebroId(userDataPayload.webCerebroId);
  }

  if (userDataPayload) await sendEvent({ eventName: "user_data_result" });

  // check if this is actually a signup
  if (
    (userDataResponse.data &&
      userDataResponse.data.success == false &&
      userDataResponse.data.error == "No user data") ||
    (userDataPayload && userDataPayload.initialSet)
  ) {
    dataToSet.initialSet = true;
    signupActions(dataToSet, authUser, source);
  }

  if (!isJustRefetch) {
    await sendEvent({
      eventName: "sign_in",
      result: "success",
      properties: {
        method: providerToName[authUser.providerData[0].providerId],
      },
    });

    // send user event to cerebro
    sendUserEvent({ account_id: authUser.uid, user_id: getCerebroId() });

    // set user data to db
    await setUserData(dataToSet);
  }
  return userDataPayload;
};

const signupActions = (dataToSet, authUser, sourcePath) => {
  dataToSet.initialSet = true;

  // send event to gtag
  sendGTagEvent({ event: "sign_up", user_id: authUser.uid });

  const source =
    sourcePath === "/whatsapp-signup"
      ? "whatsapp"
      : sourcePath === "/signup?onboarding=true"
      ? "InteractiveOnboarding"
      : sourcePath === "/signup?onboardingInf=true"
      ? "InformativeOnboarding"
      : sourcePath === "/signup?onboardingChat=true" ||
        sourcePath === "/onboarding-chat?signup=true"
      ? "ChatOnboarding"
      : sourcePath.startsWith("/?signup=true")
      ? `Home_${new URLSearchParams(sourcePath).get("position")}`
      : "Home";

  // send signup event to cerebro
  sendEvent({
    eventName: "sign_up",
    eventGroup: "authentication",
    properties: {
      method: providerToName[authUser.providerData[0].providerId],
      source,
    },
  });
};

const mergeOldDataActions = async (uid) => {
  const mergeOldDataResponse = await mergeOldUsersData(uid);
  if (
    mergeOldDataResponse.data &&
    mergeOldDataResponse.data.success &&
    mergeOldDataResponse.data.payload.oldData !== null
  ) {
    return mergeOldDataResponse.data.payload.oldData;
  }
  return null;
};
