<script setup>
import { useUserStore } from "@/store/user";
import { computed } from "@vue/reactivity";
import { onMounted, onUnmounted, ref, watch } from "vue";

import { db } from "@/firebase/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import aiStyles from "../constants/aiStyles";
import featureds from "../constants/featureds";
import { GeneralConstants } from "../constants/general";
import promptExamples from "../constants/promptExamples";
import requestWithIdToken from "../helpers/requestWithIdToken";

import CustomModal from "@/components/CustomModal.vue";
import FeaturedImageBox from "@/components/FeaturedImageBox.vue";
import HistoryBox from "@/components/HistoryBox.vue";
import InfiniteLoadingBar from "@/components/InfiniteLoadingBar.vue";
import LinkTag from "@/components/LinkTag.vue";
import StyleBox from "@/components/StyleBox.vue";
import TipCard from "@/components/TipCard.vue";
import { getCerebroId, sendEvent } from "@/helpers/cerebro";
import { scrollToTop, wordCount } from "@/helpers/common";
import { isStaging } from "@/helpers/environment";
import router from "@/router";
import CarouselSlider from "../components/CarouselSlider.vue";
import SideBar from "../components/SideBar.vue";

const prompt = ref("");
const style = ref("");
const promptSource = ref();
const createdId = ref("");
const createdImageData = ref();
const createdPromptSource = ref();
const isSideBarActive = ref(false);
const userStore = useUserStore();
const status = ref("waiting");
const publishStatus = ref([]);
const promptHistory = ref();
const randomPrompts = promptExamples;
const showModal = ref(false);
const carousel = ref(null);
const styleBoxItemStyle = ref({});

const modelConfig = computed(() => {
  return userStore.getParameter("model_configuration");
});

const getMoreCredits = () => {
  sendEvent({ eventName: "click_get_more_credits", eventGroup: "paywall" });
  showModal.value = false;
  router.push({ name: "Credits" });
};

const openDailyLimitModal = () => {
  sendEvent({
    eventName: "paywall",
    properties: { source: "dailyLimit" },
    eventGroup: "paywall",
  });
  showModal.value = true;
};

onMounted(() => {
  const element = document.querySelector(".style-box-container");
  if (!element) return;

  const resizeObserver = new ResizeObserver((entries) => {
    for (let entry of entries) {
      const computedStyle = window.getComputedStyle(entry.target);
      styleBoxItemStyle.value = {
        width: computedStyle.width,
        marginLeft: computedStyle.marginLeft,
        marginRight: computedStyle.marginRight,
      };
      scrollToSelectedItem();
    }
  });

  resizeObserver.observe(element);

  // Cleanup function to unobserve when component unmounts
  onUnmounted(() => {
    resizeObserver.unobserve(element);
  });

  const passedPrompt = router.options.history.state.prompt;
  const passedStyle = router.options.history.state.style;
  const passedPromptSource = router.options.history.state.source;
  if (passedPrompt) prompt.value = passedPrompt;
  if (passedStyle) {
    style.value = passedStyle;
    scrollToSelectedItem();
  }
  if (passedPromptSource) promptSource.value = passedPromptSource;
});

const generate = async () => {
  if (status.value != "processing" && prompt.value) {
    sendEvent({
      eventName: "click_create",
      eventGroup: "creation",
      properties: {
        prompt: prompt.value,
        style: style.value,
        wordCount: wordCount(prompt.value),
        promptLength: prompt.value.length,
        promptSource: promptSource.value,
      },
    });
    createdPromptSource.value = promptSource.value;
    status.value = "processing";
    publishStatus.value = [];
    const user = userStore.user;
    try {
      let data = {
        apiVersion: 2,
        bkey1: prompt.value,
        bkey2: style.value,
        userId: user.uid,
        platform: "web",
        cerebroId: getCerebroId(),
      };
      if (modelConfig.value) {
        data.nSamples = modelConfig.value.num_images_per_prompt;
        data.ddimSteps = modelConfig.value.num_inference_steps;
        data.scale = modelConfig.value.guidance_scale;
        data.jkey1 = modelConfig.value.jkey1;
        data.jkey2 = modelConfig.value.jkey2;
        data.jkey3 = modelConfig.value.jkey3;
        data.jkey4 = modelConfig.value.jkey4;
        data.jkey5 = modelConfig.value.jkey5;
      }

      const res = await requestWithIdToken({
        method: "post",
        url: process.env.VUE_APP_PROCESS_STARTER_BASE_URL + "/v2/t2i/process",
        data,
      });
      if (res.data.success) {
        const payload = res.data.payload;
        createdId.value = payload.docId;
        if (promptHistory.value !== undefined) {
          promptHistory.value.unshift(prompt.value);
        }
      } else {
        // handle error
        if (res.data.error == "User has reached limits.") {
          openDailyLimitModal();
        } else {
          alert(res.data.error);
        }
        status.value = "waiting";
      }
    } catch (error) {
      console.log(error);
      // handle error
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        if (error.response.data.error == "User has reached limits.") {
          openDailyLimitModal();
        } else {
          alert(error.response.data.error);
        }
      } else {
        alert("Unexpected error occured");
      }
      status.value = "waiting";
    }
    userStore.setUser(user, true, router.options.history.state.current);
  }
};

const insertRandomPrompt = () => {
  prompt.value =
    randomPrompts[Math.floor(Math.random() * randomPrompts.length)];
  promptSource.value = "inspiration";

  sendEvent({
    eventName: "click_inspiration",
    eventGroup: "creation",
    properties: {
      prompt: prompt.value,
      wordCount: wordCount(prompt.value),
      promptLength: prompt.value.length,
    },
  });
};

const toggleSideBar = async () => {
  isSideBarActive.value = !isSideBarActive.value;
  if (isSideBarActive.value) {
    sendEvent({ eventName: "click_prompt_history", eventGroup: "creation" });
  }
};

const mainStyles = computed(() => {
  const isPro = userStore.isUserPro();
  return aiStyles
    .filter((item) => item.is_active && (!item.is_premium || isPro))
    .sort((a, b) => {
      return b.priority_point - a.priority_point;
    });
});

const handleStyleBoxClick = (styleCode) => {
  if (status.value == "waiting" || status.value == "processed") {
    if (style.value == styleCode) {
      style.value = "";
    } else {
      style.value = styleCode;
    }
  }
};

const publish = async (index) => {
  if (publishStatus.value[index] == "Published") {
    return;
  }

  const properties = getEventProperties(index);

  sendEvent({
    eventName: "click_publish",
    eventGroup: "share",
    properties: properties,
  });
  const userData = userStore.userData;

  publishStatus.value[index] = "Publishing";
  const res = await requestWithIdToken({
    method: "POST",
    url: `${process.env.VUE_APP_PUBLISHED_IMAGE_MANAGER_BASE_URL}/feed/images`,
    data: {
      ...createdImageData.value,
      prompt: createdImageData.value.bkey1,
      style: createdImageData.value.bkey2,
      height: createdImageData.value.key6,
      width: createdImageData.value.key7,
      id: createdImageData.value.id + "-WNDR-" + index,
      publishedImageUrl: createdImageData.value.results[index],
      showOnFeed: true,
      showOnUserGallery: true,
      isWonderself: false,
      imageName: "Wonder",
      username: userData.username || "",
    },
  });

  if (res.data && res.data.success) {
    sendEvent({
      eventName: "publish",
      eventGroup: "share",
      result: "success",
      properties: properties,
    });
    publishStatus.value[index] = "Published";
  } else {
    sendEvent({
      eventName: "publish",
      eventGroup: "share",
      result: "fail",
      properties: properties,
    });
    publishStatus.value[index] = "Error";
  }
};

const usePrompt = (pr, st) => {
  prompt.value = pr;
  style.value = st;
  promptSource.value = "promptExample";
  scrollToTop();
  scrollToSelectedItem();
};

const scrollToSelectedItem = () => {
  const selectedIndex = mainStyles.value.findIndex(
    (styleObj) => styleObj.value === style.value
  );
  if (selectedIndex !== -1) {
    if (!carousel.value || !styleBoxItemStyle.value) return;

    const itemWidth =
      parseFloat(styleBoxItemStyle.value.marginLeft) +
      parseFloat(styleBoxItemStyle.value.width) +
      parseFloat(styleBoxItemStyle.value.marginRight);

    const targetPosition = selectedIndex * itemWidth;
    carousel.value.scrollToPosition(targetPosition);
  } else {
    carousel.value.scrollToPosition(0);
    style.value = "";
  }
};

watch(createdId, (newValue) => {
  if (newValue) {
    console.log("new value", newValue);
    const collectionName = isStaging()
      ? GeneralConstants.stagingCollectionName
      : GeneralConstants.collectionName;
    const unsub = onSnapshot(doc(db, collectionName, newValue), (doc) => {
      createdImageData.value = { id: doc.id, ...doc.data() };
      if (createdImageData.value.status == "COMPLETED") {
        sendEvent({
          eventName: "create",
          eventGroup: "creation",
          result: "success",
          properties: getEventProperties("all"),
        });
        status.value = "processed";

        unsub();
      } else if (createdImageData.value.status == "FAILED") {
        sendEvent({
          eventName: "create",
          eventGroup: "creation",
          result: "fail",
          properties: {
            prompt: prompt.value,
            style: style.value,
            wordCount: wordCount(prompt.value),
            promptLength: prompt.value.length,
          },
        });
        status.value = "processed";

        unsub();
      }
    });
  }
});

const unwatchForPrompt = watch(isSideBarActive, async (newValue) => {
  if (newValue == true && promptHistory.value == undefined) {
    const user = userStore.user;
    const promptsResponse = await requestWithIdToken({
      method: "POST",
      url: process.env.VUE_APP_USER_MANAGER_BASE_URL + "/prompts/get",
      data: { uid: user.uid },
    });
    promptHistory.value = promptsResponse.data.payload;
    // TODO: handle failure
    unwatchForPrompt();
  }
});

const resetPromptSource = () => (promptSource.value = "user");

const getEventProperties = (index) => ({
  prompt: createdImageData.value.bkey1,
  style: createdImageData.value.bkey2,
  artURL:
    index == "all"
      ? createdImageData.value.results
      : createdImageData.value.results[index],
  promptSource: createdPromptSource.value,
  wordCount: wordCount(createdImageData.value.bkey1),
  promptLength: createdImageData.value.bkey1.length,
  cerebroId: createdImageData.value.cerebroId,
  syncUserId: createdImageData.value.userId,
});
</script>

<template>
  <div id="content" :class="isSideBarActive ? 'sidebar-active' : ''">
    <div id="prompt-container">
      <HistoryBox
        class="hidden-sm-down"
        :clickHandler="toggleSideBar"
        :isSideBarActive="isSideBarActive"
      ></HistoryBox>
      <div id="prompt-top">
        <div>
          <p class="margin-0 home-title">Start with detailed description</p>
        </div>
        <div style="display: flex; flex-direction: column">
          <HistoryBox
            class="hidden-md-up"
            :clickHandler="toggleSideBar"
            :isSideBarActive="isSideBarActive"
          ></HistoryBox>
          <span
            class="faded-text semi-bold pointer"
            id="need-inspiration"
            @click="insertRandomPrompt"
          >
            <u> I need inspiration</u>
            💡
          </span>
        </div>
      </div>
      <div id="prompt-body">
        <div class="input-icon-container">
          <img src="@/assets/icons/plus_icon.svg" alt="+" class="prefix-icon" />
          <!-- <input
            type="text"
            placeholder="Type anything"
            id="prompt-input"
            v-model="prompt"
            @input="resetPromptSource"
          /> -->
          <textarea
            type="text"
            placeholder="Type anything"
            rows="1"
            id="prompt-input"
            v-model="prompt"
            @input="resetPromptSource"
          ></textarea>
        </div>
        <button
          @click="generate"
          id="create-btn"
          :disabled="status == 'processing' || !prompt"
          class="hidden-sm-down"
        >
          Generate
        </button>
      </div>
      <div id="styles-box">
        <p class="home-title">
          Select your style <span class="faded-text">(optional)</span>
        </p>
        <CarouselSlider ref="carousel">
          <StyleBox
            v-for="(styleObj, index) in mainStyles"
            :key="`ai-style-${index}`"
            :name="styleObj.name"
            :image="styleObj.image_url"
            parentClass="style-box-container"
            :onClick="() => handleStyleBoxClick(styleObj.value)"
            :isActive="styleObj.value == style"
            :disabled="status == 'processing'"
          ></StyleBox>
        </CarouselSlider>
      </div>
      <button
        @click="generate"
        id="create-btn"
        :disabled="status == 'processing' || !prompt"
        class="hidden-md-up"
      >
        Generate
      </button>
      <div v-if="status == 'processed'" id="create-result">
        <div v-if="createdImageData.status == 'COMPLETED'">
          <p class="home-title">Results</p>
          <div
            class="result-img-box"
            v-for="(result, index) in createdImageData.results"
            :key="`result-${index}`"
          >
            <img class="result-img" :src="result" alt="" />
            <div class="result-overlay">
              <div>
                <LinkTag
                  :href="result"
                  target="_blank"
                  eventName="click_download"
                  :eventProperties="getEventProperties(index)"
                >
                  <img
                    src="@/assets/icons/download_icon.png"
                    alt="Download"
                    id="download-icon"
                  />
                </LinkTag>
              </div>
              <div>
                <button class="btn" id="publish-btn" @click="publish(index)">
                  {{ publishStatus[index] || "Publish" }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="createdImageData.status == 'FAILED'">
          <p>An unexpected error occured while generating your image.</p>
        </div>
      </div>
    </div>
    <div id="featureds" v-if="status == 'waiting'">
      <h1 id="featured-title">
        Explore <span class="gradient-text">Wonders</span>
      </h1>
      <div class="masonry-container">
        <FeaturedImageBox
          v-for="(item, index) in featureds"
          :key="`featured-img-${index}`"
          :imgLink="item.imgLink"
          :prompt="item.text"
          :style="item.style"
          :clickHandler="usePrompt"
        ></FeaturedImageBox>
      </div>
    </div>
    <div v-if="status == 'processing'" id="loading-box">
      <InfiniteLoadingBar mb="32px" mt="0px"></InfiniteLoadingBar>
      <TipCard
        imgLink="https://s-media-cache-ak0.pinimg.com/736x/61/4f/1b/614f1b3a481f68b4d492f71c5fe3fc5f.jpg"
        title="Tip #1"
        text="Describe the contect in which an item appears"
        example='"A blue orange sliced in half laying on a blue floor in front of a blue
        wall."'
      ></TipCard>
    </div>
    <CustomModal v-model="showModal">
      <div class="daily-limit-modal">
        <img
          src="@/assets/images/daily_limit_image.png"
          class="modal-img"
          alt="Daily limit"
        />
        <p class="modal-title">You have reached your daily limit</p>
        <p class="modal-text">
          You can only generate 5 artworks a day on free plan without having
          credits. Buy more credits to create artworks.
        </p>
        <button @click="getMoreCredits">Get More Credits</button>
      </div>
    </CustomModal>
  </div>
  <SideBar
    :promptHistory="promptHistory"
    :isActive="isSideBarActive"
    @toggleSideBar="toggleSideBar"
  ></SideBar>
</template>

<style scoped>
.faded-text {
  color: #111a1880;
}

.semi-bold {
  font-weight: 600;
}

#content {
  box-sizing: border-box;
  min-height: 100vh;
  text-align: left;
  transition: all 0.33s;
  width: 100%;
}

#content.sidebar-active {
  display: inline-block;
  width: 80%;
  margin-right: 20%;
}

#prompt-container {
  padding: 45px 150px;
  transition: all 0.33s;
}
#need-inspiration {
  font-size: 20px;
  white-space: nowrap;
}
#content.sidebar-active #prompt-container {
  padding-right: 40px;
  transition: all 0.33s;
}

#history-box {
  text-align: right;
}

#prompt-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.home-title {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
}

#prompt-body {
  display: flex;
  margin-bottom: 40px;
}

.input-icon-container {
  position: relative;
  display: inline-flex;
  width: 100%;
}

.prefix-icon {
  position: absolute;
  top: 22px;
  left: 20px;
  color: #111a18;
  /* Adjust the font-size if you want the "+" larger or smaller */
  font-size: 14px;
  /* Ensure the "+" does not affect the input box's click area */
  pointer-events: none;
}

#prompt-input {
  flex: 1;
  border: 3px solid rgba(17, 26, 24, 0.05);
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: none;
  text-shadow: none;
  outline: none;
  padding: 14px 10px 14px 40px;
  font-family: "Poppins";
  font-size: 14px;
  resize: vertical;
  min-height: 4em;
}

#prompt-input::placeholder {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #111a18;
  opacity: 0.5;
}

#create-btn {
  width: 137px;
  height: 54px;
  background-color: #111a18;
  text-shadow: none;
  outline: none;
  border: none;
  border-radius: 12px;
  color: #fff;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  margin-left: 16px;
  cursor: pointer;
  font-family: Poppins;
}

#create-btn:disabled {
  background: #111a18;
  opacity: 0.5;
  cursor: not-allowed;
}

#featureds {
  width: 100%;
  margin: auto;
  background: #f2f2f2;
  border-radius: 40px;
  padding-top: 40px;
  text-align: center;
}

.result-img-box {
  width: 25%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.result-img {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 12px;
}

.result-overlay {
  background: #111a1880;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
  padding: 16px 16px;
  display: none;
}

.result-img-box:hover .result-overlay {
  display: flex;
}

#download-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

#publish-btn {
  padding: 8px 16px;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  height: 42px;
  border-radius: 12px;
}

#featured-title {
  font-weight: 600;
  font-size: 56px;
  line-height: 84px;
  color: #111a18;
  letter-spacing: -0.02em;
  margin: 0;
}

#featureds .masonry-container {
  padding: 56px 32px 0px 32px;
}

#loading-box {
  width: 100%;
  max-width: 680px;
  margin: auto;
  margin-bottom: 160px;
}

.style-box-container {
  width: 120px;
  height: 120px;
}

.text-center {
  text-align: center;
}

.daily-limit-modal {
  padding: 35px 65px;
}

.modal-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  color: #111a18;
}

.modal-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(17, 26, 24, 0.5);
}

.modal-img {
  width: 100%;
  max-width: 435px;
}

@media screen and (max-width: 1024px) {
  .home-title {
    font-size: 20px;
    line-height: 30px;
  }

  #prompt-container {
    padding: 30px 15px;
  }

  #prompt-body {
    margin-bottom: 0;
  }

  #need-inspiration {
    font-size: 14px;
  }

  #prompt-input {
    height: 8em;
  }

  .style-box-container {
    width: 82px;
    height: 82px;
  }

  #featured-title {
    font-size: 24px;
    line-height: 36px;
  }

  #featureds {
    padding-top: 20px;
  }

  #featureds .masonry-container {
    padding: 16px 15px;
    column-count: 2;
    column-gap: 9px;
  }
  .result-img-box {
    width: 50%;
  }

  .result-img {
    padding: 5px;
  }

  #create-btn {
    width: 80%;
    width: calc(100% - 32px);
    margin: 0px 16px;
  }

  #content.sidebar-active {
    display: inline-block;
    width: 100%;
    margin-right: 0%;
  }
  #content.sidebar-active #prompt-container {
    padding-right: 15px;
  }
}
</style>
