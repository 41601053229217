export default [
  {
    id: "t_t2v_1",
    name: "Flying Bee",
    is_premium: false,
    priority_point: 719,
    video_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_videos/t_t2v_1.mp4",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_images/t_t2v_1.webp",
    text: "POV video of a bee as it dives through a beautiful field of flowers",
    style: "v8",
  },
  {
    id: "t_t2v_2",
    name: "Nebulae Tea",
    is_premium: false,
    priority_point: 959,
    video_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_videos/t_t2v_2.mp4",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_images/t_t2v_2.webp",
    text: "A teapot pours a magical liquid full of swirling multicolored nebulae into an elegant teacup that sits atop a saucer",
    style: "v9",
  },
  {
    id: "t_t2v_3",
    name: "Sunbathing Dog",
    is_premium: false,
    priority_point: 929,
    video_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_videos/t_t2v_3.mp4",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_images/t_t2v_3.webp",
    text: "Golden retriever in sunglasses sunbathing on a beach in Hawaii",
    style: "v3",
  },
  {
    id: "t_t2v_4",
    name: "House Tour",
    is_premium: false,
    priority_point: 809,
    video_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_videos/t_t2v_4.mp4",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_images/t_t2v_4.webp",
    text: "Fly through tour of a futuristic house with a modern aesthetic and lots of light and plants",
    style: "v8",
  },
  {
    id: "t_t2v_5",
    name: "F1 Driving",
    is_premium: false,
    priority_point: 779,
    video_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_videos/t_t2v_5.mp4",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_images/t_t2v_5.webp",
    text: "An f1 driver races through the streets of san francisco during the day, the driver's pov is captured from a helmet cam. the golden gate bridge and the cityscape can be seen in the distance, while the blue sky and the sun illuminate the scene. the driver maneuvers the car skillfully, overtaking a car on a curve",
    style: "v2",
  },
  {
    id: "t_t2v_6",
    name: "Party Cat",
    is_premium: false,
    priority_point: 749,
    video_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_videos/t_t2v_6.mp4",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_images/t_t2v_6.webp",
    text: "A ragdoll cat partying inside of a dark club wearing LED lights. the cat is holding the camera and video-tapping the excitement, showing off his outfit. fish-eye lens",
    style: "v10",
  },
  {
    id: "t_t2v_7",
    name: "Beautiful City",
    is_premium: false,
    priority_point: 839,
    video_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_videos/t_t2v_7.mp4",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_images/t_t2v_7.webp",
    text: "A drone camera circles around a beautiful historic church built on a rocky outcropping along the Amalfi Coast, the view showcases historic and magnificent architectural details and tiered pathways and patios, waves are seen crashing against the rocks below as the view overlooks the horizon of the coastal waters and hilly landscapes of the Amalfi Coast Italy, several distant people are seen walking and enjoying vistas on patios of the dramatic ocean views, the warm glow of the afternoon sun creates a magical and romantic feeling to the scene, the view is stunning captured with beautiful photography",
    style: "v1",
  },
  {
    id: "t_t2v_8",
    name: "Sea Turtle",
    is_premium: false,
    priority_point: 989,
    video_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_videos/t_t2v_8.mp4",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_images/t_t2v_8.webp",
    text: "A sea turtle journeys through an underwater landscape at a steady pace",
    style: "v9",
  },
  {
    id: "t_t2v_9",
    name: "Walking Cat",
    is_premium: false,
    priority_point: 899,
    video_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_videos/t_t2v_9.mp4",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_images/t_t2v_9.webp",
    text: "A white and orange tabby cat is seen happily darting through a dense garden, as if chasing something. its eyes are wide and happy as it jogs forward, scanning the branches, flowers, and leaves as it walks. the path is narrow as it makes its way between all the plants. the scene is captured from a ground-level angle, following the cat closely, giving a low and intimate perspective. the image is cinematic with warm tones and a grainy texture. the scattered daylight between the leaves and plants above creates a warm contrast, accentuating the cat's orange fur. the shot is clear and sharp, with a shallow depth of field",
    style: "v10",
  },
  {
    id: "t_t2v_10",
    name: "Smart Monkey",
    is_premium: false,
    priority_point: 869,
    video_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_videos/t_t2v_10.mp4",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_template_images/t_t2v_10.webp",
    text: "Monkey playing chess in a park",
    style: "v10",
  },
];
