<script setup>
import SigningPage from "@/components/SigningPage.vue";
import { useMeta } from "vue-meta";

useMeta({
  title: "Sign Up",
});
</script>

<template>
  <SigningPage type="signup"></SigningPage>
</template>
