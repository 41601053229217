export const FAQs = [
  {
    question: "How can I start a subscription?",
    answer:
      "Starting a subscription is easy! Click the sign in button on the top right corner of the page. You can either choose one of the sign in options or provide your email and password yourself. Once you are in, you can click on your profile, go to the “Settings” option. You can manage everything about your Wonder subscription from here.",
  },
  {
    question: "What are the subscription benefits?",
    answer:
      "Being a subscribed member of our community comes with several benefits! These include: <br/><br/>• 50 Credit Pack each day<br/>• Commercial use allowed<br/>• Faster response times<br/>• Limitless Creativity",
  },
  {
    question: "Can I use my Wonder results for commercial purposes?",
    answer:
      "Yes! If you are a PRO Wonderer, you can use your AI artworks commercially.",
  },
  {
    question: "Is it allowed to generate NSFW results in Wonder?",
    answer:
      "We do our best to create a safe and fun environment for the entire Wonder community. In order to maintain that, any NSFW request will be filtered. Use of Wonder for nudity is only allowed for artistic purposes.",
  },
  {
    question: "Can I use Wonder on Mobile too?",
    answer:
      "Definitely! You can use Wonder on your mobile devices too. Download the Wonder app on App Store or Google Play. Once you open the app, you can start creating with Wonder.",
  },
  {
    question: "Will new styles be added in near future?",
    answer:
      "Yes! We are constantly working on new styles and features to provide the best experience possible for all Wonderers.",
  },
  {
    question: "Is my personal information secure?",
    answer:
      "Yes! Protecting your personal information is of utmost importance to us. We understand the significance of privacy and take appropriate measures to safeguard your data. When it comes to personal information collected through our app, it’s essential to refer to the privacy policies of the respective app stores (Google Play and Apple App Store) that distribute our app. These app stores have their own privacy policies in place to ensure the security and privacy of user data.",
  },
  {
    question: "How do I report a bug or make suggestions?",
    answer:
      "If you have any questions, concerns, or need assistance with our app, our dedicated customer support team is here to help. You can reach us by sending an email. Our support team is available to provide prompt and personalized assistance to address any issues or inquiries you may have. When contacting our customer support, please provide as much detail as possible regarding your question or concern. This will enable us to better understand your specific situation and provide you with accurate and helpful support.",
  },
];
