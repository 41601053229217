<script setup>
import { sendGTagEvent } from "@/helpers/gtag.js";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { computed } from "@vue/reactivity";
import { onMounted, onUnmounted } from "vue";
import { ModalsContainer } from "vue-final-modal";
import { useMeta } from "vue-meta";
import { useRoute } from "vue-router";
import AppFooter from "./components/AppFooter.vue";
import NavBar from "./components/NavBar.vue";
import PaywallManager from "./components/PaywallManager.vue";
import { sendEvent } from "./helpers/cerebro";
import { initializePaddle } from "./helpers/paddle.js";
import router from "./router";
import { useUserStore } from "./store/user";

let auth;
onMounted(() => {
  initializePaddle();
  // Listen cookie consent updates
  document.addEventListener("cookieyes_consent_update", cookieEventSender);

  const userStore = useUserStore();
  auth = getAuth();
  onAuthStateChanged(auth, async (user) => {
    let isRouteChangeable = false;
    if (userStore.user !== undefined) {
      isRouteChangeable = true;
    }
    if (user) {
      await userStore.setUser(
        user,
        false,
        router.options.history.state.current
      );
      sendGTagEvent({ event: "login", user_id: user.uid });
    } else {
      if (userStore.user !== undefined) {
        await sendEvent({ eventName: "sign_out" });
      }
      userStore.setUser(null);
    }
    if (isRouteChangeable) router.go();
  });
});

onUnmounted(() => {
  document.removeEventListener("cookieyes_consent_update", cookieEventSender);
});

const isHidden = computed(() => {
  const routeName = useRoute().name;
  return routeName == "Onboarding" || routeName == "Gallery";
});

const isFooterEnabled = computed(() => {
  const routeName = useRoute().name;
  return routeName == "Landing";
});

const cookieEventSender = (eventData) => {
  const data = eventData.detail;
  if (data)
    sendEvent({
      eventName: "cookie_permission",
      properties: {
        status: data.rejected.length
          ? data.accepted.length == 1
            ? "rejected"
            : "partiallyGranted"
          : "granted",
      },
    });
};

useMeta({
  link: [{ rel: "canonical", href: window.location.href }],
});
</script>

<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} • Wonder AI` : `Wonder AI`
    }}</template>
  </metainfo>
  <nav-bar v-if="!isHidden"></nav-bar>
  <router-view />
  <app-footer v-if="isFooterEnabled"></app-footer>
  <PaywallManager :isPopup="true"></PaywallManager>
  <ModalsContainer />
</template>

<style>
#app {
  font-family: "Poppins", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
body {
  margin: 0;
  padding: 0;
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
}
* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.gradient-text {
  background: radial-gradient(
      76.08% 75.62% at 7.79% 24.37%,
      #01faeb 0%,
      #6b46ff 60.1%,
      #ff008f 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pointer {
  cursor: pointer;
}

.form-control {
  display: block;
  width: 100%;
  padding: 20px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  height: 60px;
  color: #212529;
  background-color: #f5f5f5;
  background-clip: padding-box;
  border: 1px solid transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 12px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
  font-size: 13px;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  /* font-family: 'Poppins'; */
  color: #111a18;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  opacity: 0.3; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  /* font-family: 'Poppins'; */
  color: #111a18;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  opacity: 0.3;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  /* font-family: 'Poppins'; */
  color: #111a18;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  opacity: 0.3;
}

.action-text {
  color: #0094ff;
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
}

.btn {
  font-family: Poppins;
  border-radius: 18px;
  padding: 18px;
  border: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.dark-btn {
  background: #000000;
  color: #ffffff;
}

.mute-text {
  font-size: 14px;
  color: rgba(17, 26, 24, 0.5);
  font-weight: 400;
}

.bold {
  font-weight: 600;
}

.margin-0 {
  margin: 0;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.masonry-container {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;

  -webkit-column-gap: 16px;
  -moz-column-gap: 16px;
  column-gap: 16px;
}

.masonry-item {
  display: inline-block;
  width: 100%;
}

.settings-card-container {
  background-color: #eee;
  padding-top: 32px;
  padding-bottom: 150px;
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #00000080;
}

.legal-link {
  text-decoration: underline;
  color: #000;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.selected {
  border-color: #000;
}

.badge {
  background: #ff2567;
  color: #fff;
  border-radius: 8px;
  padding: 6px 10px;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
}

.line-through {
  text-decoration: line-through;
}

@media screen and (max-width: 1024px) {
  .hidden-sm-down {
    display: none;
  }
}
@media screen and (min-width: 1025px) {
  .hidden-md-up {
    display: none;
  }
}
</style>
