<script setup>
import { getCerebroId, sendEvent } from "@/helpers/cerebro";
import router from "@/router";
import { useUserStore } from "@/store/user";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

const userStore = useUserStore();
const showDetails = ref(false);

const props = defineProps(["productId", "subscriptionId"]);

const {
  advantages,
  priceText,
  interval,
  intervalCount,
  offersFreeTrial,
  ftDays,
} = router.options.history.state || {};

const showDetailsText = computed(() => {
  return showDetails.value ? "Hide details" : "Show details";
});

const productDetailsText = computed(() => {
  return `${offersFreeTrial ? ftDays + " days free, then" : ""} ${priceText}${
    interval
      ? `/${(intervalCount > 1 ? intervalCount + "-" : "") + interval}`
      : ""
  }`;
});

const toggleDetails = () => {
  showDetails.value = !showDetails.value;
};

const route = useRoute();
const checkoutOptions = {
  items: [
    {
      priceId: route.params.subscription,
      quantity: 1,
    },
  ],
  customData: {
    userId: userStore.user ? userStore.user.uid : null,
    cerebroId: getCerebroId(),
  },
};

if (
  userStore.user &&
  userStore.user.email &&
  userStore.user.email.indexOf("privaterelay") === -1
) {
  checkoutOptions.customer = {
    email: userStore.user.email,
  };
}

watch(
  () => userStore.isUserPro(),
  (value) => {
    if (value) {
      router.replace({ name: "Home" });
    }
  },
  { immediate: true }
);

onMounted(async () => {
  Paddle.Checkout.open(checkoutOptions); // eslint-disable-line no-undef
  sendEvent({ eventName: "checkout_paddle_payment_element_loaded" });
  sendEvent({
    eventName: "checkout",
    properties: { source: router.options.history.state.source },
  });
});
</script>
<template>
  <div class="container">
    <div class="advantages hidden-xs">
      <img
        src="@/assets/images/wonderlogonew.png"
        alt="Wonder logo"
        class="productImage"
      />
      <p class="title">Unleash Your<br />Creativity</p>
      <p class="price-text">{{ productDetailsText }}</p>
      <hr />
      <div :class="!showDetails ? 'hidden' : ''">
        <div
          v-for="(item, index) in advantages"
          :key="`advantage-${index}`"
          class="advantage"
        >
          <img :src="item.icon" alt="" class="icon" /> {{ item.title }}
        </div>
      </div>
      <div @click="toggleDetails" class="show-details">
        <u>{{ showDetailsText }}</u
        >&nbsp;
        <img
          src="@/assets/icons/down_icon.svg"
          alt=""
          class="details-arrow-icon"
          v-if="!showDetails"
        />
        <img
          src="@/assets/icons/up_icon.svg"
          alt=""
          class="details-arrow-icon"
          v-else
        />
      </div>
    </div>
    <div class="form-container">
      <div class="checkout-container"></div>
    </div>
  </div>
</template>

<style scoped>
p {
  margin: 0;
}
.container {
  padding: 40px 160px 100px 160px;
  display: flex;
  gap: 40px;
  text-align: left;
  justify-content: center;
  gap: 80px;
}

.icon {
  width: 26px;
  height: 26px;
}

.advantages {
  padding-top: 64px;
  transition: height 2s;
}

.advantage {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 25px;
}

.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 10px;
}
.form-container {
  flex: 1;
  max-width: 700px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05),
    0px 40px 64px -16px rgba(0, 0, 0, 0.15);
  border-radius: 24px;
  padding: 64px;
}

.price-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.submit-btn {
  margin-top: 15px;
}

#error-message {
  margin-top: 15px;
  background-color: red;
  color: white;
  border-radius: 15px;
  line-height: 40px;
}

.actions {
  text-align: right;
}

.summary {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
}

hr {
  margin: 30px 0px;
}

.productImage {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}

.show-details {
  cursor: pointer;
  display: none;
}

.details-arrow-icon {
  width: 12px;
  height: 12px;
}

@media screen and (max-width: 1199px) {
  .container {
    flex-direction: column;
    padding: 30px;
    gap: 20px;
  }
  .show-details {
    display: block;
  }

  .productImage {
    display: none;
  }

  .form-container {
    margin: -10px;
    padding: 15px;
  }

  .advantages {
    padding-top: 0px;
  }

  .hidden {
    display: none;
  }
}
</style>
