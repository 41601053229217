<script setup>
import { sendEvent } from "@/helpers/cerebro";
import { useUserStore } from "@/store/user";
import { getAuth } from "firebase/auth";
import { storeToRefs } from "pinia";
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import {
  isVerificationRequired,
  sendVerificationEmail,
} from "../helpers/verificationEmail.js";

const status = ref("idle");

const router = useRouter();
const source = router.options.history.state.from;

let interval = null;

onMounted(async () => {
  interval = setInterval(async () => {
    await getAuth().currentUser.reload();
    const user = getAuth().currentUser;
    if (!isVerificationRequired(user)) {
      user.getIdToken(true).then(() => {
        redirect();
      });
    }
  }, 5000);
  await sendVerificationEmail(source);
});

onUnmounted(() => {
  clearInterval(interval);
});

const sendVerificationHandler = async () => {
  status.value = "loading";
  await sendVerificationEmail(source);
  alert(
    "A verification e-mail is sent. Follow instructions in e-mail to continue."
  );
  status.value = "sent";
  setTimeout(() => {
    status.value = "idle";
  }, 120000);
};

const userStore = useUserStore();

const { user } = storeToRefs(userStore);

watch(user, (u) => {
  if (u && !isVerificationRequired(u)) {
    redirect();
  }
});

const redirect = () => {
  if (source === "onboarding") {
    sendEvent({
      eventName: "should_open_paywall",
      properties: { source: "InteractiveOnboarding" },
    });
    router.push({
      name: "Payment",
      state: { source: "InteractiveOnboarding" },
    });
    sendEvent({
      eventName: "should_open_paywall",
      properties: { source: "ChatOnboarding" },
    });
    router.push({
      name: "Chat",
      query: { paywall: true },
      state: { source: "ChatOnboarding" },
    });
  } else if (source === "Home") {
    sendEvent({
      eventName: "should_open_paywall",
      properties: { source: "Home" },
    });
    router.push({ name: "Payment", state: { source: "Home" } });
  } else if (source.startsWith("Home_") && source !== "Home_Middle") {
    sendEvent({
      eventName: "should_open_paywall",
      properties: { source: source },
    });
    router.push({ name: "Payment", state: { source: source } });
  } else if (source === "Home_Middle") {
    router.push({ name: "Chat" });
  } else {
    router.push({ name: "Payment", state: { source: "default" } });
  }
};
</script>

<template>
  <p>
    Your e-mail is not verified. Please check your inbox to verify your e-mail.
  </p>
  <p>
    Please wait a couple minutes before requesting another verification email
    and also check your spam/junk folder.
  </p>
  <p>
    <button
      class="btn"
      @click="sendVerificationHandler"
      :disabled="status != 'idle'"
    >
      Send me the verification e-mail
    </button>
  </p>
  <p class="success" v-if="status == 'sent'">A verification e-mail is sent</p>
  <p class="error" v-if="status == 'error'">
    Error on sending verification e-mail
  </p>
</template>

<style scoped>
.success {
  color: green;
}

.error {
  color: red;
}
</style>
