<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
    <path
      stroke="#FF005C"
      stroke-width="2.2"
      d="m3.297 22.998 9.67-17.375c1.323-2.378 4.743-2.378 6.066 0l9.67 17.375c1.287 2.313-.386 5.16-3.034 5.16H6.33c-2.648 0-4.32-2.847-3.033-5.16Z"
    />
    <path
      fill="#FF005C"
      stroke="#FF005C"
      stroke-width=".2"
      d="M15.951 12.713c-.858 0-1.531.738-1.452 1.593l.413 4.46a1.094 1.094 0 0 0 2.178 0l.413-4.46a1.459 1.459 0 0 0-1.453-1.593h-.099Z"
    />
    <circle
      cx="15.999"
      cy="21.992"
      r="1.232"
      fill="#FF005C"
      stroke="#FF005C"
      stroke-width=".2"
    />
  </svg>
</template>
