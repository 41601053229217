export const getPriceTextFromProduct = (product) => {
  return `$${(product.price.unit_amount / 100).toFixed(2)}`;
};

export const getPlanNameFromProduct = (product) => {
  const isRecurring = product.price.type == "recurring";
  if (isRecurring) {
    const interval = product.price.recurring.interval;
    if (interval === "week") {
      return `1-Week`;
    } else if (interval === "month") {
      return `${4 * product.price.recurring.interval_count}-Week`;
    } else if (interval === "year") {
      return `52-Week`;
    } else {
      // This case should not happen
      return "Subscription";
    }
  } else {
    if (product.price.nickname.toLowerCase().indexOf("lifetime") >= 0) {
      return "Lifetime";
    } else {
      // meaning we are selling credits
      return `${getCreditAmountFromWebProduct(product)} Credits`;
    }
  }
};

const getCreditAmountFromWebProduct = (product) => {
  return parseInt(product.credit_count);
};

const getPerIntervalCount = (recurringIntervalType, perIntervalType) => {
  let unit = 0;
  if (recurringIntervalType === perIntervalType) {
    unit = 1;
  } else if (recurringIntervalType === "year") {
    if (perIntervalType === "month") {
      unit = 12;
    } else if (perIntervalType === "week") {
      unit = 52;
    } else if (perIntervalType === "day") {
      unit = 365;
    }
  } else if (recurringIntervalType === "month") {
    if (perIntervalType === "week") {
      unit = 4;
    } else if (perIntervalType === "day") {
      unit = 30;
    }
  } else if (recurringIntervalType === "week" && perIntervalType === "day") {
    unit = 7;
  }
  return unit;
};

export const getUnitPricePerIntervalFromProduct = (
  product,
  perIntervalType,
  discounted = false
) => {
  if (product.price.type !== "recurring") {
    return null;
  } else {
    const perIntervalCount = getPerIntervalCount(
      product.price.recurring.interval,
      perIntervalType
    );
    const totalPrice = discounted
      ? product.price_without_discount
      : product.price.unit_amount / 100;
    return (
      totalPrice /
      (product.price.recurring.interval_count * perIntervalCount)
    ).toFixed(2);
  }
};

export const calculateRevenue = (price, provider) => {
  if (provider == "stripe") {
    return (price - 0.3) * 0.971;
  } else {
    const fee = price * 0.05 + 0.5;
    return price - fee;
  }
};
