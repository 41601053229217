<script setup>
import CustomSpinner from "@/components/CustomSpinner.vue";
import FeaturedImageBox from "@/components/FeaturedImageBox.vue";
import HrWithText from "@/components/HrWithText.vue";
import requestWithIdToken from "@/helpers/requestWithIdToken";
import router from "@/router";
import { useUserStore } from "@/store/user";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { Waterfall } from "vue-waterfall-plugin-next";
import "vue-waterfall-plugin-next/dist/style.css";

const openedTab = ref("myGallery");

const images = ref([]);
const isFeedEnded = ref(false);
const lastPublishTime = ref();
const status = ref("idle");
const waterfall = ref(null);

const userStore = useUserStore();

const scrollBottomListener = () => {
  if (
    window.innerHeight + Math.ceil(window.scrollY) >=
    document.body.offsetHeight
  ) {
    getImages();
  }
  if (isFeedEnded.value) {
    window.removeEventListener("scroll", scrollBottomListener);
  }
};

const getImages = async () => {
  if (status.value == "idle") {
    status.value = "fetchingImages";
    const user = userStore.user;
    let response;
    if (openedTab.value == "myGallery") {
      response = await requestWithIdToken({
        method: "GET",
        url: process.env.VUE_APP_PUBLISHED_IMAGE_MANAGER_BASE_URL + "/user",
        params: {
          uid: user.uid,
          sa: lastPublishTime.value,
        },
      }).catch((err) => "Error on getting feed data.");
    } else {
      console.log("another tab");
    }

    if (response.data && response.data.success) {
      const fetchedImages = response.data.payload;
      if (fetchedImages.length > 0) {
        images.value.push(...fetchedImages);
        lastPublishTime.value = fetchedImages.getLastItem().publishTime;
      }
    } else {
      isFeedEnded.value = true;
    }
    status.value = "idle";
  }
};

const openTab = (tab) => {
  openedTab.value = tab;
};

const usePrompt = (prompt, style) => {
  router.push({
    name: "Home",
    state: { prompt, style, source: "publishedUserPrompt" },
  });
};

const tabs = computed(() => [
  {
    icon: "",
    text: "My Gallery",
    isActive: openedTab.value == "myGallery",
    onclick: openTab("myGallery"),
  },
  {
    icon: "",
    text: "Liked",
    isActive: openedTab.value == "liked",
    onclick: openTab("liked"),
  },
  {
    icon: "",
    text: "Saved",
    isActive: openedTab.value == "saved",
    onclick: openTab("saved"),
  },
]);

onMounted(() => {
  getImages();
  window.addEventListener("scroll", scrollBottomListener);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", scrollBottomListener);
});

const rerenderWaterfall = () => {
  waterfall.value.renderer();
};
</script>

<template>
  <div id="content">
    <h1>My <span class="gradient-text">Artworks</span></h1>
    <div v-if="images.length > 0">
      <Waterfall
        ref="waterfall"
        :list="images"
        :breakpoints="{
          3840: {
            rowPerView: 6,
          },
          2560: {
            rowPerView: 4,
          },
          1920: {
            rowPerView: 2,
          },
          1024: {
            rowPerView: 1,
          },
        }"
      >
        <template #item="{ item, index }">
          <FeaturedImageBox
            :key="'feed-img-' + index"
            :imgLink="item.publishedImageUrl"
            :style="item.style || item.bkey2"
            :prompt="item.prompt || item.bkey1"
            :downloadBtnEnabled="true"
            :clickHandler="
              () => {
                usePrompt(item.prompt || item.bkey1, item.style || item.bkey2);
              }
            "
            class="card"
            :imgLoad="rerenderWaterfall"
          ></FeaturedImageBox>
        </template>
      </Waterfall>
      <HrWithText v-if="isFeedEnded" text="End of collection"></HrWithText>
    </div>
    <div v-else-if="!images.length && isFeedEnded" class="text-center">
      You do not have any published images.
      <u> <RouterLink :to="{ name: 'Home' }">Generate an image</RouterLink></u
      >&nbsp;and publish to start up collection.
    </div>
    <div v-else-if="status == 'fetchingImages'" class="text-center">
      <CustomSpinner></CustomSpinner>
    </div>
  </div>
</template>

<style scoped>
#content {
  padding: 5px 24px;
  margin: auto;
  margin-top: 30px;
  color: #000;
}

#content > img {
  width: 100%;
  margin-top: 10px;
}

.wf-element {
  width: 25%;
  padding: 8px;
  border-radius: 16px;
}

h1 {
  font-size: 48px;
  line-height: 72px;
  margin-left: 5px;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .wf-element {
    width: 100%;
  }

  #content {
    padding: 0px 15px;
  }

  h1 {
    font-size: 24px;
    line-height: 36px;
  }
}
</style>
