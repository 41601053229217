import { useUserStore } from "@/store/user";
import { getCerebroId, sendEvent } from "./cerebro";
import { Base64, sha256 } from "./crypto";

const generateSessionId = async () => {
  const cerebroId = getCerebroId();
  return await sha256(Base64.fromNumber(Date.now()) + cerebroId);
};

const newSessionOperations = () => {
  sendEvent({ eventName: "web_launch" });

  const userStore = useUserStore();
  if (
    userStore.getCheckForPaywallSource() != "onboarding" &&
    !userStore.isUserPro()
  ) {
    userStore.setCheckForPaywall({ val: true, source: "web_launch" });
  }
};

export const getSessionId = async () => {
  let sid = sessionStorage.getItem("sid");
  if (!sid || sid == "undefined") {
    sid = await generateSessionId();
    sessionStorage.setItem("sid", sid);
    newSessionOperations();
  }
  return sid;
};
