export default [
  {
    name: "Instagram",
    image:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/website_images/instagram_icon.png",
    link: "https://www.instagram.com/wonder.ai/",
    eventName: "click_instagram",
  },
  {
    name: "Twitter",
    image:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/website_images/twitter_icon.png",
    link: "https://twitter.com/wonderaiapp",
    eventName: "click_twitter",
  },
  {
    name: "Discord",
    image:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/website_images/discord_icon.png",
    link: "https://discord.gg/Emecfuq6gh",
    eventName: "click_discord",
  },
];
