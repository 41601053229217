import { createPinia } from "pinia";
import { createApp } from "vue";
import { createVfm } from "vue-final-modal";
import "vue-final-modal/style.css";
import { createMetaManager } from "vue-meta";
import { initiateArrayHelpers } from "./helpers/arrayFunctions.js";

initiateArrayHelpers();

import App from "./App.vue";
import router from "./router";

const pinia = createPinia();
const app = createApp(App);
const metaManager = createMetaManager();

const vfm = createVfm();

app.use(vfm);
app.use(pinia);
app.use(router);
app.use(metaManager);
app.mount("#app");
