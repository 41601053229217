import { isStaging } from "@/helpers/environment";
const StagingUserLimits = Object.freeze({
  DAILY_PROCESS_LIMIT: 5,
  TEXTUAL_INVERSION_LIMIT: 1,
  HIGH_RESOLUTION_LIMIT: 0,
  DAILY_INPAINTING_ERASE_LIMIT: 0,
  DAILY_INPAINTING_CHANGE_LIMIT: 0,
  DAILY_INPAINTING_EDIT_LIMIT: 0,
  daily_limit_free: 2,
  daily_limit_pro: 5,
  USE_DAILY_LIMIT_FIRST: true,
});

const ProdUserLimits = Object.freeze({
  DAILY_PROCESS_LIMIT: 5,
  TEXTUAL_INVERSION_LIMIT: 1,
  HIGH_RESOLUTION_LIMIT: 0,
  DAILY_INPAINTING_ERASE_LIMIT: 0,
  DAILY_INPAINTING_CHANGE_LIMIT: 0,
  DAILY_INPAINTING_EDIT_LIMIT: 0,
  DAILY_PROCESS_LIMIT_FREE: 5,
  DAILY_PROCESS_LIMIT_PRO: 50,
  USE_DAILY_LIMIT_FIRST: true,
});

export const ConstUserLimits = isStaging() ? StagingUserLimits : ProdUserLimits;
