export default [
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/Hyperrealistic_new.jpeg",
    name: "Hyper Realistic",
    value: "s17",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 54,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/s18.JPG",
    name: "Oil Painting",
    value: "s18",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 45,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/photo.jpg",
    name: "Photo",
    value: "s19",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 53,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/3D_render_new.jpeg",
    name: "3D Render",
    value: "s20",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 26,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/magazine_cover_new.jpeg",
    name: "Magazine Cover",
    value: "s21",
    is_premium: false,
    is_active: true,
    show_on_see_all: true,
    priority_point: 8,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/Bokeh.jpeg",
    name: "Bokeh",
    value: "s22",
    is_premium: false,
    is_active: true,
    show_on_see_all: true,
    priority_point: 10,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/Studio_lighting_new.jpeg",
    name: "Studio Lighting",
    value: "s23",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 28,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/Polaroid_new.jpeg",
    name: "Polaroid",
    value: "s24",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 44,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/Watercolor_painting_new.jpeg",
    name: "Watercolor Painting",
    value: "s25",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 40,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/Comic_book_new.jpeg",
    name: "Comic Book",
    value: "s26",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 31,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/Steampunk_new.jpeg",
    name: "Steampunk",
    value: "s27",
    is_premium: false,
    is_active: true,
    show_on_see_all: true,
    priority_point: 11,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/style_images/NoStyle.png",
    name: "No Style",
    value: "",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 130,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/s28.JPG",
    name: "Behance HD",
    value: "s28",
    is_premium: false,
    is_active: true,
    show_on_see_all: true,
    priority_point: 12,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/digital_art.jpg",
    name: "Digital Art",
    value: "s29",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 37,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/Magic_new.jpeg",
    name: "Magic",
    value: "s30",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 41,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/s31.JPG",
    name: "Cinematic",
    value: "s31",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 42,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/s32.JPG",
    name: "Mythological",
    value: "s32",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 46,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/s33.JPG",
    name: "Fictional Character",
    value: "s33",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 30,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/s34.JPG",
    name: "Novelistic",
    value: "s34",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 43,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/s35.JPG",
    name: "Authentic",
    value: "s35",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 33,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/s36.JPG",
    name: "Mystical",
    value: "s36",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 35,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/s37.JPG",
    name: "Pen&Ink",
    value: "s37",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 51,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/anime.jpeg",
    name: "Anime",
    value: "s38",
    is_premium: false,
    is_active: true,
    show_on_see_all: true,
    priority_point: 40,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/animation_new.jpeg",
    name: "Animation",
    value: "s39",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 24,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/anime_girl_new.jpeg",
    name: "Anime Girl",
    value: "s40",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 36,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/anime_man_new.jpeg",
    name: "Anime Man",
    value: "s41",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 32,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/s44.JPG",
    name: "Van Gogh",
    value: "s44",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 25,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/deepfloyd.JPG",
    name: "Text Art",
    value: "s45",
    is_premium: true,
    is_active: false,
    show_on_see_all: false,
    priority_point: 40,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/neo_impressionist.jpg",
    name: "Neo",
    value: "s46",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 38,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/cgi.jpg",
    name: "CGI",
    value: "s47",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 34,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/pop_art.jpg",
    name: "Pop Art",
    value: "s48",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 29,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/3d_game.jpg",
    name: "3D Game",
    value: "s49",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 27,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/epic.jpg",
    name: "Epic",
    value: "s50",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 52,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/tattoo.JPG",
    name: "Tattoo",
    value: "s52",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 49,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/color_tattoo.jpg",
    name: "Colored Tattoo",
    value: "s53",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 43,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/s54.jpg",
    name: "Luminous",
    value: "s54",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 20,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/artistic_portrait.jpg",
    name: "Portrait",
    value: "s55",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 39,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/Origami.jpeg",
    name: "Origami",
    value: "s56",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 37,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/color_painting.jpeg",
    name: "Color Painting",
    value: "s57",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 20,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/Abstract_curves.jpeg",
    name: "Abstract Curves",
    value: "s58",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 30,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/Striking.jpeg",
    name: "Striking",
    value: "s59",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 32,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/Hyperreal.jpeg",
    name: "Hyperreal",
    value: "s60",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 35,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/Graffiti.jpeg",
    name: "Graffiti",
    value: "s61",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 36,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/s62.JPG",
    name: "Halloween",
    value: "s62",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 45,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/s63.JPG",
    name: "Cute Halloween",
    value: "s63",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 42,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/signature.png",
    name: "Signature",
    value: "s64",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 49,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/pixar.jpg",
    name: "Toon",
    value: "s69",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 50,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/imaginer_images/after/cartoon.JPG",
    name: "Car Toon",
    value: "s71",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 46,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/gta_s76.jpg",
    name: "Vice",
    value: "s76",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 46,
    type: "realistic",
    is_new: false,
  },
  {
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/realistic_style_images/s81.jpg",
    name: "Fonko Pop",
    value: "s81",
    is_premium: false,
    is_active: true,
    show_on_see_all: false,
    priority_point: 50,
    type: "realistic",
    is_new: true,
  },
];

export const text2VidStyles = [
  {
    id: "",
    name: "No Style",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/style_images/NoStyle.png",
    priority_point: 130,
    is_premium: false,
    is_active: true,
  },
  {
    id: "v1",
    name: "Low-Poly",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_style_images/v1.webp",
    priority_point: 88,
    is_premium: false,
    is_active: true,
  },
  {
    id: "v2",
    name: "Realistic",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_style_images/v2.webp",
    priority_point: 87,
    is_premium: false,
    is_active: true,
  },
  {
    id: "v3",
    name: "Anime",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_style_images/v3.webp",
    priority_point: 86,
    is_premium: false,
    is_active: true,
  },
  {
    id: "v4",
    name: "Pixar",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_style_images/v4.webp",
    priority_point: 85,
    is_premium: false,
    is_active: true,
  },
  {
    id: "v5",
    name: "Watercolor",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_style_images/v5.webp",
    priority_point: 84,
    is_premium: false,
    is_active: true,
  },
  {
    id: "v6",
    name: "Colored Sketch",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_style_images/v6.webp",
    priority_point: 83,
    is_premium: false,
    is_active: true,
  },
  {
    id: "v7",
    name: "Monochrome Sketch",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_style_images/v7.webp",
    priority_point: 82,
    is_premium: false,
    is_active: true,
  },
  {
    id: "v8",
    name: "Pixel Art",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_style_images/v8.webp",
    priority_point: 81,
    is_premium: false,
    is_active: true,
  },
  {
    id: "v9",
    name: "Japanese Art",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_style_images/v9.webp",
    priority_point: 80,
    is_premium: false,
    is_active: true,
  },
  {
    id: "v10",
    name: "Graffiti Art",
    image_url:
      "https://storage.googleapis.com/wonder-eed94.appspot.com/text2vid_style_images/v10.webp",
    priority_point: 79,
    is_premium: false,
    is_active: true,
  },
];
